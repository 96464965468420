import React, {useEffect, useState} from "react";
import './styles/create_account.css'
import API from "../api/API";
import TextInput from "../Components/CustomComponents/TextInput";
import MainButton from "../Components/CustomComponents/MainButton";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setUserData} from "../main-store/auth/actions";
import {store} from "../main-store/store";
import Header from "../Components/Header/Header";
import regExp from "../Constants/regExp";
import {capitalizeFirstLetter} from "../Constants/capitalizeFirstLetter";

const initialState = {
    email: {
        value: '',
    },
    company_name: {
        value: '',
    },
    first_name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    last_name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    phone: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    job_title: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
};

export default function CreateAccount() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [profileData, setProfileData] = useState(() => initialState);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        API().get(`/profile`)
            .then(res => {
                dispatch(setUserData({
                    ...res.data.profile,
                    company_name: res.data.profile.company_name,
                    first_name: capitalizeFirstLetter(res.data.profile.role) ?? 'Admin',
                    last_name: 'account',
                    role: res.data.profile.role
                }));
                setProfileData(prevState => ({
                    ...prevState,
                    email: {
                        value: res.data.profile.email,
                    },
                    company_name: {
                        value: res.data.profile.company_name,
                    },
                    first_name: {
                        value: res.data.profile.first_name ?? '',
                        error: false,
                        errorMessage: 'Fill this label',
                    },
                    last_name: {
                        value: res.data.profile.last_name ?? '',
                        error: false,
                        errorMessage: 'Fill this label',
                    },
                    phone: {
                        value: res.data.profile.phone ?? '',
                        error: false,
                        errorMessage: 'Fill this label',
                    },
                    job_title: {
                        value: res.data.profile.job_title ?? '',
                        error: false,
                        errorMessage: 'Fill this label',
                    },
                }))
                setLoading(true)
            })
            .catch(err => {
                if (err.response.data.error === 'bad token') {
                    history.push('/404')
                }
            })
    }, [dispatch, history])

    const handleCreateAccount = (e) => {
        e.preventDefault();
        const {email, job_title, phone, last_name, first_name, company_name} = profileData;
        const condition = email.value && job_title.value && phone.value && last_name.value && first_name.value && company_name.value
        let formData = new FormData();
        if (condition) {
            if (regExp.email.test(email.value)) {
                formData.append('email', email.value)
                formData.append('first_name', first_name.value)
                formData.append('last_name', last_name.value)
                formData.append('job_title', job_title.value)
                formData.append('company_name', company_name.value)
                formData.append('phone', phone.value)
                API().post(`/profile`, formData)
                    .then(res => {
                        dispatch(setUserData({
                            ...store,
                            company_name: res.data.user.company_name,
                            first_name: res.data.user.first_name ?? first_name.value,
                            last_name: res.data.user.last_name ?? last_name.value,
                            role: res.data.user.role,
                        }));
                        history.push('/admin/dashboard');
                    })
            } else {
                setProfileData(prevState => ({
                    ...prevState,
                    email: {
                        ...prevState.email,
                        error: true,
                        errorMessage: 'Wrong format'
                    }
                }))
            }
        } else {
            Object.entries(profileData).forEach(el => {
                if (!el[1].value) {
                    setProfileData(prevState => ({
                        ...prevState,
                        [el[0]]: {
                            ...prevState[el[0]],
                            error: true,
                            errorMessage: 'Fill this label'
                        }
                    }))
                }
            })
        }
    }

    return loading && [
        <Header key={0} blankHeader/>,
        <div key={1} className={'create_account_wrapper'}>
            <div className={'create_account_container'}>
                <h2>Create an account</h2>
                <p>Please set up your account</p>
                <div className={'content'}>
                    <h3>Manager account</h3>
                    <form onSubmit={(e) => handleCreateAccount(e)}>
                        <div className={'block'}>
                            <TextInput value={profileData.email.value}
                                       type={'text'}
                                       title={'Email'}
                                       error={profileData.email.error}
                                       errTitle={profileData.email.errorMessage}
                                       disabled
                                       onChange={({target}) => {
                                           setProfileData(prevState => ({
                                               ...prevState,
                                               email: {
                                                   ...prevState.email,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}/>
                            <TextInput value={profileData.company_name.value}
                                       type={'text'}
                                       disabled
                                       title={'Company Name'}
                                       error={profileData.company_name.error}
                                       errTitle={profileData.company_name.errorMessage}
                                       onChange={({target}) => {
                                           setProfileData(prevState => ({
                                               ...prevState,
                                               company_name: {
                                                   ...prevState.company_name,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}/>
                            <TextInput value={profileData.first_name.value}
                                       type={'text'}
                                       error={profileData.first_name.error}
                                       errTitle={profileData.first_name.errorMessage}
                                       title={'First Name'}
                                       onChange={({target}) => {
                                           setProfileData(prevState => ({
                                               ...prevState,
                                               first_name: {
                                                   ...prevState.first_name,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}/>
                            <TextInput value={profileData.last_name.value}
                                       type={'text'}
                                       title={'Last Name'}
                                       error={profileData.last_name.error}
                                       errTitle={profileData.last_name.errorMessage}
                                       onChange={({target}) => {
                                           setProfileData(prevState => ({
                                               ...prevState,
                                               last_name: {
                                                   ...prevState.last_name,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}/>
                            <TextInput value={profileData.phone.value}
                                       type={'text'}
                                       error={profileData.phone.error}
                                       errTitle={profileData.phone.errorMessage}
                                       title={'Phone'}
                                       onChange={({target}) => {
                                           if (regExp.phone_format.test(target.value)) {
                                               setProfileData(prevState => ({
                                                   ...prevState,
                                                   phone: {
                                                       ...prevState.phone,
                                                       value: target.value,
                                                       error: false,
                                                   }
                                               }))
                                           }
                                       }}/>
                            <TextInput value={profileData.job_title.value}
                                       type={'text'}
                                       error={profileData.job_title.error}
                                       errTitle={profileData.job_title.errorMessage}
                                       title={'Job Title'}
                                       onChange={({target}) => {
                                           setProfileData(prevState => ({
                                               ...prevState,
                                               job_title: {
                                                   ...prevState.job_title,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}/>
                        </div>
                        <div className={'buttons_wrapper'}>
                            <MainButton type={'button'}
                                        text={'Skip'}
                                        className={'cancel_button'}
                                        onClick={() => history.push('/admin/dashboard')}/>
                            <MainButton type={'submit'}
                                        text={'Save'}
                                        className={'submit_button'}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    ]
}