import React from "react";
import CustomSelect from "../CustomSelect";
import TextInput from "../CustomComponents/TextInput";
import DatePickerWrapper from "../DatePickerWrapper";
import regExp from "../../Constants/regExp";

export default function OpportunityInitial(props) {
  const { data, setData, nested, index } = props;

  console.log(props.data);

  return Object.entries(nested ? data[nested][index] : data).map((el) => {
    if (el[1] instanceof Object) {
      if (el[1].date) {
        return (
          <DatePickerWrapper
            title={el[1].title}
            key={el[1].title}
            format={"MMM DD YYYY"}
            error={el[1].error}
            errTitle={el[1].errorMessage}
            icon={
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z'
                  stroke='#94A3B8'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M16 3V7'
                  stroke='#94A3B8'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8 3V7'
                  stroke='#94A3B8'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M4 11H20'
                  stroke='#94A3B8'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M11 15H12'
                  stroke='#94A3B8'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M12 15V18'
                  stroke='#94A3B8'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
            value={el[1].value}
            onChange={(date, dateString) => {
              if (nested) {
                setData((prevState) => ({
                  ...prevState,
                  opportunities: [
                    ...prevState.opportunities.slice(0, index),
                    {
                      ...prevState.opportunities[index],
                      [el[0]]: {
                        ...prevState.opportunities[index][el[0]],
                        value: dateString,
                        error: false,
                      },
                    },
                    ...prevState.opportunities.slice(index + 1),
                  ],
                }));
              } else {
                setData((prevState) => ({
                  ...prevState,
                  [el[0]]: {
                    ...prevState[el[0]],
                    value: dateString,
                    error: false,
                  },
                }));
              }
            }}
          />
        );
      } else if (el[1].options) {
        return (
          <CustomSelect
            title={el[1].title}
            key={el[1].title}
            loading={true}
            options={el[1].options}
            wrongInput={el[1].error}
            optionalLabel={el[0]}
            prospectsInput
            popupPositionTop={"65px"}
            popupPositionLeft={"0px"}
            popupPositionWidth={"235px"}
            prospect_id={true}
            // nestedOptions={el[1].nestedOptions}
            // monoNestedOptions={el[1].monoNestedOptions}
            svg={
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6 9L12 15L18 9'
                  stroke='#475569'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
            popup={el[1].popup}
            setData={(e) => setData(e)}
            nested={nested}
            nestedIndex={index}
            error={el[1].error}
            placeholder={el[1].placeholder ?? null}
            errTitle={el[1].errorMessage}
            value={
              el[1].value instanceof Object ? el[1].value.name : el[1].value
            }
          />
        );
      } else {
        return (
          <TextInput
            value={el[1].value}
            type={"text"}
            wrapper_margin={el[1].full ? "full_width" : null}
            title={el[1].title}
            key={el[1].title}
            prospectsInput
            wrongInput={el[1].error}
            error={el[1].error}
            errTitle={el[1].errorMessage}
            // disabled
            onChange={({ target }) => {
              if (nested) {
                const setState = (num) => {
                  setData((prevState) => ({
                    ...prevState,
                    opportunities: [
                      ...prevState.opportunities.slice(0, index),
                      {
                        ...prevState.opportunities[index],
                        [el[0]]: {
                          ...prevState.opportunities[index][el[0]],
                          value: num ? +target.value : target.value,
                          error: false,
                        },
                      },
                      ...prevState.opportunities.slice(index + 1),
                    ],
                  }));
                };
                if (el[1].type === "number") {
                  if (regExp.only_number.test(target.value)) {
                    setState(el[1].type === "number");
                  }
                } else {
                  setState();
                }
              } else {
                const setState = (num) => {
                  setData((prevState) => ({
                    ...prevState,
                    [el[0]]: {
                      ...prevState[el[0]],
                      value: num ? +target.value : target.value,
                      error: false,
                    },
                  }));
                };
                if (el[1].type === "number") {
                  if (regExp.only_number.test(target.value)) {
                    setState(el[1].type === "number");
                  }
                } else {
                  setState();
                }
              }
            }}
          />
        );
      }
    } else {
      return null;
    }
  });
}
