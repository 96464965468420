import Keys from "./helper";

export const handleCurrentToken = () => {
  if (localStorage.getItem(Keys.SUPER_ADMIN_JWT_TOKEN)) {
    return localStorage.getItem(Keys.SUPER_ADMIN_JWT_TOKEN);
  } else if (localStorage.getItem(Keys.JWT_TOKEN)) {
    return localStorage.getItem(Keys.JWT_TOKEN);
  } else {
    return null;
  }
};
