import React from "react";
import CustomSelect from "../CustomSelect";
import TextInput from "../CustomComponents/TextInput";
import regExp from "../../Constants/regExp";

export default function RenderInput({obj, setState}) {
    return (
        Object.entries(obj).map(el => {
            if ('options' in el[1]) {
                return (
                    <CustomSelect title={el[1].title}
                                  key={el[1].title}
                                  loading={el[1].loading ?? true}
                                  options={el[1].options}
                                  optionalLabel={el[0]}
                                  prospectsInput
                                  nestedOptions={el[1].nestedOptions}
                                  svg={<svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                      <path d="M8 10.5L12 14.5L16 10.5H8Z" fill="#0F172A" stroke="#0F172A"
                                            strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                  }
                                  popup={el[1].popup}
                                  setData={e => setState(e)}
                                  error={el[1].error}
                                  placeholder={el[1].placeholder ?? null}
                                  errTitle={el[1].errorMessage}
                                  value={el[1].value}/>
                )
            } else {
                return (
                    <TextInput value={el[1].value}
                               type={'text'}
                               title={el[1].title}
                               key={el[1].title}
                               prospectsInput={true}
                               wrapper_margin={el[1].full_line ? 'full_line' : null}
                               maxLength={el[1].max_length}
                               placeholder={el[1].placeholder}
                               error={el[1].error}
                               errTitle={el[1].errorMessage}
                               onChange={({target}) => {
                                   const setInputState = (num) => {
                                       setState(prevState => ({
                                           ...prevState,
                                           [el[0]]: {
                                               ...prevState[el[0]],
                                               value: num ? +target.value : target.value,
                                               error: false,
                                           }
                                       }))
                                   }
                                   if (el[1].type === 'number') {
                                       if (regExp.only_number.test(target.value)) {
                                           setInputState(el[1].type === 'number')
                                       }
                                   } else if (el[1].type === 'phone') {
                                       if (regExp.phone_format.test(target.value)) {
                                           setInputState()
                                       }
                                   } else {
                                       setInputState()
                                   }
                               }}/>
                )
            }
        })
    )
}