import React, { useEffect, useState } from "react";
import "./styles/create-contact.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import TextInput from "../../Components/CustomComponents/TextInput";
import { handleCurrentToken } from "../../Constants/handleCurrentToken";
import API from "../../api/API";
import CustomSelect from "../../Components/CustomSelect";
import regExp from "../../Constants/regExp";
import { Loader } from "../../Components/Loader";
import ChangedConfirmedPopup from "../../Components/ChangedConfirmedPopup";
import useBottomPopup from "../../Constants/useBottomPopup";
import { bodyOverflow } from "../../Constants/bodyOverflow";
import ModalWindow from "../../Components/ModalWindow";
import MainButton from "../../Components/CustomComponents/MainButton";
import { useSelector } from "react-redux";

const initialState = {
  name: {
    value: "",
    error: false,
    success: false,
    errorMessage: "Fill this label",
  },
  email: {
    value: "",
    error: false,
    success: false,
    errorMessage: "Fill this label",
  },
  job_title: {
    value: "",
    error: false,
    success: false,
    errorMessage: "Fill this label",
  },
  contact_prospects: {
    value: "",
    prospect_id: "",
    error: false,
    monoNestedOptions: true,
    // dataArray: [],
    errorMessage: "Fill this label",
    options: [],
    popup: false,
    loading: false,
  },
  phone: {
    value: "",
    error: false,
    success: false,
    errorMessage: "Fill this label",
  },
  status: {
    value: "",
    error: false,
    // monoNestedOptions: true,
    // dataArray: [],
    errorMessage: "Fill this label",
    options: [
      "New",
      "Property Information Requested",
      "Ready for Engagement",
      "Inactive",
      // "Existing",
    ],
    popup: false,
    loading: false,
  },
};

const modalState = {
  value: false,
  data: "",
};

const CreateContact = () => {
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  let location = useLocation();
  const [activeModal, setActiveModal] = useState(modalState);
  const [editMode, setEditMode] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createData, setCreateData] = useState(() => initialState);
  const store = useSelector((state) => state.auth.user_data);
  const history = useHistory();
  console.log(createData);
  useEffect(() => {
    API(handleCurrentToken())
      .get(`/contacts/assign_prospect`)
      .then((res) => {
        setCreateData((prevState) => ({
          ...prevState,
          contact_prospects: {
            ...prevState.contact_prospects,
            options: res.data.prospects ?? [],
            loading: true,
          },
          status: {
            ...prevState.status,
            loading: true,
          },
        }));
        setLoading(true);
      });
    return () => null;
  }, []);
  console.log(createData);
  useEffect(() => {
    let editMode = location.pathname.split("/").pop();
    if (!isNaN(Number(editMode))) {
      setEditMode(editMode);
      API(handleCurrentToken())
        .get(`/contacts/details?id=${editMode}`)
        .then((res) => {
          console.log(res);
          // let getProspectsArray = res[1].data.prospects;
          // let getDataArray = res[0].data.contact.prospects.prospects;
          setCreateData((state) => ({
            ...state,
            name: {
              ...state.name,
              value: res.data.contact.name,
            },
            email: {
              ...state.email,
              value: res.data.contact.email,
            },
            job_title: {
              ...state.job_title,
              value: res.data.contact.job_title,
            },
            contact_prospects: {
              ...state.contact_prospects,
              value: res.data.contact.prospect ?? "",
            },
            status: {
              ...state.status,
              value: res.data.contact.status ?? "",
              // dataArray: getDataArray,
              // options: getDataArray.length ? getProspectsArray.filter(x => !getDataArray.find(b => b.id === x.id)) : res[1].data.prospects,
              loading: true,
            },
            phone: {
              ...state.phone,
              value: res.data.contact.phone,
            },
          }));
          setLoading(true);
        });
    }
  }, [location.pathname]);

  const returnCancelUrl = () => {
    let prevUrl = history.location.state?.from;
    if (prevUrl && prevUrl.indexOf("/admin/contacts/" !== -1)) {
      return prevUrl;
    } else {
      return "/admin/contacts";
    }
  };

  const createNewContact = (e) => {
    e.preventDefault();
    // setEditMode(false);
    const { name, email, job_title, phone, contact_prospects, status } =
      createData;

    let condition =
      name.value &&
      email.value &&
      phone.value &&
      job_title.value &&
      status.value;

    Object.entries(createData).forEach((el) => {
      if (!("options" in el[1])) {
        setCreateData((prevState) => ({
          ...prevState,
          [el[0]]: {
            ...prevState[el[0]],
            success: true,
            error: false,
          },
        }));
      }
    });

    if (condition) {
      setDisabled(true);

      // let idsArray = [];
      let postData;
      postData = {
        id: editMode ? +editMode : null,
        name: name.value.trim(),
        email: email.value.trim(),
        job_title: job_title.value.trim(),
        phone: phone.value.trim(),
        status: status.value.trim(),
        prospect_id: contact_prospects.prospect_id
          ? +contact_prospects.prospect_id
          : null,
      };
      console.log(postData.id);
      API(handleCurrentToken())
        .post(`/contacts/details`, postData)
        .then((res) => {
          if (res.status === 200) {
            changeContinuePopup(
              editMode ? "Contact edited" : "Contact created"
            );
            setTimeout(() => {
              setDisabled(false);
              history.push(
                `/admin/contacts${
                  postData.id ? `/contact_info/${postData.id}` : ""
                }`
              );
              changeContinuePopup(
                editMode ? "Contact edited" : "Contact created"
              );
            }, 2000);
          }
        })
        .catch((err) => {
          setDisabled(false);
          console.log(err);
          if (
            err.response.data.message === "contact already exists" ||
            err.response.data.error === "contact already exists"
          ) {
            setCreateData((prevState) => ({
              ...prevState,
              email: {
                ...prevState.email,
                success: false,
                error: true,
                errorMessage: "Contact already exists",
              },
            }));
          }
        });
    } else {
      Object.entries(createData).forEach((el) => {
        if (!("options" in el[1])) {
          if (!el[1].value) {
            setCreateData((prevState) => ({
              ...prevState,
              [el[0]]: {
                ...prevState[el[0]],
                error: true,
                success: false,
                errorMessage: "Fill this label",
              },
            }));
          } else {
            setCreateData((prevState) => ({
              ...prevState,
              [el[0]]: {
                ...prevState[el[0]],
                success: true,
                error: false,
              },
            }));
          }
        }
      });
    }
  };

  const handleDeleteContact = () => {
    API(handleCurrentToken())
      .delete(`/contacts/details?id=${editMode}`)
      .then((res) => {
        if (res.status === 200) {
          history.push(`/admin/contacts`);
        }
      });
  };
  console.log(loading);
  return [
    <ChangedConfirmedPopup
      successText={successText}
      key={0}
      changingConfirmed={changingConfirmed}
    />,
    activeModal.value ? (
      <ModalWindow
        key={2}
        nestedObj
        setActiveModal={(val) => setActiveModal(val)}
        deleteModal
      >
        <div className={"delete_modal"}>
          <span>Delete</span>
          {/* <h3>{activeModal?.data}</h3> */}
          <p>Are you sure you want to permanently delete this Contact?</p>
          <div className={"modal_window_buttons"}>
            <MainButton
              type={"button"}
              className={"cancel_button"}
              text={"Cancel"}
              onClick={() => {
                setActiveModal(modalState);
                bodyOverflow(false);
              }}
            />
            <MainButton
              type={"button"}
              className={"delete_button"}
              text={"Delete"}
              onClick={() => handleDeleteContact()}
            />
          </div>
        </div>
      </ModalWindow>
    ) : null,
    <div key={1} className={"create_contact_wrapper"}>
      <form onSubmit={(e) => createNewContact(e)} className={"create-contact"}>
        <span className={"title-page"}>
          {editMode ? "Edit" : "Create"} Contact
        </span>
        <span className={"create-contact-inputs-title"}>Contact Details</span>
        <div
          style={
            !loading
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "243px",
                }
              : null
          }
          className={"create-contact-container"}
        >
          {loading ? (
            <>
              <div className={"create-contact-input-content"}>
                <div className={"create-contact-input-container"}>
                  <TextInput
                    value={createData.name.value}
                    type={"text"}
                    successInput={createData.name.success}
                    wrongInput={createData.name.error}
                    title={"Full Name"}
                    onChange={({ target }) => {
                      setCreateData((prevState) => ({
                        ...prevState,
                        name: {
                          ...prevState.name,
                          value: target.value,
                          error: false,
                          success: false,
                        },
                      }));
                    }}
                  />
                </div>
                <div className={"create-contact-input-container"}>
                  <TextInput
                    value={createData.email.value}
                    type={"text"}
                    title={"Email"}
                    error={createData.email.error}
                    errTitle={createData.email.errorMessage}
                    successInput={createData.email.success}
                    wrongInput={createData.email.error}
                    onChange={({ target }) => {
                      setCreateData((prevState) => ({
                        ...prevState,
                        email: {
                          ...prevState.email,
                          value: target.value,
                          error: false,
                          success: false,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className={"create-contact-input-content"}>
                <div className={"create-contact-input-container"}>
                  <TextInput
                    value={createData.job_title.value}
                    type={"text"}
                    title={"Job Title"}
                    successInput={createData.job_title.success}
                    wrongInput={createData.job_title.error}
                    onChange={({ target }) => {
                      setCreateData((prevState) => ({
                        ...prevState,
                        job_title: {
                          ...prevState.job_title,
                          value: target.value,
                          error: false,
                          success: false,
                        },
                      }));
                    }}
                  />
                </div>
                <div
                  className={`create-contact-input-container ${
                    10 < 50
                      ? "create-contact-input-true"
                      : "create-contact-input-false"
                  }`}
                >
                  <TextInput
                    value={createData.phone.value}
                    type={"text"}
                    title={"Phone"}
                    successInput={createData.phone.success}
                    wrongInput={createData.phone.error}
                    onChange={({ target }) => {
                      if (regExp.phone_format.test(target.value)) {
                        setCreateData((prevState) => ({
                          ...prevState,
                          phone: {
                            ...prevState.phone,
                            value: target.value,
                            error: false,
                            success: false,
                          },
                        }));
                      }
                    }}
                  />
                </div>
              </div>
              <div className={"create-contact-input-content"}>
                <div className={`create-contact-input-container`}>
                  <CustomSelect
                    title={"Company Name (Optional)"}
                    loading={createData.contact_prospects.loading}
                    options={createData.contact_prospects.options}
                    monoNestedOptions={
                      createData.contact_prospects.monoNestedOptions
                    }
                    unassignedValue
                    optionalLabel={"contact_prospects"}
                    prospect_id={"prospect_id"}
                    popupPositionTop={"65px"}
                    popupPositionLeft={"0px"}
                    popupPositionWidth={"350px"}
                    // multiSelect
                    // nestedDataArray
                    // dataArray={createData.contact_prospects.dataArray}
                    popup={createData.contact_prospects.popup}
                    setData={(e) => setCreateData(e)}
                    error={createData.contact_prospects.error}
                    errTitle={createData.contact_prospects.errorMessage}
                    value={createData.contact_prospects.value.name}
                  />
                </div>
                <div className={`create-contact-input-container`}>
                  <CustomSelect
                    title={"Status"}
                    loading={createData.status.loading}
                    options={createData.status.options}
                    monoNestedOptions={createData.status.monoNestedOptions}
                    unassignedValue
                    optionalLabel={"status"}
                    // multiSelect
                    // nestedDataArray
                    // dataArray={createData.contact_prospects.dataArray}
                    popupPositionTop={"65px"}
                    popupPositionLeft={"0px"}
                    popupPositionWidth={"350px"}
                    popup={createData.status.popup}
                    setData={(e) => setCreateData(e)}
                    error={createData.status.error}
                    errTitle={createData.status.errorMessage}
                    value={createData.status.value}
                  />
                </div>
              </div>
            </>
          ) : (
            <Loader
              color={{
                "--bgColor": "var(--gitgo-blue)",
                "--size": "40px",
                "--wrapper_size": "40px",
              }}
            />
          )}
          {store.role !== "manager" && loading && editMode && (
            <div className={"delete_contact"}>
              <p>Delete User’s Account</p>
              <div
                style={{ width: "max-content" }}
                onClick={() => {
                  setActiveModal({
                    value: true,
                    data: createData.name,
                  });
                }}
              >
                <span>Delete</span>
                <svg
                  width='21'
                  height='21'
                  viewBox='0 0 21 21'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M4.375 6.125L5.25 16.625C5.25 17.0891 5.43437 17.5342 5.76256 17.8624C6.09075 18.1906 6.53587 18.375 7 18.375H14C14.4641 18.375 14.9092 18.1906 15.2374 17.8624C15.5656 17.5342 15.75 17.0891 15.75 16.625L16.625 6.125'
                    stroke='#94A3B8'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M7.875 6.125V3.5C7.875 3.26794 7.96719 3.04538 8.13128 2.88128C8.29538 2.71719 8.51794 2.625 8.75 2.625H12.25C12.4821 2.625 12.7046 2.71719 12.8687 2.88128C13.0328 3.04538 13.125 3.26794 13.125 3.5V6.125'
                    stroke='#94A3B8'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M3.5 6.125H17.5'
                    stroke='#94A3B8'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M8.75 9.625V14.875'
                    stroke='#94A3B8'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12.25 9.625V14.875'
                    stroke='#94A3B8'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
        <div className={"line-block"} />
        <div className={"create-contact-buttons"}>
          <Link to={() => returnCancelUrl()}>
            <button className={"cancel-button"}>Cancel</button>
          </Link>
          {/* <Link to={() => returnCancelUrl()}> */}
          <button
            type={"submit"}
            disabled={disabled}
            className={"create-button"}
          >
            Save
          </button>
          {/* </Link> */}
        </div>
      </form>
    </div>,
  ];
};

export default CreateContact;
