import {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";

export const useGetLastChartHash = () => {
    const [activeHashUrl, setActiveHashUrl] = useState(null)
    let location = useLocation();

    useEffect(() => {
        let activePage = location.hash.split('=')[1];
        setActiveHashUrl(!isNaN(Number(activePage)) ? Number(activePage) :  1);
        return () => null;
    }, [location])

    return {activeHashUrl}
}