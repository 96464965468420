import React from "react";
import TextInput from "./CustomComponents/TextInput";
import regExp from "../Constants/regExp";
import MainButton from "./CustomComponents/MainButton";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export default function ProfileSettingsWrapper(props) {

    const {
        setProfileData,
        profileData,
        updateProfileSettings,
        toggleUploadPopup,
        profileEditError,
        profileSettings,
        pageTitle,
        editManager
    } = props;

    const history = useHistory();
    const store = useSelector(state => state.auth.user_data);

    return (
        <div className={'profile_settings_wrapper'}>
            <h2 className={'page_title'}>{pageTitle}</h2>
            <div className={'container'}>
                <div className={'content'}>
                    <p>{editManager ? 'Manager account' : store.role === 'gitgo_admin' || store.role === 'superadmin' ? 'Superadmin account' : 'Manager account'}</p>
                    <form onSubmit={(e) => updateProfileSettings(e)}>
                        <div className={'block'}>
                            <TextInput value={profileData.first_name.value}
                                       type={'text'}
                                       title={'First Name'}
                                       error={profileData.first_name.error}
                                       errTitle={profileData.first_name.errorMessage}
                                       onChange={({target}) => {
                                           setProfileData(prevState => ({
                                               ...prevState,
                                               first_name: {
                                                   ...prevState.first_name,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}/>
                            <TextInput value={profileData.last_name.value}
                                       type={'text'}
                                       title={'Last Name'}
                                       error={profileData.last_name.error}
                                       errTitle={profileData.last_name.errorMessage}
                                       onChange={({target}) => {
                                           setProfileData(prevState => ({
                                               ...prevState,
                                               last_name: {
                                                   ...prevState.last_name,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}/>
                            <TextInput value={profileData.job_title.value}
                                       type={'text'}
                                       title={'Job Title'}
                                       error={profileData.job_title.error}
                                       errTitle={profileData.job_title.errorMessage}
                                       onChange={({target}) => {
                                           setProfileData(prevState => ({
                                               ...prevState,
                                               job_title: {
                                                   ...prevState.job_title,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}/>
                            <TextInput value={profileData.company_name.value}
                                       type={'text'}
                                       disabled={editManager ?? store.role !== 'gitgo_admin' ?? false}
                                       title={'Company Name'}
                                       error={profileData.company_name.error}
                                       errTitle={profileData.company_name.errorMessage}
                                       onChange={({target}) => {
                                           setProfileData(prevState => ({
                                               ...prevState,
                                               company_name: {
                                                   ...prevState.company_name,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}/>
                            <TextInput value={profileData.email.value}
                                       type={'text'}
                                       title={'Email'}
                                       error={profileData.email.error}
                                       errTitle={profileData.email.errorMessage}
                                       disabled={editManager ?? store.role !== 'gitgo_admin' ?? false}
                                       onChange={({target}) => {
                                           setProfileData(prevState => ({
                                               ...prevState,
                                               email: {
                                                   ...prevState.email,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}/>
                            <TextInput value={profileData.phone.value}
                                       type={'text'}
                                       error={profileData.phone.error}
                                       errTitle={profileData.phone.errorMessage}
                                       title={'Phone'}
                                       onChange={({target}) => {
                                           if (regExp.phone_format.test(target.value) && target.value.length <= 20) {
                                               setProfileData(prevState => ({
                                                   ...prevState,
                                                   phone: {
                                                       ...prevState.phone,
                                                       value: target.value,
                                                       error: false,
                                                   }
                                               }))
                                           }
                                       }}/>
                        </div>
                        <div className="footer-profile-edit">
                            {profileSettings ?
                                <div className={'change_password'}>
                                    <p>Password</p>
                                    <span onClick={toggleUploadPopup}>Change Password</span>
                                </div> : null}
                            <div className="error-text">
                                <span>{profileEditError}</span>
                            </div>
                        </div>



                        <div className={'line'}/>


                        <div className={'buttons_wrapper'}>
                            <MainButton type={'button'}
                                        text={'Cancel'}
                                        className={'cancel_button'}
                                        onClick={() => history.goBack()}/>
                            <MainButton type={'submit'}
                                        text={'Save'}
                                        className={'submit_button'}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}