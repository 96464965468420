import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import "./styles/date_picker_wrapper.css";

export default function DatePickerWrapper(props) {
  const { title, format, value, icon, onChange, error, errTitle, className } =
    props;

  return (
    <div
      key={title}
      className={`calendar_wrapper ${className ? className : ""}`}
    >
      <span className={"title"}>{title}</span>
      <DatePicker
        format={format}
        style={error ? { border: "1px solid var(--error)" } : null}
        suffixIcon={icon}
        value={value ? moment(value, format) : ""}
        onChange={onChange}
        allowClear={false}
      />
      {error ? (
        <span className={"error"}>{errTitle ?? "Fill this label"}</span>
      ) : null}
    </div>
  );
}
