import React, { useEffect, useState } from "react";
import "./styles/custom_select.css";
import { Loader } from "./Loader";
import OutsideClickHandler from "../Constants/OutsideClickHandler";

export default function CustomSelect(props) {
  const [filterArray, setFilterArray] = useState(null);

  useEffect(() => {
    let _filterArray;

    if (props.value) {
      _filterArray = props.options.filter((el) =>
        el.name
          ? el.name.toLowerCase() !== props.value.toLowerCase()
          : el.toLowerCase() !== props.value.toLowerCase()
      );
    } else if (props.dataArray && props.nestedDataArray) {
      _filterArray = props.dataArray.length
        ? props.options.filter(
            (d) => !props.dataArray.find((b) => b.name === d.name)
          )
        : props.options;
    } else if (props.dataArray) {
      //todo: filter array with strings
      // _filterArray = props.options.filter(d => !props.dataArray.find(b => b.id === d.id))
    } else {
      _filterArray = props.options;
    }
    setFilterArray(_filterArray);
  }, [props.options, props.value, props.dataArray, props.nestedDataArray]);
  // console.log(filterArray);

  const handlePopup = (close) => {
    if (props.nested) {
      props.setData((prevState) => ({
        ...prevState,
        [props.nested]: [
          ...prevState[props.nested].slice(0, props.nestedIndex),
          {
            ...prevState[props.nested][props.nestedIndex],
            [props.optionalLabel]: {
              ...prevState[props.nested][props.nestedIndex][
                props.optionalLabel
              ],
              popup: !close
                ? !prevState[props.nested][props.nestedIndex][
                    props.optionalLabel
                  ].popup
                : false,
            },
          },
          ...prevState[props.nested].slice(props.nestedIndex + 1),
        ],
      }));
    } else {
      props.setData((prevState) => ({
        ...prevState,
        [props.optionalLabel]: {
          ...prevState[props.optionalLabel],
          popup: !close ? !prevState[props.optionalLabel].popup : false,
        },
      }));
    }
  };

  return (
    <div
      className={`custom_select_wrapper ${
        props.prospectsInput ? "prospects_custom_select_wrapper" : ""
      } ${props.className ? props.className : ""}`}
    >
      {props.title ? <span className={`title`}>{props.title}</span> : null}
      <div
        style={
          props.popup
            ? { pointerEvents: "none" }
            : props.error
            ? { border: "1px solid var(--error)" }
            : null
        }
        onClick={() => handlePopup(false)}
        className={
          props.wrongInput ? "wrong_input select_content" : `select_content`
        }
      >
        <span>
          {props.dataArray ? (
            ""
          ) : props.value ? (
            props.value.includes("_") ? (
              props.value.split("_").join(" ")
            ) : (
              props.value
            )
          ) : props.unassignedValue ? (
            <span style={{ color: "#A4A3B8", margin: "" }}>Unassigned</span>
          ) : (
            ""
          )}
        </span>
        {props.svg ? (
          props.svg
        ) : (
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6 9L12 15L18 9'
              stroke='#94A3B8'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        )}
      </div>
      <OutsideClickHandler
        callback={() => {
          if (props.popup) handlePopup(true);
        }}
      >
        <div
          style={
            props.popupPositionWidth &&
            props.popupPositionTop &&
            props.popupPositionLeft
              ? {
                  width: props.popupPositionWidth,
                  top: props.popupPositionTop,
                  left: props.popupPositionLeft,
                }
              : null
          }
          // {`
          //   ${props.popupPositionWidth ? `width: ${props.popupPositionWidth} ,`: ""}
          //   ${props.popupPositionTop ? `top: ${props.popupPositionTop} ,` : ""}
          //   ${props.popupPositionLeft ? `left: ${props.popupPositionLeft} ,`: ""}
          //   `}
          className={`select_popup overflow_scrollbar ${
            props.popup
              ? `select_popup_active ${
                  props.popupMaxHeight ? "select_popup_active_max_height" : ""
                }`
              : ""
          }`}
        >
          {props.loading ? (
            props.multiSelect ? (
              filterArray?.length ? (
                filterArray.map((el, i) => (
                  <span
                    onClick={() => {
                      props.setData((prevState) => ({
                        ...prevState,
                        [props.optionalLabel]: {
                          ...prevState[props.optionalLabel],
                          error: false,
                          popup: false,
                          dataArray: props.dataArray
                            ? [...prevState[props.optionalLabel].dataArray, el]
                            : null,
                        },
                      }));
                    }}
                    key={i}
                  >
                    {el.name
                      ? el.name.includes("_")
                        ? el.name.split("_").join(" ")
                        : el.name
                      : el.includes("_")
                      ? el.split("_").join(" ")
                      : el}
                  </span>
                ))
              ) : (
                <span style={{ background: "none", cursor: "auto" }}>
                  No items
                </span>
              )
            ) : filterArray?.length ? (
              filterArray.map((el, i) => {
                if (props.monoNestedOptions) {
                  return (
                    <span
                      key={i}
                      onClick={() => {
                        if (props.nested) {
                          props.setData((prevState) => ({
                            ...prevState,
                            [props.nested]: [
                              ...prevState[props.nested].slice(
                                0,
                                props.nestedIndex
                              ),
                              {
                                ...prevState[props.nested][props.nestedIndex],
                                [props.optionalLabel]: {
                                  ...prevState[props.nested][props.nestedIndex][
                                    props.optionalLabel
                                  ],
                                  value: el,
                                  error: false,
                                  prospect_id: [props.prospect_id]
                                    ? +el.id
                                    : null,
                                  popup: false,
                                },
                              },
                              ...prevState[props.nested].slice(
                                props.nestedIndex + 1
                              ),
                            ],
                          }));
                        } else {
                          props.setData((prevState) => ({
                            ...prevState,
                            [props.optionalLabel]: {
                              ...prevState[props.optionalLabel],
                              value: el,
                              error: false,
                              prospect_id: [props.prospect_id] ? +el.id : null,
                              popup: false,
                            },
                          }));
                        }
                      }}
                    >
                      {el.name.includes("_")
                        ? el.name.split("_").join(" ")
                        : el.name}
                    </span>
                  );
                } else if (props.nestedOptions) {
                  return (
                    <div
                      onClick={() => {
                        props.setData((prevState) => ({
                          ...prevState,
                          [props.optionalLabel]: {
                            ...prevState[props.optionalLabel],
                            value: el.name,
                            error: false,
                            prospect_id: [props.prospect_id] ? +el.id : null,
                            popup: false,
                          },
                        }));
                      }}
                      key={i}
                      className={"nested"}
                    >
                      <div
                        style={{ background: el.color }}
                        className={"option_color"}
                      />
                      <span>
                        {el.name.includes("_")
                          ? el.name.split("_").join(" ")
                          : el.name}
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <span
                      onClick={() => {
                        // console.log(5555555, el);
                        if (props.nested) {
                          props.setData((prevState) => ({
                            ...prevState,
                            [props.nested]: [
                              ...prevState[props.nested].slice(
                                0,
                                props.nestedIndex
                              ),
                              {
                                ...prevState[props.nested][props.nestedIndex],
                                [props.optionalLabel]: {
                                  ...prevState[props.nested][props.nestedIndex][
                                    props.optionalLabel
                                  ],
                                  value: el.name ? el.name : el,
                                  error: false,
                                  popup: false,
                                  prospect_id: [props.prospect_id]
                                    ? +el.id
                                    : null,
                                },
                              },
                              ...prevState[props.nested].slice(
                                props.nestedIndex + 1
                              ),
                            ],
                          }));
                        } else {
                          if (props.setActivePage) props.setActivePage();
                          props.setData((prevState) => ({
                            ...prevState,
                            [props.optionalLabel]: {
                              ...prevState[props.optionalLabel],
                              value: el.name ? el.name : el,
                              prospect_id: [props.prospect_id] ? +el.id : null,
                              error: false,
                              popup: false,
                              dataArray: props.dataArray
                                ? [
                                    ...prevState[props.optionalLabel].dataArray,
                                    el,
                                  ]
                                : null,
                            },
                          }));
                        }
                      }}
                      key={i}
                    >
                      {el.name
                        ? el.name.includes("_")
                          ? el.name.split("_").join(" ")
                          : el.name
                        : el.includes("_")
                        ? el.split("_").join(" ")
                        : el}
                    </span>
                  );
                }
              })
            ) : (
              <span style={{ background: "none", cursor: "auto" }}>
                No items
              </span>
            )
          ) : (
            <Loader
              color={{
                "--bgColor": "var(--gitgo-blue)",
                "--size": "40px",
                "--wrapper_size": "40px",
              }}
            />
          )}
        </div>
      </OutsideClickHandler>
      {props.error ? (
        <span
          className={"error_text"}
          style={{ color: "var(--error)", margin: "8px 0 0", padding: "0" }}
        >
          {props.errTitle ? props.errTitle : "Fill this label"}
        </span>
      ) : null}
    </div>
  );
}
