import React, { useEffect, useState } from "react";
import DashboardColumn from "../Components/Charts/DashboardColumn";
import DashboardFunnel from "../Components/Charts/DashboardFunnel";
// import OutsideClickHandler from "../../src/Constants/OutsideClickHandler";
import "../Pages/styles/dashboard.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import API, { baseURL } from "../api/API";
import { handleCurrentToken } from "../Constants/handleCurrentToken";
import MainButton from "../Components/CustomComponents/MainButton";
import { Loader } from "../Components/Loader";
import chevron from "../assets/Dashboard/Union.svg";
import StatisticModal from "../Components/StatisticModal";
import CustomSelect from "../Components/CustomSelect";

const topData = [
  {
    text: "revenue_potential",
    how: "#EDFCF5",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M24.7391 11.6667C24.4488 10.8431 23.9187 10.1252 23.217 9.60536C22.5153 9.08557 21.674 8.78767 20.8016 8.75H14.9683C13.8079 8.75 12.6951 9.21094 11.8747 10.0314C11.0542 10.8519 10.5933 11.9647 10.5933 13.125C10.5933 14.2853 11.0542 15.3981 11.8747 16.2186C12.6951 17.0391 13.8079 17.5 14.9683 17.5H20.8016C21.9619 17.5 23.0747 17.9609 23.8952 18.7814C24.7157 19.6019 25.1766 20.7147 25.1766 21.875C25.1766 23.0353 24.7157 24.1481 23.8952 24.9686C23.0747 25.7891 21.9619 26.25 20.8016 26.25H14.9683C14.0958 26.2123 13.2546 25.9144 12.5529 25.3946C11.8511 24.8748 11.321 24.1569 11.0308 23.3333'
          stroke='#4BDE97'
          strokeWidth='2.15385'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8848 26.25V30.625M17.8848 4.375V8.75V4.375Z'
          stroke='#4BDE97'
          strokeWidth='2.15385'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    text: "won_opportunities",
    how: "#FFF8ED",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17.8846 25.8853L8.88381 30.6176L10.6032 20.5944L3.31152 13.4967L13.374 12.0384L17.8744 2.91943L22.3749 12.0384L32.4374 13.4967L25.1457 20.5944L26.8651 30.6176L17.8846 25.8853Z'
          stroke='#FFB648'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    text: "contacts",
    how: "#FFF6E8",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17.8845 21.875C20.3008 21.875 22.2595 19.9162 22.2595 17.5C22.2595 15.0838 20.3008 13.125 17.8845 13.125C15.4683 13.125 13.5095 15.0838 13.5095 17.5C13.5095 19.9162 15.4683 21.875 17.8845 21.875Z'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8846 29.1668C24.3279 29.1668 29.5512 23.9435 29.5512 17.5002C29.5512 11.0568 24.3279 5.8335 17.8846 5.8335C11.4412 5.8335 6.2179 11.0568 6.2179 17.5002C6.2179 23.9435 11.4412 29.1668 17.8846 29.1668Z'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8845 2.9165V5.83317'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8845 29.1665V32.0832'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.5511 17.5H32.4678'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.30115 17.5H6.21781'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    text: "outreach",
    how: "#EFEAFD",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.75977 29.1667L6.6556 23.4792C5.01708 21.0558 4.42434 18.186 4.98758 15.4034C5.55083 12.6207 7.23173 10.1145 9.71776 8.35083C12.2038 6.58712 15.3258 5.68591 18.5032 5.81474C21.6807 5.94357 24.6974 7.09369 26.9925 9.05125C29.2875 11.0088 30.7048 13.6406 30.9807 16.4573C31.2566 19.274 30.3724 22.0839 28.4925 24.3646C26.6126 26.6453 23.8649 28.2415 20.7603 28.8566C17.6557 29.4716 14.4055 29.0636 11.6139 27.7083L4.75977 29.1667'
          stroke='#5F2EEA'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    text: "opportunities",
    how: "#EAEDFA",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.75928 30.625H31.0093'
          stroke='var(--gitgo-blue)'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.67578 30.625V10.2083L19.3425 4.375V30.625'
          stroke='var(--gitgo-blue)'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.9995 30V15.2222L28.9995 11V30'
          stroke='var(--gitgo-blue)'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    text: "won_revenue",
    how: "#EFF9FE",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.75952 30.625H31.0095'
          stroke='#39A0FF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.75952 14.5833H31.0095'
          stroke='#39A0FF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.67615 8.75L17.8845 4.375L28.0928 8.75'
          stroke='#39A0FF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.2179 14.5833V30.625'
          stroke='#39A0FF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.5511 14.5833V30.625'
          stroke='#39A0FF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.0511 20.4167V24.7917'
          stroke='#39A0FF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8845 20.4167V24.7917'
          stroke='#39A0FF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M23.7179 20.4167V24.7917'
          stroke='#39A0FF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
];

export default function Dashboard() {
  const [viewEngagement, setViewEngagement] = useState("");
  const [activeStatus, setActiveStatus] = useState({
    data: {
      value: viewEngagement ? viewEngagement : "all",
      options: ["all", "meeting", "email", "call"],
      popup: false,
    },
  });
  console.log(111111, viewEngagement);
  useEffect(() => {
    if (viewEngagement.length) {
      setActiveStatus((prevState) => {
        const { data } = prevState;
        data.value = viewEngagement;
        return {
          ...prevState,
          data,
        };
      });
    }
  }, [viewEngagement]);
  //  !
  const history = useHistory();
  const store = useSelector((state) => state.auth.user_data);
  const [data, setData] = useState({
    statistics: null,
    loading: false,
  });
  const [engagement, setEngagement] = useState({
    engagements_all: null,
    engagements: null,
    loading: false,
  });

  const [openStats, setOpenStats] = useState(false);

  useEffect(() => {
    if (store.role === "gitgo_admin_main") {
      history.push("/admin/workspaces");
    }
  }, [history, store.role]);

  useEffect(() => {
    API(handleCurrentToken())
      .get(`/dashboard/statistics`)
      .then((res) => {
        setData((prevState) => ({
          ...prevState,
          statistics: res.data.statistics,
          loading: true,
        }));
      });
  }, []);

  useEffect(() => {
    API(handleCurrentToken())
      .get(
        `/dashboard/recent_engagement?types_filter=${activeStatus.data.value}`
      )
      .then((res) => {
        setEngagement((prevState) => ({
          ...prevState,
          engagements_all:
            activeStatus.data.value === "all"
              ? res.data.engagements
              : prevState.engagements_all,
          engagements: res.data.engagements,
          loading: true,
        }));
      });
  }, [activeStatus.data.value]);

  const toggleUploadPopup = () => {
    setOpenStats(!openStats);
  };

  const allRecentActivity = (dashboard) => {
    const exportOutreach = () => {
      window.open(
        baseURL + `/data/export/recent_outreach?jwt=` + handleCurrentToken()
      );
    };
    return (
      <div
        className={
          dashboard
            ? "recent_engagement_wrapper"
            : "recent_engagement_wrapper_no-blur"
        }
      >
        {/* <OutsideClickHandler
          callback={() => {
            setViewEngagement(false);
          }}
        > */}
        <div className={"recent_activity_modal_window"}>
          <div className={"fixed_modal_header"}>
            <div className={"recent_engagement_modal_header"}>
              <span className={"recent_modal_title"}>Recent Outreach</span>
              <span className='recent_engagement_export-data'>
                <button
                  className='recent_engagement_export-dataBtn'
                  onClick={() => {
                    exportOutreach();
                  }}
                >
                  <svg
                    style={{ marginRight: "12px" }}
                    width='24'
                    height='24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17'
                      stroke='white'
                      stroke-width='2'
                      strokeLinecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M7 11L12 16L17 11'
                      stroke='white'
                      stroke-width='2'
                      strokeLinecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M12 4V16'
                      stroke='white'
                      stroke-width='2'
                      strokeLinecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                  Export Data
                </button>
              </span>
              <MainButton
                type={"button"}
                text={"Close"}
                className={"cancel_button"}
                onClick={() => {
                  setViewEngagement(false);
                  setActiveStatus((prevState) => ({
                    ...prevState,
                    data: {
                      ...prevState.data,
                      value: "All",
                    },
                  }));
                }}
              />
            </div>
            <div className='status-filter__innerList'>
              <div className='status-filter'>
                <CustomSelect
                  loading={true}
                  options={activeStatus.data.options}
                  className={"status_popup"}
                  optionalLabel={"data"}
                  width={250}
                  popupPositionTop={"37px"}
                  popupPositionLeft={"-10px"}
                  popupPositionWidth={"265px"}
                  svg={
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M8 10L12 14L16 10H8Z'
                        fill='var(--gitgo-blue)'
                        stroke='var(--gitgo-blue)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  }
                  onChange={({ target }) => {
                    setActiveStatus((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        value: target.value,
                      },
                    }));
                  }}
                  popup={activeStatus.data.popup}
                  setData={(e) => {
                    setTimeout(() => {
                      setActiveStatus(e);
                    }, 100);
                  }}
                  value={activeStatus.data.value}
                />
              </div>
            </div>
          </div>
          <div className={"modal_list_of_activity"}>
            <div>
              {engagement && engagement.engagements.length > 0 ? (
                engagement.engagements.map((item, index) => (
                  <div
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={"engagement_line"}
                    onClick={() =>
                      window.open(
                        `/admin/contacts/contact_info/${item.contact.id}`,
                        "_blank"
                      )
                    }
                  >
                    <div className={"left_engagement_side"}>
                      <div className={"user_data"}>
                        <span className={"user_name"}>{item.contact.name}</span>
                        <span className={"company_name"}>
                          {item.contact.prospect ?? "Unassigned"}
                        </span>
                        <span className={"logged_type"}>
                          {item.type === "meeting"
                            ? "Meeting"
                            : item.type === "call"
                            ? "Call"
                            : "Email"}
                        </span>
                      </div>
                    </div>

                    <div className={"date_of_engagement"}>
                      <span>{item.date}</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className={"no_activity_title"}>
                  <span>There has been no engagement recently</span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* </OutsideClickHandler> */}
      </div>
    );
  };
  const exportDashboard = () => {
    window.open(baseURL + `/data/export?jwt=` + handleCurrentToken());
  };
  return (
    <div>
      <div className={"main_workspace_wrapper"}>
        <div className={"dashboard_header"}>
          <span>
            Dashboard
            <button
              className='dashboard_header-btn_download'
              onClick={() => {
                exportDashboard();
              }}
            >
              <svg
                style={{ marginRight: "12px" }}
                width='24'
                height='24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17'
                  stroke='white'
                  stroke-width='2'
                  strokeLinecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M7 11L12 16L17 11'
                  stroke='white'
                  stroke-width='2'
                  strokeLinecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M12 4V16'
                  stroke='white'
                  stroke-width='2'
                  strokeLinecap='round'
                  stroke-linejoin='round'
                />
              </svg>
              Export Data
            </button>
          </span>
        </div>
        <div
          style={
            !data.loading
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "223px",
                }
              : null
          }
          className={"dashboard_total_blocks"}
        >
          {data && data.loading ? (
            Object.entries(data.statistics).map((el, i) => {
              let colorBlock = topData.find((i) => i.text === el[0]);
              return (
                <div
                  onClick={() => {
                    setOpenStats(el[1]);
                  }}
                  key={i}
                  className={"total_stat_block"}
                >
                  <div className={"left_block_side"}>
                    <span className={"value_total"}>
                      {el[0] === "revenue_potential" || el[0] === "won_revenue"
                        ? "$"
                        : ""}
                      {el[1].total
                        ? el[1].total
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : 0}
                    </span>
                    <span className={"total_text"}>
                      Total {el[0].split("_").join(" ")}
                    </span>
                    <div className={"rating_of_month"}>
                      <svg
                        width='22'
                        height='22'
                        viewBox='0 0 22 22'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M3.00952 15.375L8.25952 10.125L11.7595 13.625L18.7595 6.625'
                          stroke={
                            el[1].symbol === "down"
                              ? "var(--error)"
                              : el[1].symbol === "up"
                              ? "#4BDE97"
                              : "#FFB648"
                          }
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M12.6345 6.625H18.7595V12.75'
                          stroke={
                            el[1].symbol === "down"
                              ? "var(--error)"
                              : el[1].symbol === "up"
                              ? "#4BDE97"
                              : "#FFB648"
                          }
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <span
                        style={
                          el[1].symbol === "down"
                            ? { color: "var(--error)" }
                            : el[1].symbol === "up"
                            ? { color: "#4BDE97" }
                            : { color: "#FFB648" }
                        }
                        className={"procent_number"}
                      >
                        {el[1].percents}%
                      </span>
                      <span className={"period_text"}>Last month</span>
                    </div>
                  </div>
                  <div
                    style={{ background: colorBlock?.how }}
                    className={"ball_content"}
                  >
                    {topData.find((i) => i.text === el[0])?.icon}
                  </div>
                  {openStats === el[1] ? (
                    <StatisticModal
                      handleClose={toggleUploadPopup}
                      setViewEngagement={(e) => setViewEngagement(e)}
                      // allRecentActivity={(e) => allRecentActivity(e)}
                      colorBlock={colorBlock}
                      el={el}
                      setOpenStats={(e) => setOpenStats(e)}
                      // viewEngagement={viewEngagement}
                      openStats={openStats}
                      svg={topData.find((i) => i.text === el[0])?.icon}
                    />
                  ) : null}
                </div>
              );
            })
          ) : (
            <Loader
              color={{
                "--bgColor": "var(--gitgo-blue)",
                "--size": "40px",
                "--wrapper_size": "40px",
              }}
            />
          )}
        </div>
        <div className={"graph_wrapper"}>
          <div className={"total_opportunities_block"}>
            <div className={"travel_opportunities_block"}>
              <div className={"funnel_columns"}>
                <div className={"opportunities_title"}>
                  <span>Travel Opportunities Funnel</span>
                </div>
                <DashboardFunnel />
              </div>
            </div>
            <div className={"prospect_status_block"}>
              <div className={"content_block line_columns"}>
                <div className={"prospect_status_title"}>
                  <span>Contact Status</span>
                </div>
                <DashboardColumn />
              </div>
            </div>
            <div className={"recent_engagement_block"}>
              <div className={"content_block"}>
                <div className={"recent_engagement_title"}>
                  <span>Recent Outreach</span>

                  <div
                    onClick={() => {
                      setViewEngagement(true);
                    }}
                    className={"view_all_engagements_block"}
                  >
                    <span>View all</span>
                    <img src={chevron} alt='view_all' />
                  </div>
                </div>
                {activeStatus.data.options.includes(viewEngagement)
                  ? allRecentActivity(false)
                  : viewEngagement === true
                  ? allRecentActivity(true)
                  : null}
                <div
                  style={
                    !data.loading
                      ? {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "340px",
                        }
                      : null
                  }
                  className={"engagement_list"}
                >
                  {engagement.loading ? (
                    engagement.engagements_all.length > 0 ? (
                      engagement.engagements_all.map(
                        (item, index) =>
                          index <= 3 && (
                            <div
                              style={{ cursor: "pointer" }}
                              key={item.id}
                              className={"engagement_line"}
                              onClick={() =>
                                window.open(
                                  `/admin/contacts/contact_info/${item.contact.id}`,
                                  "_blank"
                                )
                              }
                            >
                              <div className={"left_engagement_side"}>
                                <div className={"user_data"}>
                                  <span className={"user_name"}>
                                    {item.contact.name}
                                  </span>
                                  <span className={"company_name"}>
                                    {item.contact.prospect ?? "Unassigned"}
                                  </span>
                                  <span className={"logged_type"}>
                                    {item.type === "meeting"
                                      ? "Meeting"
                                      : item.type === "call"
                                      ? "Call"
                                      : "Email"}
                                  </span>
                                </div>
                              </div>
                              <div className={"date_of_engagement"}>
                                <span>{item.date}</span>
                              </div>
                            </div>
                          )
                      )
                    ) : (
                      <div className={"no_activity_title"}>
                        <span>There has been no engagement recently</span>
                      </div>
                    )
                  ) : (
                    <Loader
                      color={{
                        "--bgColor": "var(--gitgo-blue)",
                        "--size": "40px",
                        "--wrapper_size": "40px",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
