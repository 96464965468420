import React, { useEffect, useState } from 'react';
import './styles/text_input.css';

function TextBox(props) {
    const [disabled, setDisabled] = useState(false)
    const [required, setRequired] = useState(false)

    useEffect(() => {
        if (props.disabled !== undefined && props.disabled !== null) {
            setDisabled(props.disabled)
        }
        if (props.required !== undefined && props.required !== null) {
            setRequired(props.required)
        }
    }, [props.disabled, props.required])

    return (
        <div style={props.margin ? { margin: props.margin } : null} className={'input_component'}>
            <span style={disabled ? { color: '#C2C2C2' } : null}>{props.title ? props.title : ''}</span>
            <textarea
                style={props.width ? { width: `${props.width}` } : props.height && { height: props.height }}
                disabled={disabled}
                name={props.name ? props.name : ""}
                required={required}
                placeholder={props.placeholder ? props.placeholder : null}
                className={props.error ? 'error textarea' : 'textarea'}
                rows={props.rows ? props.rows : 5}
                value={props.value ? props.value : ''}
                onChange={props.onChange ? e => {
                    props.onChange(e)
                } : null}
            />
            {props.error ?
                <span className={'input_component_error_text'}
                    style={{ color: 'var(--error)', margin: props.addMargin ? props.addMargin : '0' }}>
                    {props.errTitle}
                </span> : null}
        </div>
    );
}

export default TextBox;
