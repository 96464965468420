import React, { useEffect, useState } from "react";
import "./styles/manage_seats.css";
import { Loader } from "../../Components/Loader";
import SortingHeader from "../../Components/SortingHeader";
import { bodyOverflow } from "../../Constants/bodyOverflow";
import ModalWindow from "../../Components/ModalWindow";
import MainButton from "../../Components/CustomComponents/MainButton";
import AddEmailsWrapper from "../../Components/AddEmailsWrapper";
import API from "../../api/API";
import { handleCurrentToken } from "../../Constants/handleCurrentToken";
import ChangedConfirmedPopup from "../../Components/ChangedConfirmedPopup";
import useBottomPopup from "../../Constants/useBottomPopup";
import { useHistory } from "react-router-dom";
//import SubscriptionPlan from '../Profile/SubscriptionPlan'

const initialState = {
  total: null,
  managers: [],
};

const modalState = {
  value: null,
  data: "",
  type: "",
};

const ManageSeats = () => {
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const [manageData, setManageData] = useState(() => initialState);
  const [orderBy, setOrderBy] = useState({
    value: { request: "name", render: "Contact name" },
    sorting: true,
    options: [
      { request: "name", render: "Contact name" },
      { request: "email", render: "Email" },
      { request: "created", render: "Created on" },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [activeModal, setActiveModal] = useState(() => modalState);
  const [addData, setAddData] = useState([]);
  const [membersError, setMembersError] = useState(null);

  let subscriptionType = false;

  const history = useHistory();

  useEffect(() => {
    API(handleCurrentToken())
      .get(
        `/dashboard/manage_seats?order_by=${orderBy.value.request}&sorting=${
          orderBy.sorting ? "asc" : "desc"
        }`
      )
      .then((res) => {
        setManageData({
          total: res.data.total,
          managers: res.data.managers,
        });
        setLoading(true);
      });
  }, [orderBy]);

  const inviteMembers = () => {
    if (addData.length) {
      API(handleCurrentToken())
        .post(`/admin/add_manager`, {
          email: addData,
        })
        .then((res) => {
          bodyOverflow(false);
          setActiveModal(modalState);
          setManageData((prevState) => ({
            total: prevState.total + addData.length,
            managers: [...prevState.managers, ...res.data.managers],
          }));
          changeContinuePopup("Managers added");
          setTimeout(() => {
            changeContinuePopup("Managers added");
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setMembersError("Should be at least one manager");
    }
  };

  const deleteMember = () => {
    if (!isNaN(Number(activeModal.value))) {
      API(handleCurrentToken())
        .delete(`/dashboard/manage_seats?id=${activeModal.value}`)
        .then((res) => {
          setManageData((prevState) => ({
            total: prevState.total - 1,
            managers: prevState.managers.filter(
              (el) => el.id !== activeModal.value
            ),
          }));
          bodyOverflow(false);
          setActiveModal(modalState);
          changeContinuePopup("Manager deleted");
          setTimeout(() => {
            changeContinuePopup("Manager deleted");
          }, 2000);
        });
    }
  };

  return [
    <ChangedConfirmedPopup
      successText={successText}
      key={10}
      changingConfirmed={changingConfirmed}
    />,
    <div key={0} className={"manage_seats_wrapper"}>
      <h2 className={"page_title"}>Manage Seats</h2>
      <div className={"total_seats"}>
        <div>
          <h6>Total Seats</h6>
          {[
            loading ? (
              <span key={0}>{manageData.total}</span>
            ) : (
              <Loader
                key={1}
                color={{
                  "--bgColor": "var(--gitgo-blue)",
                  "--size": "44px",
                  "--wrapper_size": "44px",
                }}
              />
            ),
          ]}
        </div>
        <button
          onClick={() => {
            bodyOverflow(true);
            if (!subscriptionType && manageData.total >= 2) {
              setActiveModal((prevState) => ({
                ...prevState,
                value: true,
                type: "subscription",
              }));
            } else {
              setActiveModal((prevState) => ({
                ...prevState,
                value: true,
                type: "add_member",
              }));
            }
          }}
        >
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12 5V19'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M5 12H19'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </button>
      </div>

      <div className={"content"}>
        {loading ? (
          manageData.managers.length ? (
            <div className={"block-manage"}>
              <div className={"head"}>
                <SortingHeader
                  orderBy={orderBy}
                  setOrderBy={(val) => setOrderBy(val)}
                />
              </div>
              <div className={"body_content"}>
                {manageData.managers.map((el) => (
                  <div key={el.id} className={"item"}>
                    <p>{el.name}</p>
                    <span>{el.email ?? "email"}</span>
                    <span>{el.created_at}</span>
                    <div className={"manage-seats-icons-container"}>
                      <div className={"manage-seats-tooltip"}>
                        <span className={"manage-seats-tooltip-text"}>
                          Edit
                        </span>
                        <svg
                          onClick={() =>
                            history.push(
                              `/admin/manage_seats/edit_manager/${el.id}`
                            )
                          }
                          width='32'
                          height='32'
                          viewBox='0 0 32 32'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M5.33325 26.6667H10.6666L24.6666 12.6667C25.3738 11.9594 25.7712 11.0002 25.7712 10C25.7712 8.99983 25.3738 8.0406 24.6666 7.33336C23.9593 6.62612 23.0001 6.22879 21.9999 6.22879C20.9997 6.22879 20.0405 6.62612 19.3333 7.33336L5.33325 21.3334V26.6667Z'
                            stroke='#478ECC'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M18 8.66666L23.3333 14'
                            stroke='#478ECC'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                      <div className={"manage-seats-tooltip"}>
                        <span className={"manage-seats-tooltip-text"}>
                          Delete
                        </span>
                        <svg
                          onClick={() => {
                            bodyOverflow(true);
                            setActiveModal({
                              data: el.name,
                              value: el.id,
                              type: "delete",
                            });
                          }}
                          width='32'
                          height='32'
                          viewBox='0 0 32 32'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M6.66675 9.33331L8.00008 25.3333C8.00008 26.0406 8.28103 26.7188 8.78113 27.2189C9.28123 27.719 9.9595 28 10.6667 28H21.3334C22.0407 28 22.7189 27.719 23.219 27.2189C23.7191 26.7188 24.0001 26.0406 24.0001 25.3333L25.3334 9.33331'
                            stroke='#F26464'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 9.33333V5.33333C12 4.97971 12.1405 4.64057 12.3905 4.39052C12.6406 4.14048 12.9797 4 13.3333 4H18.6667C19.0203 4 19.3594 4.14048 19.6095 4.39052C19.8595 4.64057 20 4.97971 20 5.33333V9.33333'
                            stroke='#F26464'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M5.33325 9.33331H26.6666'
                            stroke='#F26464'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.3333 14.6667V22.6667'
                            stroke='#F26464'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M18.6667 14.6667V22.6667'
                            stroke='#F26464'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <span className={"no_items_style"}>No items</span>
          )
        ) : (
          <Loader
            key={1}
            color={{
              "--bgColor": "var(--gitgo-blue)",
              "--size": "40px",
              "--wrapper_size": "40px",
            }}
          />
        )}
      </div>
    </div>,
    activeModal.value ? (
      <ModalWindow
        key={1}
        nestedObj
        setActiveModal={(val) => setActiveModal(val)}
        setAddData={setAddData}
        deleteModal={activeModal.type === "delete"}
      >
        {activeModal.type === "add_member" ? (
          <>
            <h5>Add seats to team</h5>
            <p>Please invite contacts</p>
            <AddEmailsWrapper
              title={"Emails"}
              error={membersError}
              setMembersError={(e) => setMembersError(e)}
              data={addData}
              setData={(e) => setAddData(e)}
            />
            {membersError ? (
              <span className={"error_message"}>{membersError}</span>
            ) : null}
            <div className={"modal_window_buttons"}>
              <MainButton
                type={"button"}
                className={"cancel_button"}
                text={"Cancel"}
                onClick={() => {
                  setAddData([]);
                  setActiveModal(modalState);
                  bodyOverflow(false);
                }}
              />
              <MainButton
                type={"button"}
                className={"submit_button"}
                text={"Invite"}
                onClick={() => inviteMembers()}
              />
            </div>
          </>
        ) : manageData.total >= 2 && activeModal.type === "subscription" ? (
          <div className={"review-subscription-container"}>
            <div className={"review-subscription-title"}>
              <span id={"change-password"} className={"change-password-title"}>
                You cannot add more seats according to your current subscription
                plan
              </span>
              <span className={"email-gitgo-support"}>
                Email GitGo support team to review your current subscription
                plan
              </span>
            </div>
            <div className='got-it-button'>
              <MainButton
                type={"button"}
                className={"cancel_button"}
                text={"Got it!"}
                onClick={() => {
                  setAddData([]);
                  setActiveModal(modalState);
                  bodyOverflow(false);
                }}
              />
            </div>
          </div>
        ) : (
          <div className={"delete_modal"}>
            <span>Delete</span>
            <h3>{activeModal.data}</h3>
            <p>Are you sure you want to permanently delete this manager?</p>
            <div className={"modal_window_buttons"}>
              <MainButton
                type={"button"}
                className={"cancel_button"}
                text={"Cancel"}
                onClick={() => {
                  setActiveModal(modalState);
                  bodyOverflow(false);
                }}
              />
              <MainButton
                type={"button"}
                className={"delete_button"}
                text={"Delete"}
                onClick={() => deleteMember()}
              />
            </div>
          </div>
        )}
      </ModalWindow>
    ) : null,
  ];
};

export default ManageSeats;
