import React, {useEffect, useState} from 'react';
import './styles/multiply_select.css';
import OutsideClickHandler from "../../Constants/OutsideClickHandler";

export default function MultipleSelect(props) {

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (props.dataArray.length > 2 && props.disabled) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [props.dataArray, props.disabled])

    return (
        <div className={'multiply_select_wrapper'}>
            {props.title ? <span className={`title`}>{props.title}</span> : null}
            <div style={props.error ? {border: '1px solid var(--error)'}
                : props.popup ? {pointerEvents: 'none'} : null}
                 onClick={() => props.handlePopup()}
                 className={`content ${disabled ? 'content_disabled' : ''}`}>
                <div className={'items'}>
                    {props.dataArray.length ? props.dataArray.map((el, i) => (
                        <span key={i}>{el}{props.dataArray.length > 1 ? ';' : ''} </span>
                    )) : null}
                </div>
                {props.icon ? props.icon
                    : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 10L12 14L16 10H8Z" fill="#0F172A" stroke="#0F172A" strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                }
            </div>
            <OutsideClickHandler callback={() => {
                if (props.popup) props.handlePopup()
            }}>
                <div style={props.triple ? {top: '8rem'} : props.monoLine ? {top: '4.5rem'} : null}
                     className={`select_popup overflow_scrollbar ${props.popup ? 'select_popup_active' : ''}`}>
                    {props.options.length ? props.options.map((el, i) => (
                        <div style={disabled && !props.dataArray.includes(el) ? {
                            color: '#94A3B8',
                            cursor: 'auto'
                        } : null} key={i}
                             className={'item'}
                             onClick={() => props.setData(el)}>
                            <div style={disabled && !props.dataArray.includes(el) ? {border: '2px solid#94A3B8'} : null}
                                 className={`checkbox ${props.dataArray.includes(el) ? 'checkbox_active' : ''}`}>
                                <svg width="8" height="6" viewBox="0 0 8 6" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 3L3 5L7 1" stroke="var(--gitgo-blue)" strokeWidth="2" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <span key={i}>{el}</span>
                        </div>
                    )) : <span className={'no_items_style'}>No items</span>}
                </div>
            </OutsideClickHandler>
            {props.error ? <span className={`error_title`}>{props.errTitle ?? 'Fill this label'}</span> : null}
        </div>
    );
}