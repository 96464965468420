import React, {useState} from 'react'

import './styles/profile_change_password.css'
import API from "../../api/API";

const ChangePasswordPopup = (props) => {
    const [counter, setCounter] = useState(0);

    const incCounter = () => {
        setCounter(counter + 1)
    }

    //const decCounter = () => {
    //    setCounter(counter > 0 ? counter - 1 : 0);
    //}

    const windowStates = [
        {
            title: 'Are you sure you want to change your password?',
            firstButton: 'Cancel',
            secondButton: 'Change password'
        },
        {
            title: 'An email containing your password reset link has been sent to you',
            firstButton: 'Resend Email',
            secondButton: 'Close',
        }
    ];

    const forgotPasswordAPI = () => {
        API().post(`/forgot_password/email`, {
            email: props.changePasswordEmail
        })
            .then(() => {
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div>
            <div className="popup-box">
                <div className="change-password-box">
                    <div className={'change-password-title-box'}>
                        <span id={'change-password'}
                              className={'change-password-title'}>{windowStates[counter].title}</span>
                    </div>
                    <div className='change-password-buttons-container'>
                        <button id={'resend-email'}
                                onClick={counter === 0 ? props.handleClose : forgotPasswordAPI}>{windowStates[counter].firstButton} </button>
                        <button id={'close-change'} onClick={counter === 0 ? () => {
                            incCounter();
                            forgotPasswordAPI()
                        } : props.handleClose}> {windowStates[counter].secondButton} </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordPopup