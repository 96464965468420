import React, { useState } from "react";
// import CustomSelect from "./CustomSelect";
import PaginationView from "./PaginationView";
import { getActivePage } from "../Constants/getActivePage";
import { Loader } from "./Loader";
import "./styles/pagination_wrapper.css";

export default function PaginationWrapper(props) {
  const { activePage, perPage, setPerPage, setActivePage, loading } = props;
  const [activeDropDown, setActiveDropDown] = useState(false);
  //   const [per, setPer] = useState({
  //     data: {
  //       value: "10",
  //       options: ["10", "50", "100"],
  //       popup: false,
  //     },
  //   });

  console.log(perPage);
  return (
    activePage?.total > 10 && (
      <div className={"pagination_wrapper"}>
        <div className={"pagination_per_page"}>
          <span>Show</span>

          <div
            style={{
              width: activeDropDown ? "165px" : "100px",
            }}
            className={"page_block_all"}
          >
            <span>{perPage.data.value}</span>
            <div
              style={{
                width: activeDropDown ? "130px" : "0px",
              }}
              className={"drop_block"}
            >
              {perPage.data.options
                .filter((x) => x !== perPage.data.value)
                .map((item) => (
                  <span
                    className='cursor'
                    onClick={() => {
                      setActiveDropDown(false);

                      setPerPage(() => ({
                        data: {
                          value: item,
                          options: [...perPage.data.options],
                          popup: false,
                        },
                      }));
                    }}
                  >
                    {item}
                  </span>
                ))}
            </div>
            <div
              className='cursor'
              onClick={() => {
                setActiveDropDown(!activeDropDown);
              }}
              style={{
                transition: "0.5s",
                padding: "0px 2px",
                transform: activeDropDown ? "rotate(90deg)" : "rotate(270deg)",
              }}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8 10L12 14L16 10H8Z'
                  fill='var(--gitgo-blue)'
                  stroke='var(--gitgo-blue)'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </div>
          {/* <CustomSelect loading={true}
                              options={perPage.data.options}
                              className={'pagination_popup'}
                              optionalLabel={'data'}
                              svg={<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8 10L12 14L16 10H8Z" fill="var(--gitgo-blue)" stroke="var(--gitgo-blue)" strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>}
                              setActivePage={() => {
                                  setActivePage(prevState => ({
                                      ...prevState,
                                      page: 1
                                  }))
                              }}
                              popup={perPage.data.popup}
                              setData={e => setPerPage(e)}
                              value={perPage.data.value}/> */}
        </div>

        {activePage?.total > perPage.data.value ? (
          loading ? (
            <div className={"pagination_content"}>
              <PaginationView
                activePage={activePage}
                onChange={(pageNumber) => {
                  getActivePage(pageNumber);
                  setActivePage((prevState) => ({
                    ...prevState,
                    page: pageNumber,
                  }));
                }}
              />
            </div>
          ) : (
            <Loader
              color={{
                "--bgColor": "var(--gitgo-blue)",
                "--size": "48px",
                "--wrapper_size": "40px",
              }}
            />
          )
        ) : null}
      </div>
    )
  );
}
