import React, {useEffect, useState} from "react";
import './styles/login_signup.css'
import LoginWrapper from "../Components/LoginWrapper";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Keys from "../Constants/helper";
import API from "../api/API";
import {setUserData} from "../main-store/auth/actions";

export default function GitGoAdminLogin() {
    const dispatch = useDispatch();
    const history = useHistory();
    const store = useSelector(state => state.auth.user_data);

    const [loginData, setLoginData] = useState({
        email: {
            value: '',
            error: false,
            errorMessage: '',
        },
        password: {
            value: '',
            error: false,
            errorMessage: '',
        },
    });
    const [loginError, setLoginError] = useState(null);

    useEffect(() => {
        if (localStorage.getItem(Keys.JWT_TOKEN) && store) {
            store.role === 'gitgo_admin_main' ? history.push('/admin/workspaces') : history.push('/admin/dashboard')
        }
    }, [store, history])

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        const {email, password} = loginData;

        const loginCondition = email.value && password.value
        if (loginCondition) {
            formData.append('login', email.value)
            formData.append('password', password.value)
            API().post(`/gitgo_admin/login`, formData)
                .then(res => {
                    // console.log(res)
                    localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
                    dispatch(setUserData({
                        role: res.data.role,
                        first_name: 'Git Go',
                        last_name: 'Admin',
                    }));
                    history.push('/admin/workspaces')
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.error.toLowerCase() === "cant find user") {
                        setLoginError('User not found')
                    } else if (err.response.data.error.toLowerCase() === "invalid password") {
                        setLoginError('Wrong password')
                    }
                })
        }
    }

    return <LoginWrapper setLoginData={e => setLoginData(e)}
                         loginStep={'login'}
                         setLoginError={val => setLoginError(val)}
                         loginError={loginError}
                         handleLoginSubmit={handleLoginSubmit}
                         loginData={loginData}/>
}