import axios from "axios";
import helper from "../Constants/helper";
// import {clearCookies} from "../Constants/clearCookies";

export const baseURL = process.env.REACT_APP_API_URL;

function API(token = null) {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (token ?? localStorage.getItem(helper.JWT_TOKEN)) {
    headers.Authorization =
      "Bearer " + (token ?? localStorage.getItem(helper.JWT_TOKEN));
  }

  let api = axios.create({
    baseURL: baseURL,
    timeout: 60000,
    headers: headers,
  });

  api.interceptors.request.use((request) => requestHandler(request));

  api.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
  );

  const successHandler = (response) => response;
  const requestHandler = (request) => request;

  const errorHandler = (error) => {
    if (error?.response?.status === 401) {
      // if (error?.response?.data?.error.toLowerCase() === 'token has been revoked' || error?.response?.data?.error.toLowerCase() === "token has expired") {
      userLogout();
      // }
      // console.log(error)
      return Promise.reject({ ...error });
    } else if (error?.response?.status === 404) {
      console.log(error);
      // debugger
      // window.location = "/404"
      return Promise.reject({ ...error });
    } else {
      console.log(error);
      // debugger
      return Promise.reject({ ...error });
    }
  };
  return api;
}

export function userLogout(isUser = null) {
  if (isUser) {
    API()
      .post("/user/logout")
      .then(() => forceLogout())
      .catch(() => forceLogout());
  } else {
    forceLogout();
  }
}

export function forceLogout() {
  // clearCookies()
  localStorage.clear();
  sessionStorage.clear();
  window.location = "/";
}

export default API;
