import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./styles/contacts.css";
import plus from "../../assets/img/plus.svg";
import search from "../../assets/img/search-grey.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import OutsideClickHandler from "../../Constants/OutsideClickHandler";
import UploadContentPopUp from "../../Components/UploadContentPopUp/UploadContentPopUp";
import API from "../../api/API";
import { handleCurrentToken } from "../../Constants/handleCurrentToken";
import { useGetLastChartHash } from "../../Constants/useGetLastChartHash";
import SortingHeader from "../../Components/SortingHeader";
import { Loader } from "../../Components/Loader";
import AssignPopUp from "../../Components/AssignPopUp/AssignPopUp";
import DeletePopUp from "../../Components/DeletePopUp/DeletePopUp";
import PaginationWrapper from "../../Components/PaginationWrapper";
import ModalWindow from "../../Components/ModalWindow";
import MainButton from "../../Components/CustomComponents/MainButton";
import { bodyOverflow } from "../../Constants/bodyOverflow";
import ChangedConfirmedPopup from "../../Components/ChangedConfirmedPopup";
import useBottomPopup from "../../Constants/useBottomPopup";
import CustomSelect from "../../Components/CustomSelect";
// import moment from "moment";

const modalState = {
  value: false,
  data: "",
};

const initialState = {
  contact_prospects: {
    value: {
      id: "",
      name: "",
    },
    prospect_id: "",
    error: false,
    monoNestedOptions: true,
    errorMessage: "Fill this label",
    options: [],
    popup: false,
    loading: false,
  },
};

const Contact = () => {
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const { activeHashUrl } = useGetLastChartHash();
  const history = useHistory();
  const location = useLocation();
  const store = useSelector((state) => state.auth.user_data);
  const [contactsData, setContactsData] = useState(null);
  const [csvFile, setCSVFile] = useState(null);
  const [orderBy, setOrderBy] = useState({
    value: { request: "name", render: "Name" },
    sorting: "asc",
    options: [
      { request: "name", render: "Contact Name" },
      { request: "status", render: "Status" },
      { request: "email", render: "Email" },
      { request: "prospects", render: "Company Name" },
      { request: "job_title", render: "Job Title" },
      { request: "phone", render: "Phone" },
      { request: "created", render: "Created on" },
      { request: "last_engaged", render: "Last Activity" },
    ],
  });
  const [activePage, setActivePage] = useState({
    page: null,
    pages: null,
    total: null,
  });
  const [loading, setLoading] = useState(false);

  const [activeDropDown, setActiveDropDown] = useState(false);
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [assignPopupData, setAssignPopupData] = useState(() => initialState);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [assignContact, setAssignContact] = useState(false);
  const [data, setData] = useState(false);
  const [checkAssign, setCheckAssign] = useState(false);
  const [deletePop, setDeletePop] = useState(false);
  const [assignIsOpen, setAssignIsOpen] = useState({
    index: null,
    value: false,
    data: null,
  });
  const [activeSearch, setActiveSearch] = useState("");
  const [activeModal, setActiveModal] = useState(modalState);
  const [perPage, setPerPage] = useState({
    data: {
      value: "10",
      options: ["10", "50", "100"],
      popup: false,
    },
  });
  const [activeStatus, setActiveStatus] = useState({
    data: {
      value: history.location.search?.includes("status")
        ? history.location.search.split("=")[1].split("_").join(" ")
        : "All",
      options: [
        "All",
        "New",
        "Property Information Requested",
        "Ready for Engagement",
        "Inactive",
        // "Existing",
      ],
      popup: false,
    },
  });
  console.log(location);
  useEffect(() => {
    if (store.role === "gitgo_admin_main") {
      history.push("/admin/workspaces");
    }
  }, [history, store.role]);

  useEffect(() => {
    if (activeHashUrl) {
      setActivePage((prevState) => ({
        ...prevState,
        page: activeHashUrl,
      }));
    }
  }, [activeHashUrl]);

  useEffect(() => {
    if (activePage.page) {
      const _fetchData = () => {
        API(handleCurrentToken())
          .get(`/contacts`, {
            params: {
              order_by: orderBy.value.request,
              search: activeSearch,
              page: activePage.page,
              sorting: orderBy.sorting ? "asc" : "desc",
              status_filter: activeStatus.data.value,
              per_page: perPage.data.value,
            },
          })
          .then((res) => {
            console.log(res);
            res.data.contacts.forEach((el) => {
              el.popup = false;
              el.prospect_popup = false;
            });
            setContactsData(res.data.contacts);
            setActivePage((prevState) => ({
              ...prevState,
              pages: res.data.pages,
              total: res.data.total,
            }));
            // setActiveStatus((prevState) => ({
            //   ...prevState,
            //   data: {
            //     ...prevState.data,
            //     value: location.search
            //       ? location.search.replace("?status=", "")
            //       : "all",
            //   },
            // }));
            setLoading(true);
          });
      };
      _fetchData();
    }
  }, [
    orderBy,
    activePage.page,
    activeSearch,
    perPage.data.value,
    activeStatus.data.value,
  ]);

  const fetchData = () => {
    API(handleCurrentToken())
      .get(`/contacts`, {
        params: {
          order_by: orderBy.value.request,
          search: activeSearch,
          page: activePage.page,
          sorting: orderBy.sorting ? "asc" : "desc",
          status_filter: activeStatus.data.value,
          per_page: perPage.data.value,
        },
      })
      .then((res) => {
        console.log(res);
        res.data.contacts.forEach((el) => {
          el.popup = false;
          el.prospect_popup = false;
        });
        setContactsData(res.data.contacts);
        setActivePage((prevState) => ({
          ...prevState,
          pages: res.data.pages,
          total: res.data.total,
        }));
        setLoading(true);
      });
  };

  useEffect(() => {
    API(handleCurrentToken())
      .get(`/contacts/assign_prospect`)
      .then((res) => {
        setAssignPopupData((state) => ({
          contact_prospects: {
            ...state.contact_prospects,
            options: res.data.prospects,
            loading: true,
          },
        }));
      });
  }, []);
  useEffect(() => {
    API(handleCurrentToken())
      .get(`/prospects`)
      .then((res) => {
        setData(res.data);
      });
  }, []);
  console.log(data.prospects);

  const toggleDeletePopup = () => {
    setDeleteIsOpen(!deleteIsOpen);
  };

  const toggleUploadPopup = () => {
    setUploadIsOpen(!uploadIsOpen);
  };
  console.log(activeModal);
  const handleDeleteContact = () => {
    API(handleCurrentToken())
      .delete(`/contacts?contacts_ids=${selectedWorkspaces.join()}`)
      .then((res) => {
        if (res.status === 200) {
          setContactsData((state) =>
            state.filter((el) => el.id !== activeModal.value)
          );
          selectWorkspace([]);
          changeContinuePopup(`Contact ${activeModal.data} deleted`);
          setTimeout(() => {
            changeContinuePopup(`Contact ${activeModal.data} deleted`);
          }, 2000);
          setActiveModal(modalState);
          bodyOverflow(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error === "there must be at least one contact") {
          alert(`This contact the last in prospect so you can't delete it`);
          setActiveModal(modalState);
          bodyOverflow(false);
        }
      });
  };

  const uploadFile = () => {
    if (csvFile) {
      const formData = new FormData();
      formData.append("file", csvFile);
      formData.append("data", "contacts");

      API(handleCurrentToken())
        .post(`/data/upload`, formData)
        .then((res) => {
          toggleUploadPopup();
          changeContinuePopup(
            "The contacts from your CSV file have been added"
          );
          setTimeout(() => {
            changeContinuePopup(
              "The contacts from your CSV file have been added"
            );
          }, 2000);
          fetchData();
        })
        .catch((error) => console.log(error));
    }
  };

  const [selectedWorkspaces, selectWorkspace] = useState([]);

  const deleteContacts = () => {
    API(handleCurrentToken())
      .delete(`/contacts?contacts_ids=${selectedWorkspaces.join()}`)
      .then((res) => {
        selectWorkspace([]);
        fetchData();
      })
      .catch((error) => console.log(error));
  };

  const handleSelectWorspace = (id) => {
    if (selectedWorkspaces.includes(id)) {
      selectWorkspace(selectedWorkspaces.filter((item) => item !== id));
    } else {
      selectWorkspace((prevState) => [...prevState, id]);
    }
  };
  console.log(selectedWorkspaces);
  console.log("JDSJDSJ", assignPopupData);
  const assignProspect = () => {
    API(handleCurrentToken())
      .post(`/prospects/contacts`, {
        contact_ids: selectedWorkspaces.join(),
        prospect_id: checkAssign,
      })
      .then((res) => {
        if (res.status === 200) {
          setAssignContact(false);
          // setAssignIsOpen({
          //     value: false,
          //     index: null,
          //     data: null,
          // })
          // setContactsData(prevState => [
          //     ...prevState.slice(0, assignIsOpen.index),
          //     {
          //         ...assignIsOpen.data, prospect: {
          //             name: assignPopupData.contact_prospects.value.name,
          //             id: assignPopupData.contact_prospects.value.id
          //         }, popup: false,
          //     },
          //     ...prevState.slice(assignIsOpen.index + 1)
          // ])
          window.location.href = "/admin/contacts";
          setTimeout(() => {
            changeContinuePopup("Prospect assigned");
          }, 2000);
        }
      });
  };
  const assignContacts = () => {
    return (
      <div className='assign-contacts-background'>
        <OutsideClickHandler
          callback={() => {
            setAssignContact(false);
          }}
        >
          <div className='assign-modal-window'>
            <div className='modal-header'>
              <span>Prospects</span>
              <div className='modal-buttons'>
                <button
                  onClick={() => {
                    assignProspect();
                  }}
                  className='assign-button'
                >
                  Assign
                </button>
                <button
                  onClick={() => setAssignContact(false)}
                  className='close-button'
                >
                  Close
                </button>
              </div>
            </div>
            <div className='container-assign'>
              {data.prospects.map((item, index) => (
                <div
                  key={index}
                  className={`assign-line ${
                    checkAssign === item.id ? "checked" : null
                  }`}
                >
                  <div className='right-info-side'>
                    <div
                      onClick={() => {
                        console.log(item.id);
                        setCheckAssign(item.id);
                      }}
                      className={`check-circle ${
                        checkAssign === item.id ? "checked" : null
                      } `}
                    />
                    <span>{item.name}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    );
  };

  return [
    <ChangedConfirmedPopup
      successText={successText}
      key={10}
      changingConfirmed={changingConfirmed}
    />,
    <div key={0}>
      <div className='contacts-container'>
        <div>
          <div className='contacts-title'>
            <p className='title-page'>Contacts</p>

            <div
              className={`contacts-button-new ${
                activeDropDown ? "active" : ""
              }`}
            >
              <button
                style={activeDropDown ? { pointerEvents: "none" } : null}
                onClick={() => setActiveDropDown(true)}
              >
                <img alt={"add"} src={plus} />
                <span>New</span>
              </button>
              {activeDropDown && (
                <OutsideClickHandler callback={() => setActiveDropDown(false)}>
                  <div className='contacts-button-dropdown-container'>
                    <div className='contacts-button-dropdown'>
                      <button>
                        <Link
                          onClick={() => setActiveDropDown(false)}
                          to={`/admin/contacts/create_contact/new_contact`}
                        >
                          <p>Create Contact</p>
                        </Link>
                      </button>
                      <button onClick={toggleUploadPopup}>
                        <p>Upload</p>
                      </button>
                      {uploadIsOpen && (
                        <UploadContentPopUp
                          handleClose={toggleUploadPopup}
                          onDrop={(file) => setCSVFile(file)}
                          uploadFile={uploadFile}
                        />
                      )}
                      {/* <button onClick={() => setActiveDropDown(false)}>
                                                <p>Download CSV template</p>
                                            </button> */}
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridGap: "8px",
              marginBottom: "16px",
              gridTemplateColumns: "max-content 1fr",
              alignItems: "flex-start",
              height: "max-content",
            }}
          >
            <div className='contacts-search-container'>
              <input
                placeholder='Search'
                value={activeSearch}
                onChange={({ target }) => setActiveSearch(target.value)}
              />
              <button>
                <img src={search} alt={"img"} />
              </button>
            </div>
            <div className='status-filter mt-37'>
              <CustomSelect
                loading={true}
                options={activeStatus.data.options}
                className={"status_popup"}
                optionalLabel={"data"}
                svg={
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M8 10L12 14L16 10H8Z'
                      fill='var(--gitgo-blue)'
                      stroke='var(--gitgo-blue)'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                }
                onChange={({ target }) => {
                  setActiveStatus((prevState) => ({
                    ...prevState,
                    value: target.value,
                  }));
                }}
                popup={activeStatus.data.popup}
                setData={(e) => setActiveStatus(e)}
                value={activeStatus.data.value}
              />
            </div>
          </div>

          {selectedWorkspaces.length > 0 && (
            <div
              style={{ display: "flex", gap: 16, width: 400, marginBottom: 20 }}
            >
              <MainButton
                type={"button"}
                style={{
                  backgroundColor: "transparent",
                  color: "#13426B",
                  borderColor: "#13426B",
                }}
                svg={
                  <svg
                    width='26'
                    height='26'
                    viewBox='0 0 26 26'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4.38477 18.3848V20.5386C4.38477 21.1098 4.61169 21.6577 5.01561 22.0616C5.41954 22.4655 5.96738 22.6925 6.53861 22.6925H19.4617C20.0329 22.6925 20.5808 22.4655 20.9847 22.0616C21.3886 21.6577 21.6155 21.1098 21.6155 20.5386V18.3848'
                      stroke='#13426B'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M7.61572 11.9231L13.0003 17.3077L18.385 11.9231'
                      stroke='#13426B'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M13.0005 4.38477V17.3078'
                      stroke='#13426B'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                }
                className={"button_default_plus contacts"}
                text={`Assign ${selectedWorkspaces.length} contacts to a prospect`}
                onClick={() => setAssignContact(true)}
              />
              <MainButton
                style={{
                  backgroundColor: "transparent",
                  color: "#F26464",
                  borderColor: "#F26464",
                }}
                type={"button"}
                svg={
                  <svg
                    width='27'
                    height='26'
                    viewBox='0 0 27 26'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M5.61572 7.61548L6.69265 20.5386C6.69265 21.1098 6.91957 21.6576 7.32349 22.0616C7.72742 22.4655 8.27526 22.6924 8.84649 22.6924H17.4619C18.0331 22.6924 18.581 22.4655 18.9849 22.0616C19.3888 21.6576 19.6157 21.1098 19.6157 20.5386L20.6926 7.61548'
                      fill='#FEEFEF'
                    />
                    <path
                      d='M5.61572 7.61548L6.69265 20.5386C6.69265 21.1098 6.91957 21.6576 7.32349 22.0616C7.72742 22.4655 8.27526 22.6924 8.84649 22.6924H17.4619C18.0331 22.6924 18.581 22.4655 18.9849 22.0616C19.3888 21.6576 19.6157 21.1098 19.6157 20.5386L20.6926 7.61548'
                      stroke='#F26464'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M9.92334 7.61555V4.38478C9.92334 4.09917 10.0368 3.82525 10.2388 3.62328C10.4407 3.42132 10.7146 3.30786 11.0003 3.30786H15.308C15.5936 3.30786 15.8675 3.42132 16.0695 3.62328C16.2714 3.82525 16.3849 4.09917 16.3849 4.38478V7.61555'
                      fill='#FEEFEF'
                    />
                    <path
                      d='M9.92334 7.61555V4.38478C9.92334 4.09917 10.0368 3.82525 10.2388 3.62328C10.4407 3.42132 10.7146 3.30786 11.0003 3.30786H15.308C15.5936 3.30786 15.8675 3.42132 16.0695 3.62328C16.2714 3.82525 16.3849 4.09917 16.3849 4.38478V7.61555'
                      stroke='#F26464'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M4.53857 7.61548H21.7693'
                      stroke='#F26464'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M11 11.9231V18.3846'
                      stroke='#F26464'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M15.3081 11.9231V18.3846'
                      stroke='#F26464'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                }
                className={"button_default_plus contacts"}
                text={`Delete ${selectedWorkspaces.length} ${
                  selectedWorkspaces.length < 2 ? "Contact" : "Contacts"
                }`}
                onClick={() => setDeletePop(true)}
              />
              {deletePop && (
                <ModalWindow
                  key={1}
                  nestedObj
                  setActiveModal={(val) => setActiveModal(val)}
                  deleteModal
                >
                  <div className={"delete_modal"}>
                    <span>Delete</span>
                    <p>{`Are you sure you want to permanently delete ${
                      selectedWorkspaces.length
                    } ${
                      selectedWorkspaces.length < 2 ? "contact" : "contacts"
                    }`}</p>
                    <div className={"modal_window_buttons"}>
                      <MainButton
                        type={"button"}
                        className={"cancel_button"}
                        text={"Cancel"}
                        onClick={() => {
                          setDeletePop(false);
                          bodyOverflow(false);
                        }}
                      />
                      <MainButton
                        type={"button"}
                        className={"delete_button"}
                        text={"Delete"}
                        onClick={() => deleteContacts()}
                      />
                    </div>
                  </div>
                </ModalWindow>
              )}
            </div>
          )}
          {assignContact ? assignContacts() : null}
          <div className={"contacts-items-container"}>
            <div className='contacts-container-content' id='scroll-bar'>
              <div className='contacts-items-content'>
                <SortingHeader
                  orderBy={orderBy}
                  setOrderBy={(val) => setOrderBy(val)}
                />
              </div>

              <div
                style={
                  !loading
                    ? {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "200px",
                      }
                    : null
                }
                className={"contacts-items-content-body"}
              >
                {loading ? (
                  contactsData.length ? (
                    contactsData.map((el, i) => (
                      <div key={i} className={"item"}>
                        <span
                          className={"checkbox"}
                          style={{ lineHeight: 0, cursor: "pointer" }}
                          onClick={() => handleSelectWorspace(el.id)}
                        >
                          {selectedWorkspaces.includes(el.id) && (
                            <svg
                              width='8'
                              height='6'
                              viewBox='0 0 8 6'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M1 3L3 5L7 1'
                                stroke='#01426A'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          )}
                        </span>
                        <span
                          onClick={() =>
                            history.push(
                              `/admin/contacts/contact_info/${el.id}`
                            )
                          }
                          className={"contacts-items-span name"}
                        >
                          {el.name ?? "No name yet"}
                        </span>
                        <div className={"prospects-status-container"}>
                          <span className={"status"}>
                            {el.status ? el.status : "no status"}
                          </span>
                        </div>
                        <span className={"contacts-items-span"}>
                          {el.email ?? "No email yet"}
                        </span>
                        <span>
                          {/*
                                            <div>
                                                {el.prospect_popup ?
                                                    <OutsideClickHandler callback={() => {
                                                        el.prospect_popup = false
                                                        setContactsData(prevState => [
                                                            ...prevState.slice(0, i),
                                                            el,
                                                            ...prevState.slice(i + 1)
                                                        ])
                                                    }}>
                                                        <div className={'popup_prospect_content'}>
                                                            <div className={'popup_prospect_content_item'}>
                                                                {el.prospects.prospects.map((p, num) => <span
                                                                    key={num}>{p.name}</span>)}
                                                            </div>
                                                        </div>
                                                    </OutsideClickHandler> : null}
                                                </div>
                                                */}
                          <span
                            style={!el.prospect.id ? { cursor: "auto" } : null}
                            onClick={() => {
                              if (el.prospect && el.prospect.id) {
                                history.push(
                                  `/admin/companies/${el.prospect.id}`
                                );
                              }
                            }}
                            className={"contacts-prospects-items-span"}
                          >
                            {el.prospect.name ?? "Unassigned"}
                          </span>
                        </span>
                        <span className={"contacts-items-span"}>
                          {el.job_title ?? "No job title yet"}
                        </span>
                        <span className={"contacts-items-span"}>
                          {el.phone ?? "No phone yet"}
                        </span>
                        <span className={"contacts-items-span"}>
                          {el.created ?? "No login yet"}
                        </span>
                        <span className={"contacts-items-span"}>
                          {el.last_engaged ?? "No activity yet"}
                        </span>
                        <div className={"popup_wrap"}>
                          {el.popup ? (
                            <OutsideClickHandler
                              callback={() => {
                                el.popup = false;
                                setContactsData((prevState) => [
                                  ...prevState.slice(0, i),
                                  el,
                                  ...prevState.slice(i + 1),
                                ]);
                              }}
                            >
                              <div className={"popup-content-menu"}>
                                <button
                                  onClick={() => {
                                    history.push(
                                      `/admin/contacts/create_contact/${el.id}`
                                    );
                                  }}
                                >
                                  <span className={"popup-content-menu-item"}>
                                    Edit
                                  </span>
                                </button>
                                <button
                                  onClick={() => {
                                    setAssignIsOpen({
                                      data: el,
                                      index: i,
                                      value: true,
                                    });
                                    setAssignPopupData((state) => ({
                                      contact_prospects: {
                                        ...state.contact_prospects,
                                        value: {
                                          id: el.prospect.id ?? "",
                                          name: el.prospect.name ?? "",
                                        },
                                      },
                                    }));
                                    setContactsData((prevState) => [
                                      ...prevState.slice(0, i),
                                      { ...el, popup: false },
                                      ...prevState.slice(i + 1),
                                    ]);
                                  }}
                                >
                                  <span className={"popup-content-menu-item"}>
                                    Assign contact
                                  </span>
                                </button>
                                <button
                                  onClick={() => {
                                    setActiveModal({
                                      value: el.id,
                                      data: el.name,
                                    });
                                    handleSelectWorspace(el.id);
                                  }}
                                  className={"delete_contact"}
                                >
                                  <span>Delete</span>
                                </button>
                              </div>
                              {deleteIsOpen && (
                                <DeletePopUp handleClose={toggleDeletePopup} />
                              )}
                            </OutsideClickHandler>
                          ) : null}
                          <svg
                            style={el.popup ? { pointerEvents: "none" } : null}
                            onClick={() => {
                              setContactsData((prevState) => [
                                ...prevState.slice(0, i),
                                { ...el, popup: true },
                                ...prevState.slice(i + 1),
                              ]);
                            }}
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z'
                              stroke='#CBD5E1'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z'
                              stroke='#CBD5E1'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z'
                              stroke='#CBD5E1'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </div>
                    ))
                  ) : (
                    <span className={"no_items_style"}>No items</span>
                  )
                ) : (
                  <Loader
                    color={{
                      "--bgColor": "var(--gitgo-blue)",
                      "--size": "40px",
                      "--wrapper_size": "40px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <PaginationWrapper
            perPage={perPage}
            setPerPage={(e) => setPerPage(e)}
            activePage={activePage}
            loading={loading}
            setActivePage={(e) => setActivePage(e)}
          />
        </div>
      </div>
    </div>,
    activeModal.value ? (
      <ModalWindow
        key={1}
        nestedObj
        setActiveModal={(val) => setActiveModal(val)}
        deleteModal
      >
        <div className={"delete_modal"}>
          <span>Delete</span>
          <h3>{activeModal.data}</h3>
          <p>Are you sure you want to permanently delete this Contact?</p>
          <div className={"modal_window_buttons"}>
            <MainButton
              type={"button"}
              className={"cancel_button"}
              text={"Cancel"}
              onClick={() => {
                setActiveModal(modalState);
                bodyOverflow(false);
              }}
            />
            <MainButton
              type={"button"}
              className={"delete_button"}
              text={"Delete"}
              onClick={() => handleDeleteContact()}
            />
          </div>
        </div>
      </ModalWindow>
    ) : null,
    assignIsOpen.value ? (
      <AssignPopUp
        assignData={assignIsOpen}
        key={12}
        assignPopupData={assignPopupData}
        setContactsData={(e) => setContactsData(e)}
        setAssignPopupData={(e) => setAssignPopupData(e)}
        changeContinuePopup={changeContinuePopup}
        setAssignIsOpen={(e) => setAssignIsOpen(e)}
      />
    ) : null,
  ];
};

export default Contact;
