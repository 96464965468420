import React from 'react';
import Pagination from 'react-js-pagination'

const PaginationView = ({activePage, onChange, pageRangeDisplayed}) => {
    return (
        <Pagination
            activePage={activePage.page}
            itemsCountPerPage={1}
            itemClass={'pagination_list_buttons'}
            activeClass={'pagination_list_buttons_active'}
            disabledClass={'pagination_list_buttons_disabled'}
            prevPageText={activePage.page >= 3
                ? <button>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.3545 7.33329H5.20376L8.94759 3.60663L7.99656 2.66663L2.63867 7.99996L7.99656 13.3333L8.94089 12.3933L5.20376 8.66663H13.3545V7.33329Z"
                            fill="white"/>
                    </svg>
                </button> : null}
            nextPageText={(activePage.pages - activePage.page) >= 2
                ? <button>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.99656 2.66663L7.05223 3.60663L10.7894 7.33329H2.63867V8.66663H10.7894L7.05223 12.3933L7.99656 13.3333L13.3545 7.99996L7.99656 2.66663Z"
                            fill="white"/>
                    </svg>
                </button> : null}
            firstPageText={activePage.page >= 3 && activePage.pages > 3 ? '1' : null}
            lastPageText={activePage.page <= activePage.pages - 2 && activePage.pages > 3 ? activePage.pages.toString() : null}
            hideFirstLastPages={!(activePage.page >= 3 && activePage.pages > 3) || !(activePage.page <= activePage.pages - 2 && activePage.pages > 3)}
            hideNavigation={!((activePage.pages - activePage.page) >= 2) || !(activePage.page >= 3)}
            totalItemsCount={activePage.pages}
            pageRangeDisplayed={pageRangeDisplayed ? pageRangeDisplayed : 5}
            hideDisabled={true}
            onChange={onChange}/>
    )
}

export default PaginationView
