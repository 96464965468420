import React, { useEffect, useState } from "react";
import "../Charts/dashboard_funnel.css";
import "zingchart/es6";
import second from "../../assets/Dashboard/funnel-blocks/Subtract02.svg";
import third from "../../assets/Dashboard/funnel-blocks/Subtract03.svg";
import fourth from "../../assets/Dashboard/funnel-blocks/Subtract04.svg";
import fifth from "../../assets/Dashboard/funnel-blocks/Subtract05.svg";
import sixth from "../../assets/Dashboard/funnel-blocks/Subtract06.svg";
import API from "../../api/API";
import { handleCurrentToken } from "../../Constants/handleCurrentToken";

export default function DashboardFunnel() {
  const [data, setData] = useState({
    statuses: {
      values: "",
      percents: "",
      text: "",
    },
  });

  useEffect(() => {
    API(handleCurrentToken())
      .get(`/dashboard/graphs`)
      .then((res) => {
        console.log("res: ", res);
        let newSeries = Object.entries(res.data.opportunities.statuses)
          .map((i, index) => {
            let data = Object.values(i[1])[0];
            return {
              values: data.total,
              percents: data.percents,
              text: Object.keys(i[1])[0],
            };
          })
          .sort((a, b) => b.values[0] - a.values[0]);
        console.log(newSeries);
        setData((prevState) => ({
          ...prevState,
          statuses: newSeries,
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(data);

  // const addWord = (string) => {
  //     let arr = string.split(' ', 3);
  //     console.log(string)
  //     let str =  arr.map(el => '\n'+ el ).join()
  //     console.log(str.replace(',', ' '))
  //     return str.replace(',', ' ');
  // }
  return (
    <div className='blocks-section'>
      <div className='funnel-block'>
        {/*{data.statuses.map((item, index) => (*/}
        {/*    <div key={index} className="block">*/}
        {/*    <span>*/}
        {/*        {item.text}*/}
        {/*    </span>*/}
        {/*        <img src={sixth} alt="first"/>*/}
        {/*        <span>{item.percents}</span>*/}
        {/*    </div>*/}
        {/*))*/}

        {/*}*/}
        <button
          className={"funnel_chart"}
          onClick={() =>
            window.open(
              `/admin/opportunities?status=${data.statuses[0]?.text
                .split(" ")
                .join("_")}`,
              "_blank"
            )
          }
        >
          <div className='block'>
            <span className='name-of-category'>{data.statuses[0]?.text}</span>
            <div className='center-funnel'>
              <span className='value-count'>{data.statuses[0]?.values}</span>
              <img src={sixth} alt='first' />
              <div className='tooltip-funnel'>
                <div className='modal-tool'>
                  <div className='header-text-tooltip'>
                    <span className='title-modal-text'>
                      Travel Opportunities Funnel
                    </span>
                    <span className='title-modal-value'>
                      {data.statuses[0]?.text}
                    </span>
                    <div className='border-modal' />
                  </div>
                  <div className='footer-tooltip-counter'>
                    <span className='title-count-value'>
                      {data.statuses[0]?.values}
                    </span>
                    <div className='right-count-side'>
                      <span className='record-count'>Record count</span>
                      <span className='record-count'>
                        {data.statuses[0]?.percents}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className='name-of-category'>
              {data.statuses[0]?.percents}%
            </span>
          </div>
        </button>
        <button
          className={"funnel_chart"}
          onClick={() =>
            window.open(
              `/admin/opportunities?status=${data.statuses[1]?.text
                .split(" ")
                .join("_")}`,
              "_blank"
            )
          }
        >
          <div className='block'>
            <span className='name-of-category'>{data.statuses[1]?.text}</span>
            <div className='center-funnel'>
              <span className='value-count'>{data.statuses[1]?.values}</span>
              <img src={fifth} alt='first' />
              <div className='tooltip-funnel'>
                <div className='modal-tool'>
                  <div className='header-text-tooltip'>
                    <span className='title-modal-text'>
                      Travel Opportunities Funnel
                    </span>
                    <span className='title-modal-value'>
                      {data.statuses[1]?.text}
                    </span>
                    <div className='border-modal' />
                  </div>
                  <div className='footer-tooltip-counter'>
                    <span className='title-count-value'>
                      {data.statuses[1]?.values}
                    </span>
                    <div className='right-count-side'>
                      <span className='record-count'>Record count</span>
                      <span className='record-count'>
                        {data.statuses[1]?.percents}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className='name-of-category'>
              {data.statuses[1]?.percents}%
            </span>
          </div>
        </button>
        <button
          className={"funnel_chart"}
          onClick={() =>
            window.open(
              `/admin/opportunities?status=${data.statuses[2]?.text
                .split(" ")
                .join("_")}`,
              "_blank"
            )
          }
        >
          <div className='block'>
            <span className='name-of-category'>{data.statuses[2]?.text}</span>
            <div className='center-funnel'>
              <span className='value-count'>{data.statuses[2]?.values}</span>
              <img src={fourth} alt='first' />
              <div className='tooltip-funnel'>
                <div className='modal-tool'>
                  <div className='header-text-tooltip'>
                    <span className='title-modal-text'>
                      Travel Opportunities Funnel
                    </span>
                    <span className='title-modal-value'>
                      {data.statuses[2]?.text}
                    </span>
                    <div className='border-modal' />
                  </div>
                  <div className='footer-tooltip-counter'>
                    <span className='title-count-value'>
                      {data.statuses[2]?.values}
                    </span>
                    <div className='right-count-side'>
                      <span className='record-count'>Record count</span>
                      <span className='record-count'>
                        {data.statuses[2]?.percents}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className='name-of-category'>
              {data.statuses[2]?.percents}%
            </span>
          </div>
        </button>
        <button
          className={"funnel_chart"}
          onClick={() =>
            window.open(
              `/admin/opportunities?status=${data.statuses[3]?.text
                .split(" ")
                .join("_")}`,
              "_blank"
            )
          }
        >
          <div className='block'>
            <span className='name-of-category'>{data.statuses[3]?.text}</span>
            <div className='center-funnel'>
              <span className='value-count'>{data.statuses[3]?.values}</span>
              <img src={third} alt='first' />
              <div className='tooltip-funnel'>
                <div className='modal-tool'>
                  <div className='header-text-tooltip'>
                    <span className='title-modal-text'>
                      Travel Opportunities Funnel
                    </span>
                    <span className='title-modal-value'>
                      {data.statuses[3]?.text}
                    </span>
                    <div className='border-modal' />
                  </div>
                  <div className='footer-tooltip-counter'>
                    <span className='title-count-value'>
                      {data.statuses[3]?.values}
                    </span>
                    <div className='right-count-side'>
                      <span className='record-count'>Record count</span>
                      <span className='record-count'>
                        {data.statuses[3]?.percents}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className='name-of-category'>
              {data.statuses[3]?.percents}%
            </span>
          </div>
        </button>
        <button
          className={"funnel_chart"}
          onClick={() =>
            window.open(
              `/admin/opportunities?status=${data.statuses[4]?.text
                .split(" ")
                .join("_")}`,
              "_blank"
            )
          }
        >
          <div className='block'>
            <span className='name-of-category'>{data.statuses[4]?.text}</span>
            <div className='center-funnel'>
              <span className='value-count'>{data.statuses[4]?.values}</span>
              <img src={second} alt='first' />
              <div className='tooltip-funnel'>
                <div className='modal-tool'>
                  <div className='header-text-tooltip'>
                    <span className='title-modal-text'>
                      Travel Opportunities Funnel
                    </span>
                    <span className='title-modal-value'>
                      {data.statuses[4]?.text}
                    </span>
                    <div className='border-modal' />
                  </div>
                  <div className='footer-tooltip-counter'>
                    <span className='title-count-value'>
                      {data.statuses[4]?.values}
                    </span>
                    <div className='right-count-side'>
                      <span className='record-count'>Record count</span>
                      <span className='record-count'>
                        {data.statuses[4]?.percents}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className='name-of-category'>
              {data.statuses[4]?.percents}%
            </span>
          </div>
        </button>
      </div>

      {/*{data.totalValue > 0 ?*/}
      {/*    <ZingChart*/}
      {/*        id={"my_Chart"}*/}
      {/*        data={data}*/}
      {/*        ha*/}
      {/*        height={350}*/}
      {/*        width={440}*/}
      {/*    />*/}
      {/*    : <div className={'dashboard-graphs-line-first'}>*/}
      {/*        <div className={"no-activity-title-graphs-first"}>*/}
      {/*            <span>There have been no opportunities yet</span>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*}*/}
    </div>
  );
}
