import React from "react";
import './styles/select_option.css';

export default function TargetingSelectOption(props) {

    const {title, options, setData, value, label} = props;

    return (
        <div className={'select_options_wrap'}>
            <span className={'title'}>{title}</span>
            <div className={'content'}>
                {options.map((el, i) => (
                    <div className={'item'} key={i} onClick={() => {
                        setData(prevState => ({
                            ...prevState,
                            [label]: {
                                ...prevState[label],
                                value: el,
                            }
                        }))
                    }}>
                        <div className={`circle ${value === el ? 'active_circle' : ''}`}/>
                        <p>{el}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}