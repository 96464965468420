export default class Keys {
    static JWT_TOKEN = "GitGoJwtToken";
    static SUPER_ADMIN_JWT_TOKEN = "SuperAdminJwtToken";
    static USER_DATA = "GitGoData";
    static PROSPECT_DATA = "ProspectData";
    static EDIT_PROSPECT = "EditProspect";
    // static JWT_TOKEN_REGISTRATION = "ArtstedJwtTokenRegistration";
    // static REGISTRATION_DATA = "ArtstedRegData";
    // static JWT_TOKEN_ADMIN = "ArtstedAdminJwtToken";
    // static RECENT_VIEW = "ArtstedUserRecentList";
    // static VERIFIED_POPUP = "ArtstedVerifiedPopup";
    // static LANDING_ROLE = "ArtstedRole";
    // static SUBSCRIPTION_DATA = "subscriptionData";
    // static FOOTER_CATEGORY = "footerCategory";
    // static SUBSCRIPTION_PLAN_BILL = "subscriptionPlanBill";
    // static RESET_PASSWORD_CODE = "resetPasswordCode";
}
