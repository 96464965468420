import React, { useCallback, useEffect, useState } from "react";
import { Route, Link, useLocation, useHistory } from "react-router-dom";
import { Navigation } from "../routers/navigation";
import support from "../assets/img/Prospects/Prospects/help.svg";
import useResize from "../Constants/useResize";
import { useSelector } from "react-redux";
import { forceLogout } from "../api/API";
import Header from "../Components/Header/Header";
import API from "../api/API";
import regExp from "../Constants/regExp";
import { handleCurrentToken } from "../Constants/handleCurrentToken";
import SupportModal from "../Components/SupportModal";
import useBottomPopup from "../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";

const supportFields = {
  name: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Name",
    max_length: 30,
  },
  email: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Email",
    max_length: 30,
  },
  message: {
    value: "",
    error: false,
    errorMessage: "Fill this field",
    title: "Message",
    max_length: 200,
  },
  file: {
    path: "",
  },
};

const SideBarRoutes = React.memo(() => {
  let location = useLocation();
  const history = useHistory();
  const store = useSelector((state) => state.auth.user_data);
  const { innerWidth } = useResize();
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();

  const [supportData, setSupportData] = useState(() => supportFields);
  const [supportPop, setSupportPop] = useState(false);
  const [activeNavigationPage, setActiveNavigationPage] = useState(
    location.pathname.split("/")[2]
  );

  useEffect(() => {
    if (
      !Navigation.mainNavigation.some((el) => {
        console.log(el.pathname, location.pathname.split("/")[2]);
        return el.pathname === location.pathname.split("/")[2];
      })
    ) {
      console.log("wtf");
      history.push("/404");
    }
  }, [location.pathname, history, store.role]);

  const supportHelp = (e) => {
    e.preventDefault();
    const { name, email, message, file } = supportData;
    const fillOut = name.value && email.value && message.value;
    let formData = new FormData();

    if (fillOut) {
      if (regExp.email.test(email.value)) {
        formData.append("name", name.value);
        formData.append("email", email.value);
        formData.append("message", message.value);

        if (file?.path?.toString() === "[object File]") {
          formData.append("file", supportData.file.path);
        }
        API(handleCurrentToken())
          .post(`/support`, formData)
          .then((res) => {
            setSupportPop(false);
            changeContinuePopup(
              "Your message has been sent. We will review it and respond soon"
            );
            setSupportData(supportFields);
          });
      } else {
        setSupportData((prevState) => ({
          ...prevState,
          email: {
            ...prevState.email,
            error: true,
            errorMessage: "Wrong format",
          },
        }));
      }
    } else {
      Object.entries(supportData).forEach((el) => {
        if (el[0] !== "file") {
          if (!el[1].value) {
            setSupportData((prevState) => ({
              ...prevState,
              [el[0]]: {
                ...prevState[el[0]],
                error: true,
                errorMessage: "Fill this label",
              },
            }));
          }
        }
      });
    }
  };

  const setFileContent = (e) => {
    if (e.target.files && e.target.files[0]) {
      let photo = e.target.files[0];
      // console.log(photo)
      setSupportData((prevState) => ({
        ...prevState,
        file: {
          path: photo,
        },
      }));
    }
  };

  const OldSchoolMenuLink = useCallback(
    ({ user, label, pathname, path, icon }) => {
      return user.includes(store.role) && label ? (
        label === "Refuel Your Contacts" || label === "Services" ? (
          <a href={path}>
            {icon}
            <span>{label}</span>
          </a>
        ) : (
          <Link
            key={pathname}
            className={
              activeNavigationPage === pathname ||
              activeNavigationPage.includes(pathname)
                ? "admin_bar_active_link"
                : null
            }
            onClick={() => {
              setActiveNavigationPage(pathname);
              window.scrollTo(0, 0);
            }}
            to={path}
          >
            {icon}
            <span>{label}</span>
          </Link>
        )
      ) : null;
    },
    [activeNavigationPage, store.role]
  );

  function SwitcherNavBar({ routes }) {
    return routes.map((route, index) => (
      <Route
        path={route.path}
        exact={true}
        component={route.main}
        key={index}
      />
    ));
  }

  const renderSwitchRoute = React.useCallback(
    () => (
      <div key={1} className={"wrapper_admin_panel_content"}>
        <SwitcherNavBar routes={Navigation.mainNavigation} />
      </div>
    ),
    []
  );

  return (
    <Route>
      <ChangedConfirmedPopup
        successText={successText}
        key={0}
        changingConfirmed={changingConfirmed}
      />
      ,
      <Header />
      {innerWidth > 1100 ? (
        [
          <div key={0} className={"relative_wrapper"}>
            <div className={"wrapper_admin_panel_navigation"}>
              <div className={"wrapper_admin_panel_navigation_content"}>
                {Navigation.mainNavigation.map((item, index) => (
                  <OldSchoolMenuLink {...item} key={index} />
                ))}
              </div>
              <div
                onClick={() =>
                  store.role === "gitgo_admin_main" ? forceLogout() : null
                }
                className={"wrapper_admin_panel_log_out"}
              >
                {store.role === "gitgo_admin_main" ? (
                  <>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0)'>
                        <path
                          d='M4 12L14 12'
                          stroke='var(--gitgo-blue)'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <g filter='url(#filter0_d)'>
                          <path
                            d='M4 12L8 8'
                            stroke='var(--gitgo-blue)'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </g>
                        <path
                          d='M4 12L8 16'
                          stroke='var(--gitgo-blue)'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M20 20L20 4'
                          stroke='var(--gitgo-blue)'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                      <defs>
                        <filter
                          id='filter0_d'
                          x='-1'
                          y='7'
                          width='14'
                          height='14'
                          filterUnits='userSpaceOnUse'
                          colorInterpolationFilters='sRGB'
                        >
                          <feFlood
                            floodOpacity='0'
                            result='BackgroundImageFix'
                          />
                          <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                            result='hardAlpha'
                          />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
                          />
                          <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow'
                            result='shape'
                          />
                        </filter>
                        <clipPath id='clip0'>
                          <rect
                            width='24'
                            height='24'
                            fill='white'
                            transform='translate(24 24) rotate(-180)'
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Log out</span>
                  </>
                ) : (
                  <>
                    <img src={support} alt='support_logo' />
                    <span onClick={() => setSupportPop(true)}>Support</span>
                  </>
                )}
              </div>
            </div>
            {supportPop ? (
              <SupportModal
                supportHelp={supportHelp}
                setFileContent={setFileContent}
                setSupportData={(e) => setSupportData(e)}
                setSupportPop={(e) => setSupportPop(e)}
                supportData={supportData}
              />
            ) : null}
          </div>,
          renderSwitchRoute(),
        ]
      ) : (
        <div className={"wrapper_admin_panel_content_responsive"}>
          <div className={"admin_panel_content_responsive"}>
            <span>
              The GitGo Outreach App is not available at this resolution.
            </span>
            <span>Please make the browser larger to view the content</span>
          </div>
        </div>
      )}
    </Route>
  );
});

export default SideBarRoutes;
