import React, {useEffect, useState} from "react";
import './styles/login_signup.css'
import {useHistory, useLocation} from "react-router-dom";
import regExp from "../Constants/regExp";
import API from "../api/API";
import Keys from "../Constants/helper";
import {setUserData} from "../main-store/auth/actions";
import {useDispatch} from "react-redux";
import LoginWrapper from "../Components/LoginWrapper";

export default function LoginSignup() {
    const dispatch = useDispatch();
    const history = useHistory();
    let location = useLocation();

    const [loginStep, setLoginStep] = useState('login');
    const [loginError, setLoginError] = useState(null);
    const [token, setToken] = useState(null);

    const [loginData, setLoginData] = useState({
        email: {
            value: '',
            error: false,
            errorMessage: '',
        },
        password: {
            value: '',
            error: false,
            errorMessage: '',
        },
        repeat_password: {
            value: '',
            error: false,
            errorMessage: '',
        },
    });

    useEffect(() => {
        let codeUrl = location.pathname.split('/').pop();
        if (codeUrl.includes('jwt')) {
            setLoginStep('setup')
            let token = codeUrl.split('=').pop();
            API(token).get(`/token_verification`)
                .then(res => {
                    if (res.status === 200) {
                        setToken(token)
                    }
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        history.push('/404')
                    }
                })
        }
    }, [location.pathname, history])

    useEffect(() => {
        if (localStorage.getItem(Keys.JWT_TOKEN) && loginStep === 'login') {
            history.push('/admin/dashboard')
        }
    }, [loginStep, history])

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        const {email, password, repeat_password} = loginData;

        const loginCondition = email.value && regExp.email.test(email.value) && password.value
        const setupCondition = password.value && repeat_password.value
        if (loginStep === 'login') {
            if (loginCondition) {
                formData.append('email', email.value)
                formData.append('password', password.value)
                API().post(`/login`, formData)
                    .then(res => {
                        localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
                        dispatch(setUserData({
                            company_name: res.data.user.company_name,
                            role: res.data.user.role,
                            first_name: res.data.user.first_name ?? 'Admin',
                            last_name: res.data.user.last_name ?? 'account',
                        }));
                        history.push('/admin/dashboard')
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response.data.error.toLowerCase() === "cant find user") {
                            setLoginError('User not found')
                        } else if (err.response.data.error.toLowerCase() === "invalid password") {
                            setLoginError('Wrong password')
                        }
                    })
            } else {
                Object.entries(loginData).forEach(el => {
                    if (!el[1].value) {
                        setLoginData(state => ({
                            ...state,
                            [el[0]]: {
                                ...state[el[0]],
                                error: true,
                                errorMessage: 'Fill this label',
                            },
                        }))
                    }
                })
                if (!regExp.email.test(email.value)) {
                    setLoginData(state => ({
                        ...state,
                        email: {
                            ...state.email,
                            error: true,
                            errorMessage: 'Wrong email format',
                        },
                    }))
                }
            }
        } else {
            if (setupCondition) {
                if (password.value === repeat_password.value) {
                    formData.append('password', password.value)
                    API(token).post(`/setup_password`, formData)
                        .then(res => {
                            localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
                            history.push('/create_account')
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                history.push('/404')
                            }
                        })
                } else {
                    setLoginData(state => ({
                        ...state,
                        password: {
                            ...state.password,
                            error: true,
                            errorMessage: 'Passwords should match',
                        },
                        repeat_password: {
                            ...state.password,
                            error: true,
                            errorMessage: 'Passwords should match',
                        }
                    }))
                }
            } else {
                Object.entries(loginData).forEach(el => {
                    if (!el[1].value) {
                        setLoginData(state => ({
                            ...state,
                            [el[0]]: {
                                ...state[el[0]],
                                error: true,
                                errorMessage: 'Fill this label',
                            },
                        }))
                    }
                })
            }
        }

    }

    return <LoginWrapper setLoginData={e => setLoginData(e)}
                         loginStep={loginStep}
                         loginError={loginError}
                         setLoginError={e => setLoginError(e)}
                         handleLoginSubmit={handleLoginSubmit}
                         loginData={loginData}/>
}