import React from "react";

import './styles/modal_window.css'
import OutsideClickHandler from "../Constants/OutsideClickHandler";
import {bodyOverflow} from "../Constants/bodyOverflow";

export default function ModalWindow({setActiveModal, setNullData, children, nestedObj, deleteModal, setAddData}) {

    return (
        <div className={'modal_window_bg'}>
            <OutsideClickHandler callback={() => {
                if (setAddData) {
                    setAddData([])
                }
                if (nestedObj) {
                    setActiveModal(prevState => ({
                        ...prevState,
                        value: null,
                    }))
                } else {
                    setActiveModal(null)
                }
                if (setNullData) {
                    setNullData(null)
                }
                bodyOverflow(false)
            }}>
                <div className={`modal_window_content ${deleteModal ? 'delete_modal_window' : ''}`}>
                    {children}
                </div>
            </OutsideClickHandler>
        </div>
    )
}