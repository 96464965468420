import React, {useState} from "react";
import './styles/forgot_password.css';
import loginLogo from "../assets/img/gitGo_login_logo.png";
import TextInput from "../Components/CustomComponents/TextInput";
import regExp from "../Constants/regExp";
import API from "../api/API";
import {Link} from "react-router-dom";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";
import useBottomPopup from "../Constants/useBottomPopup";

export default function ForgotPassword() {
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();

    const [email, setEmail] = useState({
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    });
    const [disabled, setDisabled] = useState(false);

    const [step, setStep] = useState('forgot')

    const emailError = (error) => {
        setEmail(prevState => ({
            ...prevState,
            error: true,
            errorMessage: error,
        }))
    }

    const handleEmail = (e) => {
        e.preventDefault();
        if (email.value) {
            if (regExp.email.test(email.value)) {
                API().post(`/forgot_password/email`, {
                    email: email.value
                })
                    .then(res => {
                        if (res.status === 200) {
                            setStep('success')
                            changeContinuePopup('Email send')
                            setTimeout(() => {
                                changeContinuePopup('Email send')
                            }, 2000)
                        }
                    })
                    .catch(() => {
                        emailError('Wrong email')
                    })
            } else {
                emailError('Wrong email format')
            }
        } else {
            emailError('Fill this label')
        }
    }

    const resendEmail = () => {
        API().post(`/forgot_password/email`, {
            email: email.value
        })
            .then(res => {
                if (res.status === 200) {
                    setStep('success')
                    setDisabled(true)
                    changeContinuePopup('Email resent')
                    setTimeout(() => {
                        changeContinuePopup('Email resent')
                    }, 2000)
                    setTimeout(() => {
                        setDisabled(false)
                        changeContinuePopup('You can resent email again')
                    }, 30000)
                }
            })
    }

    return [
        <ChangedConfirmedPopup successText={successText}
                               key={0}
                               changingConfirmed={changingConfirmed}/>,
        <div key={1} className={'forgot_password_wrapper'}>
            <div className={'content'}>
                <div className={'card'}>
                    <div className={'head'}>
                        <img src={loginLogo} alt="logo"/>
                    </div>
                    {step === 'forgot' ?
                        <div className={'body'}>
                            <h4>Forgot Password</h4>
                            <p>Please confirm your email to set up a new password</p>
                            <form onSubmit={(e) => handleEmail(e)}>
                                <TextInput value={email.value}
                                           type={'text'}
                                           title={'Email'}
                                           error={email.error}
                                           loginInput
                                           errTitle={email.errorMessage}
                                           onChange={({target}) => {
                                               setEmail(prevState => ({
                                                   ...prevState.email,
                                                   value: target.value,
                                                   error: false,
                                               }))
                                           }}/>
                                <button type={'submit'}>Confirm</button>
                            </form>
                        </div>
                        : <div className={'body body_success'}>
                            <h3>Check your email for verification link</h3>
                            <button disabled={disabled} onClick={() => resendEmail()}>Resend email</button>
                        </div>}
                </div>
                {step === 'forgot' ?
                    <Link to={'/'}>Return to Login page</Link> : null}
            </div>
        </div>
    ]
}