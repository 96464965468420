import React, { useState } from "react";
import './styles/success_popup.css';
import AddEmailsWrapper from "../AddEmailsWrapper";
import { handleCurrentToken } from "../../Constants/handleCurrentToken";
import API from "../../api/API";

export default function TargetingSuccessPopup(props) {

    const {
        firstData, fifthData, setTargetingSubmitted,
        fourthData, secondData, seventhData, targetingSubmitted,
        sixthData, repeatData, thirdData, className,
        setSuccessPopup, changeContinuePopup,
        postDataArray
    } = props;


    const [addData, setAddData] = useState([]);
    const [membersError, setMembersError] = useState(null);

    const renderLayout = (title, text) => {
        if (text) {
            return (
                <div key={title} className={'item'}>
                    <h5>{title}</h5>
                    <p>{text}</p>
                </div>
            )
        } else {
            return null
        }
    }

    const renderNestedLayout = (obj, number) => (
        <div className={'render_content'}>
            {Object.entries(obj[number]).map(el => {
                return renderLayout(el[1].renderTitle ?? el[0], el[1].dataArray.length ? el[1].dataArray.join('; ') : '―――')
            })}
        </div>
    )

    const objectMap = (obj) => (
        <div className={'render_content'}>
            {Object.entries(obj).map(el => {
                if (el[1].value !== 'yes' && el[1].value !== 'no' && el[1].required) {
                    if ('dataArray' in el[1]) {
                        return (
                            renderLayout(el[1].renderTitle ?? el[0], el[1].dataArray.join('; '))
                        )
                    } else {
                        return (
                            renderLayout(el[1].renderTitle ?? el[0], el[1].value)
                        )
                    }
                } else return null
            })}
        </div>
    )

    const sendToEmails = () => {
        if (addData.length) {
            API(handleCurrentToken()).post(`/targeting/send`, {
                emails: addData,
                data: postDataArray
            })
                .then(res => {
                    if (res.status === 200) {
                        changeContinuePopup('Successfully send')
                        setSuccessPopup(false);
                        setTargetingSubmitted(prevState => ({
                            ...prevState,
                            targeting_submitted: true,
                            data: postDataArray,
                        }))
                        setTimeout(() => {
                            changeContinuePopup('Successfully send')
                        }, 2000)
                    }
                })
        } else {
            setMembersError('Should be at least one email')
        }
    }

    const exportButton = (className) => (
        <button className={`export ${className ? className : ''}`} onClick={() => {
            window.open(process.env.REACT_APP_API_URL + `/targeting/export?jwt=${handleCurrentToken()}`)
        }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17"
                    stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 11L12 16L17 11" stroke="white" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round" />
                <path d="M12 4V16" stroke="white" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>
            <span>Export</span>
        </button>
    )

    return (
        <div className={`success_popup_wrapper ${className ? className : ''}`}>
            <div className={'success_content overflow_scrollbar'}>
                {
                    targetingSubmitted && targetingSubmitted.data && targetingSubmitted.targeting_submitted
                        ? <div style={{ border: 'none' }} className={'render_content'}>
                            {targetingSubmitted.data.map((el) => (
                                Object.entries(el).map((item, i) => {
                                    if (item[0] === 'line') {
                                        return (
                                            <div key={i} className={'data_line'} />
                                        )
                                    } else {
                                        return (
                                            <div key={i}>
                                                <h5>{item[0]}</h5>
                                                <p>{item[1]}</p>
                                            </div>
                                        )
                                    }
                                })
                            ))}
                        </div>
                        : <>
                            <h2>Targeting</h2>
                            <div style={{ paddingTop: '0' }} className={'render_content'}>
                                {Object.entries(firstData).map(el => (
                                    renderLayout(el[0], el[1]?.value)
                                ))}
                            </div>
                            <div className={'render_content'}>
                                {Object.entries(secondData).map(el => (
                                    renderLayout(el[0], el[1]?.value)
                                ))}
                            </div>
                            {/* {objectMap(secondData)} */}

                            {/* {secondData["Would you like to select subindustries for your backyard?"].value === 'yes' ?
                                renderNestedLayout(repeatData, '1') : null} */}

                            {objectMap(thirdData)}

                            {thirdData["Would you like to select subindustries for your drive market?"].value === 'yes' ?
                                renderNestedLayout(repeatData, '1') : null}

                            {objectMap(fourthData)}

                            {/* {
                            renderNestedLayout(repeatData, '1')} */}
                            {objectMap(fourthData)}
                            {objectMap(fifthData)}
                            {objectMap(sixthData)}
                            <div className={'render_content'}>
                                {Object.entries(seventhData).map(el => (
                                    renderLayout(el[0], el[1]?.value)
                                ))}
                            </div>
                            {/* {customRenderLayout(seventhData)} */}
                            {/* {objectMap(fifthData)} */}
                            {/* 
                        {fifthData["Would you like to select subindustries for your 2nd feeder market?"].value === 'yes' ?
                            renderNestedLayout(repeatData, '3') : null}

                        {sixthData["Is there a feeder market you wish to add that was not on the list?"].value === 'yes'
                            ? objectMap(sixthData) : null}
                        {objectMap(seventhData)} */}

                            {/* {seventhData["Is there a feeder market you wish to add that was not on the list?"].value === 'yes' ?
                            renderNestedLayout(repeatData, '4') : null} */}
                        </>
                }

                {targetingSubmitted.targeting_submitted
                    ? exportButton('submitted_export')
                    : <div className={'send_wrapper'}>
                        <p style={{ marginBottom: 16, fontSize: 14, fontWeight: '500' }}>GitGo has received your response. If you would like to receive a copy of your submission, enter your email below</p>
                        <p style={{
                            marginBottom: 16,
                            fontSize: 16,
                            fontWeight: 600
                        }}>Send to</p>
                        <AddEmailsWrapper title={'Emails'}
                            error={membersError}
                            contactsSearch
                            className={'targeting_add_email_wrapper'}
                            setMembersError={e => setMembersError(e)}
                            data={addData}
                            svg={<svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.06071 8.00032L12.0295 5.03157C12.1704 4.89093 12.2496 4.70007 12.2498 4.50099C12.25 4.30191 12.171 4.11091 12.0304 3.97001C11.8897 3.82912 11.6989 3.74986 11.4998 3.74969C11.3007 3.74951 11.1097 3.82843 10.9688 3.96907L8.00008 6.93782L5.03133 3.96907C4.89043 3.82818 4.69934 3.74902 4.50008 3.74902C4.30082 3.74902 4.10973 3.82818 3.96883 3.96907C3.82793 4.10997 3.74878 4.30107 3.74878 4.50032C3.74878 4.69958 3.82793 4.89068 3.96883 5.03157L6.93758 8.00032L3.96883 10.9691C3.82793 11.11 3.74878 11.3011 3.74878 11.5003C3.74878 11.6996 3.82793 11.8907 3.96883 12.0316C4.10973 12.1725 4.30082 12.2516 4.50008 12.2516C4.69934 12.2516 4.89043 12.1725 5.03133 12.0316L8.00008 9.06282L10.9688 12.0316C11.1097 12.1725 11.3008 12.2516 11.5001 12.2516C11.6993 12.2516 11.8904 12.1725 12.0313 12.0316C12.1722 11.8907 12.2514 11.6996 12.2514 11.5003C12.2514 11.3011 12.1722 11.11 12.0313 10.9691L9.06071 8.00032Z"
                                    fill="#475569" />
                            </svg>}
                            setData={e => setAddData(e)} />
                        {membersError ? <span className={'error_message'}>{membersError}</span> : null}
                        <div className={'buttons_wrapper'}>
                            <button onClick={() => setSuccessPopup(false)} className={'cancel'}>Cancel</button>
                            <div className={'inline'}>
                                <button onClick={() => sendToEmails()}>Send</button>
                                {exportButton()}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}