import React, { useEffect, useState } from "react";
import "./styles/opportunities.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";
import useBottomPopup from "../Constants/useBottomPopup";
import { handleCurrentToken } from "../Constants/handleCurrentToken";
import { setProspectData } from "../main-store/auth/actions";
import SortingHeader from "../Components/SortingHeader";
import MainButton from "../Components/CustomComponents/MainButton";
import { bodyOverflow } from "../Constants/bodyOverflow";
// import { useGetLastChartHash } from "../Constants/useGetLastChartHash";
import ModalWindow from "../Components/ModalWindow";
import API from "../api/API";
import { Loader } from "../Components/Loader";
import PaginationWrapper from "../Components/PaginationWrapper";
import CustomSelect from "../Components/CustomSelect";

export default function Opportunities() {
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const history = useHistory();
  const dispatch = useDispatch();
  // const { activeHashUrl } = useGetLastChartHash();
  const store = useSelector((state) => state.auth.user_data);
  const [loading, setLoading] = useState(false);
  const [prospectsData, setProspectsData] = useState(null);
  const [activeModal, setActiveModal] = useState({
    value: false,
    count: null,
  });
  const [activeStatus, setActiveStatus] = useState({
    data: {
      value: history.location.search?.includes("status")
        ? history.location.search.split("=")[1].split("_").join(" ")
        : "All",
      options: [
        "New Opportunity",
        "Proposal",
        "Tentative",
        "Closed Won",
        "Lost",
        "All",
      ],
      popup: false,
    },
  });

  const [perPage, setPerPage] = useState({
    data: {
      value: "10",
      options: ["10", "50", "100"],
      popup: false,
    },
  });
  const [activePage, setActivePage] = useState({
    page: 1,
    pages: null,
    total: null,
  });

  const [orderBy, setOrderBy] = useState({
    value: { request: "name", render: "New Opportunity" },
    sorting: true,
    options: [
      { request: "name", render: "Opportunity Name" },
      { request: "status", render: "Travel Opportunity Status" },
      { request: "created", render: "Company Name" },
      { request: "primary_contact", render: "Primary Contact" },
      { request: "potential_revenue", render: "Potential Revenue" },
    ],
  });

  const handleDeleteProspects = () => {
    let checkedIdsArray = prospectsData
      .filter((el) => el.checked)
      .map((item) => item.id);
    let deleteData = checkedIdsArray
      .map((el, i) => `ids=${el}${i + 1 !== checkedIdsArray.length ? "&" : ""}`)
      .join("");
    if (checkedIdsArray.length) {
      API(handleCurrentToken())
        .delete(`/prospects?${deleteData}`)
        .then((res) => {
          if (res.status === 200) {
            changeContinuePopup("Prospects deleted");
            setTimeout(() => {
              changeContinuePopup("Prospects deleted");
            }, 2000);
            bodyOverflow(false);
            setActiveModal({
              value: false,
              count: null,
            });
            setProspectsData((prevState) =>
              prevState.filter((el) => !checkedIdsArray.includes(el.id))
            );
          }
        });
    }
  };

  useEffect(() => {
    if (store.role === "gitgo_admin_main") {
      history.push("/admin/opportunities");
    }
  }, [history, store.role]);

  useEffect(() => {
    if (activePage.page) {
      let activeValue = activeStatus.data.value.split(" ").join("_");
      history.push(`/admin/opportunities?status=${activeValue}`);
      API(handleCurrentToken())
        .get(
          `/opportunities?&order_by=${orderBy.value.request}&status_filter=${
            activeStatus.data.value
          }&sorting=${orderBy.sorting ? "asc" : "desc"}&page=${
            activePage.page
          }&per_page=${perPage.data.value}`
        )
        .then((res) => {
          console.log(res);
          let data = res.data.opportunities;
          data.forEach((el) => {
            el.popup = false;
            el.checked = false;
          });

          setProspectsData(data);
          setActivePage((prevState) => ({
            ...prevState,
            pages: res.data.pages,
            total: res.data.total,
          }));
          setLoading(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    orderBy,
    history,
    activeStatus.data.value,
    activePage.page,
    perPage.data.value,
  ]);
  console.log(prospectsData);
  return [
    <ChangedConfirmedPopup
      successText={successText}
      key={10}
      changingConfirmed={changingConfirmed}
    />,
    <div key={0} className={"prospects_wrapper"}>
      <div
        style={{
          display: "grid",
          gap: "10px",
          width: "100%",
          marginBottom: "16px",
          gridTemplateColumns: "320px 1fr",
          alignItems: "self-start",
        }}
      >
        <div className='status-filter'>
          <CustomSelect
            loading={true}
            options={activeStatus.data.options}
            className={"status_popup"}
            optionalLabel={"data"}
            width={250}
            popupPositionTop={"37px"}
            popupPositionLeft={"-10px"}
            popupPositionWidth={"265px"}
            svg={
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8 10L12 14L16 10H8Z'
                  fill='var(--gitgo-blue)'
                  stroke='var(--gitgo-blue)'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
            onChange={({ target }) => {
              setActiveStatus((prevState) => ({
                ...prevState,
                value: target.value,
              }));
            }}
            popup={activeStatus.data.popup}
            setData={(e) => {
              setActiveStatus(e);
            }}
            value={activeStatus.data.value}
          />
        </div>
        {loading && prospectsData.some((el) => el.checked) ? (
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "16px",
              justifySelf: "end",
            }}
          >
            <MainButton
              type={"button"}
              rightSvg={
                <svg
                  width='27'
                  height='27'
                  viewBox='0 0 27 27'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.11548 8.11572L7.1924 21.0388C7.1924 21.61 7.41932 22.1579 7.82325 22.5618C8.22717 22.9657 8.77501 23.1926 9.34625 23.1926H17.9616C18.5329 23.1926 19.0807 22.9657 19.4846 22.5618C19.8886 22.1579 20.1155 21.61 20.1155 21.0388L21.1924 8.11572'
                    fill='#FEEFEF'
                  />
                  <path
                    d='M6.11548 8.11572L7.1924 21.0388C7.1924 21.61 7.41932 22.1579 7.82325 22.5618C8.22717 22.9657 8.77501 23.1926 9.34625 23.1926H17.9616C18.5329 23.1926 19.0807 22.9657 19.4846 22.5618C19.8886 22.1579 20.1155 21.61 20.1155 21.0388L21.1924 8.11572'
                    stroke='#F26464'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M10.4231 8.1158V4.88503C10.4231 4.59941 10.5366 4.32549 10.7385 4.12353C10.9405 3.92157 11.2144 3.80811 11.5 3.80811H15.8077C16.0933 3.80811 16.3672 3.92157 16.5692 4.12353C16.7712 4.32549 16.8846 4.59941 16.8846 4.88503V8.1158'
                    fill='#FEEFEF'
                  />
                  <path
                    d='M10.4231 8.1158V4.88503C10.4231 4.59941 10.5366 4.32549 10.7385 4.12353C10.9405 3.92157 11.2144 3.80811 11.5 3.80811H15.8077C16.0933 3.80811 16.3672 3.92157 16.5692 4.12353C16.7712 4.32549 16.8846 4.59941 16.8846 4.88503V8.1158'
                    stroke='#F26464'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M5.03833 8.11572H22.2691'
                    stroke='#F26464'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M11.5 12.4233V18.8849'
                    stroke='#F26464'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M15.8076 12.4233V18.8849'
                    stroke='#F26464'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              }
              className={"delete_button_trash"}
              text={`Delete ${
                prospectsData.filter((el) => el.checked).length
              } Opportunit${
                prospectsData.filter((el) => el.checked).length > 1
                  ? "ies"
                  : "y"
              }`}
              onClick={() => {
                bodyOverflow(true);
                setActiveModal({
                  value: true,
                  count: prospectsData.filter((el) => el.checked).length,
                });
              }}
            />
          </div>
        ) : null}
      </div>
      <div className={"prospects_content"}>
        {loading ? (
          prospectsData.length ? (
            <div className={"prospects_container overflow_scrollbar"}>
              <div className={"head_opportunities"}>
                <SortingHeader
                  orderBy={orderBy}
                  setOrderBy={(val) => setOrderBy(val)}
                />
              </div>
              <div className={"container_body"}>
                {prospectsData.map((el, i) => (
                  <div key={el.id} className={"item_opportunities"}>
                    <div
                      onClick={() => {
                        setProspectsData((prevState) => [
                          ...prevState.slice(0, i),
                          { ...el, checked: !prevState[i].checked },
                          ...prevState.slice(i + 1),
                        ]);
                      }}
                      className={"choose_item_wrap"}
                    >
                      <div
                        className={`choose_item ${
                          el.checked ? "choose_item_active" : ""
                        }`}
                      >
                        <svg
                          width='8'
                          height='6'
                          viewBox='0 0 8 6'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1 3L3 5L7 1'
                            stroke='#478ECC'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    </div>
                    <p
                      onClick={() => {
                        dispatch(
                          setProspectData({
                            id: el.prospect.id,
                            name: el.name,
                          })
                        );
                        history.push(
                          `/admin/companies/${el.prospect.id}/${el.id}`
                        );
                      }}
                    >
                      {el.name}
                    </p>
                    <div className={"prospects-status-container w-max-c"}>
                      <span className={"status"}>{el.status}</span>
                    </div>
                    <span>
                      {el.prospect ? el.prospect.name : "Not specefied"}
                    </span>
                    <span>
                      {el.primary_contact
                        ? el.primary_contact.name
                        : "Not specefied"}
                    </span>
                    <span>
                      {el.potential_revenue
                        ? el.potential_revenue
                        : "Not specefied"}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <span className={"no_items_style"}>No items</span>
          )
        ) : (
          <Loader
            color={{
              "--bgColor": "var(--gitgo-blue)",
              "--size": "40px",
              "--wrapper_size": "40px",
            }}
          />
        )}
      </div>
      <PaginationWrapper
        perPage={perPage}
        setPerPage={(e) => setPerPage(e)}
        activePage={activePage}
        loading={loading}
        setActivePage={(e) => setActivePage(e)}
      />
    </div>,
    activeModal.value ? (
      <ModalWindow
        key={2}
        nestedObj
        setActiveModal={(val) => setActiveModal(val)}
        deleteModal
      >
        <div className={"delete_modal"}>
          <span>Delete</span>
          <h3>
            Are you sure you want to delete this {activeModal.count} Opportunit
            {activeModal.count > 1 ? "ies" : "y"}?
          </h3>
          <p>This action cannot be restored</p>
          <div className={"modal_window_buttons"}>
            <MainButton
              type={"button"}
              className={"cancel_button"}
              text={"Cancel"}
              onClick={() => {
                setActiveModal({
                  value: false,
                  count: null,
                });
                bodyOverflow(false);
              }}
            />
            <MainButton
              type={"button"}
              className={"delete_button"}
              text={"Delete"}
              onClick={() => handleDeleteProspects()}
            />
          </div>
        </div>
      </ModalWindow>
    ) : null,
  ];
}
