import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import helper from "../Constants/helper";
import ProjectNavigation from "../Navigation/ProjectNavigation";

const PrivateRouteProject = ({component: Component, ...rest}) => {
    const userToken = localStorage.getItem(helper.JWT_TOKEN);
    let responseComponent;

    if (userToken) {
        responseComponent = props => (<ProjectNavigation {...props} />);
    } else responseComponent = () => <Redirect to='/'/>;

    return <Route {...rest} render={responseComponent}/>;
};

export default PrivateRouteProject;
