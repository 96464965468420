import React, { useEffect, useState } from "react";
import "../Pages/styles/create_work.css";
import API from "../api/API";
import TextInput from "../Components/CustomComponents/TextInput";
import CustomSelect from "../Components/CustomSelect";
import MainButton from "../Components/CustomComponents/MainButton";
import { useHistory, useLocation } from "react-router-dom";
import regExp from "../Constants/regExp";
import useBottomPopup from "../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";
import { capitalizeFirstLetter } from "../Constants/capitalizeFirstLetter";

const initialState = {
  company_name: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
  },
  state: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    options: [],
    popup: false,
    loading: false,
  },
  city: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
  },
  email: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
  },
};

export default function CreateWork() {
  const history = useHistory();
  const location = useLocation();
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();

  const [createData, setCreateData] = useState(() => initialState);
  // const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(null);

  useEffect(() => {
    if (!editMode) {
      API()
        .get(`/states`)
        .then((res) => {
          setCreateData((prevState) => ({
            ...prevState,
            state: {
              ...prevState.state,
              options: res.data,
              loading: true,
            },
          }));
        });
    }
  }, [editMode]);

  useEffect(() => {
    let edit = location.pathname.split("/")[3];
    if (!isNaN(Number(edit))) {
      setEditMode(edit);
      Promise.all([
        API().get(`/gitgo_admin/workspaces/details?id=${edit}`),
        API().get(`/states`),
      ])
        .then((res) => {
          setCreateData((prevState) => ({
            ...prevState,
            company_name: {
              ...prevState.company_name,
              value: res[0].data.workspace.name,
            },
            state: {
              ...prevState.state,
              value: res[0].data.workspace.state ?? "",
              options: res[1].data,
            },
            city: {
              ...prevState.city,
              value: res[0].data.workspace.city,
            },
            email: {
              ...prevState.email,
              value: res[0].data.workspace.superadmin.email,
            },
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [location.pathname]);

  const createWorkspace = (e) => {
    e.preventDefault();
    let formData = new FormData();
    const { company_name, email, state, city } = createData;

    const condition =
      company_name.value && email.value && state.value && city.value;

    if (condition) {
      if (regExp.email.test(email.value)) {
        if (editMode) formData.append("id", editMode);
        formData.append("email", email.value.trim());
        formData.append("name", company_name.value.trim());
        formData.append("state", state.value);
        formData.append("city", city.value.trim());
        API()
          .post(`/gitgo_admin/workspaces/details`, formData)
          .then((res) => {
            if (res.status === 200) {
              changeContinuePopup(
                editMode ? "Workspace edited" : "Workspace created"
              );
              setTimeout(() => {
                changeContinuePopup(
                  editMode ? "Workspace edited" : "Workspace created"
                );
                history.push("/admin/workspaces");
              }, 2000);
            }
          })
          .catch((err) => {
            if (err.response.data.error === "user already exists") {
              setCreateData((prevState) => ({
                ...prevState,
                email: {
                  ...prevState.email,
                  error: true,
                  errorMessage: capitalizeFirstLetter(err.response.data.error),
                },
              }));
            }
          });
      } else {
        setCreateData((prevState) => ({
          ...prevState,
          email: {
            ...prevState.email,
            error: true,
            errorMessage: "Wrong format",
          },
        }));
      }
    } else {
      Object.entries(createData).forEach((el) => {
        if (!el[1].value) {
          setCreateData((prevState) => ({
            ...prevState,
            [el[0]]: {
              ...prevState[el[0]],
              error: true,
              errorMessage: "Fill this label",
            },
          }));
        }
      });
    }
  };

  return [
    <ChangedConfirmedPopup
      successText={successText}
      key={0}
      changingConfirmed={changingConfirmed}
    />,
    <div key={1} className={"create_work_wrapper"}>
      <h2 className={"page_title"}>
        {editMode ? "Edit Workspace" : "Create Workspace"}
      </h2>
      <h3>Main Info</h3>
      <form onSubmit={(e) => createWorkspace(e)}>
        <div className={"head_content"}>
          <div className={"full"}>
            <TextInput
              value={createData.company_name.value}
              type={"text"}
              title={"Company Name"}
              error={createData.company_name.error}
              errTitle={createData.company_name.errorMessage}
              onChange={({ target }) => {
                setCreateData((prevState) => ({
                  ...prevState,
                  company_name: {
                    ...prevState.company_name,
                    value: target.value,
                    error: false,
                  },
                }));
              }}
            />
          </div>
          <div className={"inline"}>
            <CustomSelect
              title={"State"}
              loading={createData.state.loading}
              options={createData.state.options}
              optionalLabel={"state"}
              popupPositionTop={"67px"}
              popupPositionLeft={"0px"}
              popupPositionWidth={"350px"}
              popup={createData.state.popup}
              setData={(e) => setCreateData(e)}
              error={createData.state.error}
              errTitle={createData.state.errorMessage}
              value={createData.state.value}
            />
            <TextInput
              value={createData.city.value}
              type={"text"}
              title={"City"}
              error={createData.city.error}
              errTitle={createData.city.errorMessage}
              onChange={({ target }) => {
                setCreateData((prevState) => ({
                  ...prevState,
                  city: {
                    ...prevState.city,
                    value: target.value,
                    error: false,
                  },
                }));
              }}
            />
          </div>
        </div>
        <div className={"body_content"}>
          <h3>Superadmin</h3>

          <div className={"inline"}>
            <TextInput
              value={createData.email.value}
              type={"text"}
              title={"Email"}
              error={createData.email.error}
              errTitle={createData.email.errorMessage}
              onChange={({ target }) => {
                setCreateData((prevState) => ({
                  ...prevState,
                  email: {
                    ...prevState.email,
                    value: target.value,
                    error: false,
                  },
                }));
              }}
            />
          </div>
        </div>
        <div className={"buttons_wrapper"}>
          <MainButton
            type={"button"}
            text={"Cancel"}
            className={"cancel_button"}
            onClick={() => history.push("/admin/workspaces")}
          />
          <MainButton
            type={"submit"}
            text={editMode ? "Edit" : "Create"}
            className={"submit_button"}
          />
        </div>
      </form>
    </div>,
  ];
}
