import * as types from "./types";

export const setUserData = action => ({
    type: types.USER_DATA,
    payload: action
});

export const setProspectData = action => ({
    type: types.PROSPECT_DATA,
    payload: action
});

export const setEditProspectData = action => ({
    type: types.EDIT_PROSPECT,
    payload: action
});

