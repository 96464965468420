import React, { useEffect, useState } from "react";
import API from "../api/API";
// import { useHistory } from "react-router-dom";
import { handleCurrentToken } from "../Constants/handleCurrentToken";
import SortingHeader from "./SortingHeader";
import chevron from "../assets/Dashboard/Union.svg";
import { Loader } from "./Loader";
import "../Pages/styles/dashboard.css";

const contactImage = [
  {
    img: (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z'
          stroke='#13426B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16 3V7'
          stroke='#13426B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 3V7'
          stroke='#13426B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4 11H20'
          stroke='#13426B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11 15H12'
          stroke='#13426B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12 15V18'
          stroke='#13426B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
    text: "meetings",
  },
  {
    img: (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z'
          stroke='#13426B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 7L12 13L21 7'
          stroke='#13426B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
    text: "emails",
  },
  {
    img: (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21C14.0993 20.763 10.4202 19.1065 7.65683 16.3432C4.8935 13.5798 3.23705 9.90074 3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4'
          stroke='#13426B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
    text: "calls",
  },
];
export default function StatisticModal(props) {
  const [data, setData] = useState(null);
  // const history = useHistory();
  const {
    // allRecentActivity,
    setViewEngagement,
    colorBlock,
    el,
    // viewEngagement,
    setOpenStats,
    svg,
  } = props;
  const [prospectsStatistics, setProspectsStatistics] = useState({
    new: [],
    property: [],
    ready: [],
    inactive: [],
    last_month: "",
    this_month: "",
  });
  const [opportunitiesStatistics, setOpportunitiesStatistics] = useState({
    opportunities: [],
    this_month: "",
    last_month: "",
  });
  const [wonOpportunitiesStatistics, setWonOpportunitiesStatistics] = useState({
    opportunities: [],
    this_month: "",
    last_month: "",
  });
  const [engagementsStatistics, setEngagementsStatistics] = useState({
    prospects: [],
    this_month: "",
    last_month: "",
    contacts: {},
  });
  const [revenueStatistics, setRevenueStatistics] = useState({
    prospects: [],
    this_month: "",
    last_month: "",
    contacts: {},
  });
  const [noActivity, setNoActivity] = useState({
    prospects: [],
    this_month: "",
    last_month: "",
  });
  const [revenueOrder, setRevenueOrder] = useState({
    value: { request: "name", render: "Company Name" },
    sorting: true,
    options: [
      { request: "name", render: "Company Name" },
      { request: "total_revenue", render: "Revenue Potential" },
    ],
  });
  const [wonOrder, setWonOrder] = useState({
    value: { request: "name", render: "Prospect Name" },
    sorting: true,
    options: [
      { request: "name", render: "Opportunity Name" },
      { request: "engagement_requested", render: "Engagement Requested" },
      { request: "total_revenue", render: "Revenue" },
    ],
  });
  const [opportunitiesOrder, setOpportunitiesOrder] = useState({
    value: { request: "name", render: "Prospect Name" },
    sorting: true,
    options: [
      { request: "name", render: "Opportunity Name" },
      { request: "status", render: "Status" },
      { request: "total_revenue", render: "Revenue Potential" },
    ],
  });
  const [noActive, setNoActive] = useState({
    value: { request: "name", render: "Prospect Name" },
    sorting: true,
    options: [
      { request: "name", render: "Company Name" },
      { request: "status", render: "Revenue Potential" },
    ],
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    Promise.all([
      API(handleCurrentToken()).get(
        "/dashboard/statistics/contacts?page=1&per_page=10"
      ),
      API(handleCurrentToken()).get(
        `/dashboard/statistics/opportunities?order_by=${
          opportunitiesOrder.value.request
        }&sorting=${opportunitiesOrder.sorting ? "asc" : "desc"}&per_page=10`
      ),
      API(handleCurrentToken()).get(
        `/dashboard/statistics/won_opportunities?order_by=${
          wonOrder.value.request
        }&sorting=${wonOrder.sorting ? "asc" : "desc"}&per_page=10`
      ),
      API(handleCurrentToken()).get(
        "/dashboard/statistics/engagements?page=1&per_page=10"
      ),
      API(handleCurrentToken()).get(
        `/dashboard/statistics/revenue_potential?order_by=${
          revenueOrder.value.request
        }&sorting=${revenueOrder.sorting ? "asc" : "desc"}&page=1&per_page=10`
      ),
      API(handleCurrentToken()).get(
        `/dashboard/statistics/won_revenue?order_by=${
          noActive.value.request
        }&sorting=${noActive.sorting ? "asc" : "desc"}&page=1&per_page=10`
      ),
    ]).then((res) => {
      setProspectsStatistics(res[0].data);
      setOpportunitiesStatistics(res[1].data);
      setWonOpportunitiesStatistics(res[2].data);
      setEngagementsStatistics(res[3].data);
      setRevenueStatistics(res[4].data);
      setNoActivity(res[5].data);
      setLoading(true);
      if (el[0] === "revenue_potential") {
        setData(res[4].data);
      } else if (el[0] === "won_opportunities") {
        setData(res[2].data);
      } else if (el[0] === "contacts") {
        setData(res[0].data);
      } else if (el[0] === "outreach") {
        setData(res[3].data);
      } else if (el[0] === "opportunities") {
        setData(res[1].data);
      } else if (el[0] === "won_revenue") {
        setData(res[5].data);
      }
    });
  }, [wonOrder, revenueOrder, noActive, opportunitiesOrder, el]);

  // console.log(wonOpportunitiesStatistics.opportunities);
  return (
    <div
      style={
        !loading
          ? {
              display: "none",
              transition: "opacity 1s linear",
            }
          : null
      }
      id='modal-window'
      className={"modal-background-wrapper"}
    >
      <div className='total-statistics-modal'>
        <div className='total-statistics-header'>
          <span className='title-of-modal-window'>
            Total {el[0].split("_").join(" ")}
          </span>
          <button
            onClick={() => {
              setTimeout(() => {
                setOpenStats(true);
              }, 100);
            }}
            className='close-modal-button'
          >
            Close
          </button>
        </div>
        <div
          className={`percentage-counter-of-total ${
            (el[0] === "won_revenue" && "no_activity") ||
            (el[0] === "outreach" && "center-block")
          }`}
        >
          <div
            style={{ background: colorBlock.how }}
            className={"ball_content"}
          >
            {svg}
          </div>
          <div className='grand-total'>
            <span>
              {el[0] === "revenue_potential" || el[0] === "won_revenue"
                ? "$"
                : ""}
              {data?.this_month
                ? data?.this_month
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0}
            </span>
            <p>This month</p>
          </div>
          <div className='grand-total'>
            <span>
              {el[0] === "revenue_potential" || el[0] === "won_revenue"
                ? data?.last_month
                  ? `$${data.last_month}`
                  : "$0"
                : data?.last_month
                ? data.last_month
                : "0"}
            </span>
            <p>Last month</p>
          </div>
          {el[0] !== "won_revenue" && (
            <div className='grand-total'>
              <div className='percentage-count-line'>
                <svg
                  width='30'
                  height='30'
                  viewBox='0 0 22 22'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M3.00952 15.375L8.25952 10.125L11.7595 13.625L18.7595 6.625'
                    stroke={
                      el[1].symbol === "down"
                        ? "var(--error)"
                        : el[1].symbol === "up"
                        ? "#4BDE97"
                        : "#FFB648"
                    }
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12.6345 6.625H18.7595V12.75'
                    stroke={
                      el[1].symbol === "down"
                        ? "var(--error)"
                        : el[1].symbol === "up"
                        ? "#4BDE97"
                        : "#FFB648"
                    }
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <span
                  style={
                    el[1].symbol === "down"
                      ? { color: "var(--error)" }
                      : el[1].symbol === "up"
                      ? { color: "#4BDE97" }
                      : { color: "#FFB648" }
                  }
                  className={"procent_number"}
                >
                  {el[1].percents}%
                </span>
              </div>
              <p>Month over month</p>
            </div>
          )}
        </div>

        {el[0] === "revenue_potential" && (
          <div className='modal-statistics-list'>
            <div className='block-of-status-data'>
              <div className='titles-modal-header'>
                <SortingHeader
                  orderBy={revenueOrder}
                  setOrderBy={(val) => setRevenueOrder(val)}
                />
              </div>
              <div className='modal-list-wrapper'>
                <div className='activity-modal-list'>
                  {loading ? (
                    revenueStatistics.prospects.length > 0 ? (
                      revenueStatistics.prospects.map((item, index) => (
                        <div key={index} className='modal-list-line'>
                          <span
                            onClick={() => {
                              window.open(
                                `/admin/companies/${item.id}`,
                                "_blank"
                              );
                            }}
                            className='name-of-user'
                          >
                            {item.name}
                          </span>
                          <span className='revenue-count'>
                            {item?.revenue_potential
                              ? "$" +
                                item?.revenue_potential
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : "Not Specified"}
                          </span>
                        </div>
                      ))
                    ) : (
                      <div className='no-items'>No items</div>
                    )
                  ) : (
                    <Loader
                      color={{
                        "--bgColor": "var(--gitgo-blue)",
                        "--size": "40px",
                        "--wrapper_size": "40px",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='footer-modal'>
              <div
                onClick={() => {
                  window.open(`/admin/companies`, "_blank");
                }}
                className='view-all-footer-block'
              >
                <span className='view-all-text'>View All Companies</span>
                <img src={chevron} alt='chevron' />
              </div>
            </div>
          </div>
        )}
        <div>
          {el[0] === "contacts" && (
            <div className='margin-table'>
              <div className='modal-status-list'>
                <div className='new-status-column'>
                  <div
                    onClick={() => {
                      window.open(`/admin/contacts?status=New`, "_blank");
                    }}
                    className='color-status-block new-status'
                  >
                    <span>New</span>
                  </div>
                  {prospectsStatistics.New?.length > 0
                    ? prospectsStatistics.New?.map((item, index) => (
                        <span
                          onClick={() => {
                            window.open(
                              `/admin/contacts/contact_info/${item.id}`,
                              "_blank"
                            );
                          }}
                          key={index}
                          className='new-column-users'
                        >
                          {item.name}
                        </span>
                      ))
                    : ""}
                </div>
                <div className='new-status-column'>
                  <div
                    onClick={() => {
                      window.open(
                        `/admin/contacts?status=Property_Information_Requested`,
                        "_blank"
                      );
                    }}
                    className='color-status-block property-status'
                  >
                    <span>Property Information Requested</span>
                  </div>
                  {prospectsStatistics["Property_Information_Requested"]?.map(
                    (item, index) => (
                      <span
                        onClick={() => {
                          window.open(
                            `/admin/contacts/contact_info/${item.id}`,
                            "_blank"
                          );
                        }}
                        key={index}
                        className='new-column-users'
                      >
                        {item.name}
                      </span>
                    )
                  )}
                </div>
                <div className='new-status-column'>
                  <div
                    onClick={() => {
                      window.open(
                        `/admin/contacts?status=Ready_for_Engagement`,
                        "_blank"
                      );
                    }}
                    className='color-status-block ready-status'
                  >
                    <span>Ready for Engagement</span>
                  </div>
                  {prospectsStatistics["Ready for Engagement"]?.map(
                    (item, index) => (
                      <span
                        onClick={() => {
                          window.open(
                            `/admin/contacts/contact_info/${item.id}`,
                            "_blank"
                          );
                        }}
                        key={index}
                        className='new-column-users'
                      >
                        {item.name}
                      </span>
                    )
                  )}
                </div>
                <div className='new-status-column last-closed'>
                  <div
                    onClick={() => {
                      window.open(`/admin/contacts?status=Inactive`, "_blank");
                    }}
                    className='color-status-block inactive-status'
                  >
                    <span>Inactive</span>
                  </div>
                  {prospectsStatistics.Inactive?.map((item, index) => (
                    <span
                      onClick={() => {
                        window.open(
                          `/admin/contacts/contact_info/${item.id}`,
                          "_blank"
                        );
                      }}
                      key={index}
                      className='new-column-users'
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
                {/* <div className='new-status-column last-closed'>
                  <div
                    onClick={() => {
                      window.open(`/admin/contacts?status=`, "_blank");
                    }}
                    className='color-status-block existing-status'
                  >
                    <span>Existing</span>
                  </div>
                  {prospectsStatistics.Existing?.length > 0
                    ? prospectsStatistics.Existing?.map((item, index) => (
                        <span
                          onClick={() => {
                            window.open(
                              `/admin/contacts/contact_info/${item.id}`,
                              "_blank"
                            );
                          }}
                          key={index}
                          className='new-column-users'
                        >
                          {item.name}
                        </span>
                      ))
                    : ""}
                </div> */}
              </div>
              {prospectsStatistics.New?.length < 1 && (
                <div className='no-items'>No items</div>
              )}
              <div className='footer-modal'>
                <div
                  onClick={() => {
                    window.open(`/admin/contacts`, "_blank");
                  }}
                  className='view-all-footer-block'
                >
                  <span className='view-all-text'>View All Contacts</span>
                  <img src={chevron} alt='chevron' />
                </div>
              </div>
            </div>
          )}
          <div className='won-opportunities-wrapper'>
            {el[0] === "won_opportunities" ? (
              <div className='modal-statistics-list'>
                <div className='block-of-status-data'>
                  <div className='titles-modal-header-opportunity'>
                    <SortingHeader
                      orderBy={wonOrder}
                      setOrderBy={(val) => setWonOrder(val)}
                    />
                  </div>
                  <div className='activity-modal-list'>
                    <div className='modal-list-wrapper'>
                      {wonOpportunitiesStatistics.opportunities?.length > 0 ? (
                        wonOpportunitiesStatistics.opportunities?.map(
                          (item, i) => (
                            <div key={i} className='modal-list-line'>
                              <span
                                onClick={() => {
                                  // console.log(`/admin/companies/${item.prospect_id}/${item.id}`);
                                  window.open(
                                    `/admin/companies/${item.prospect_id}/${item.id}`,
                                    "_blank"
                                  );
                                }}
                                className='name-of-user'
                              >
                                {item.name}
                              </span>
                              <span className='revenue-count'>
                                {item.engagement_requested}
                              </span>
                              <span className='revenue-count'>
                                $
                                {item.total_revenue
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </span>
                            </div>
                          )
                        )
                      ) : (
                        <div className='no-items'>No items</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='footer-modal'>
                  <div
                    onClick={() => {
                      window.open(`/admin/opportunities`, "_blank");
                    }}
                    className='view-all-footer-block'
                  >
                    <span className='discover-opportunities'>
                      Discover new opportunities
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {el[0] === "outreach" && (
                  <div className='modal-statistics-list'>
                    <div className='engagement-wrapper-activity'>
                      <div className='contact-title'>
                        <div className='title-block'>
                          <span className='title-engagement'>Contacts</span>
                        </div>
                        {Object.entries(engagementsStatistics.contacts).map(
                          (el, i) => (
                            <div key={i} className='contacts-block'>
                              <div className='logo-contacts-side'>
                                {contactImage.find((i) => i.text === el[0]).img}
                                <span
                                  // Add onClick
                                  onClick={() => {
                                    console.log(22222, el[0]);
                                    setViewEngagement(
                                      el[0].slice(0, el[0].length - 1)
                                    );
                                  }}
                                >
                                  {el[0]}
                                </span>
                              </div>
                              <button>{el[1]}</button>
                            </div>
                          )
                        )}
                        <div className='footer-modal'>
                          <div
                            onClick={() => {
                              // setTimeout(() => {
                              //   setOpenStats(true);
                              // }, 0);
                              setViewEngagement("all");
                            }}
                            className='view-all-footer-block'
                          >
                            <span className='view-all-text'>
                              View Recent Outreach
                            </span>
                            <img src={chevron} alt='chevron' />
                          </div>
                        </div>
                      </div>
                      <div className='contact-title prospect'>
                        <div className='title-block'>
                          <span className='title-engagement'>Companies</span>
                        </div>
                        {engagementsStatistics.prospects?.length > 0 ? (
                          engagementsStatistics.prospects
                            .sort()
                            .map((item, index) => (
                              <div
                                className='contacts-block prospect-section'
                                key={index}
                              >
                                <span
                                  onClick={() => {
                                    window.open(
                                      `/admin/companies/${item.id}`,
                                      "_blank"
                                    );
                                  }}
                                  className='prospect-name'
                                >
                                  {item.name}
                                </span>
                                <span className='last-engaged'>
                                  {item.last_engaged
                                    ? item.last_engaged
                                    : "No Engagement"}
                                </span>
                              </div>
                            ))
                        ) : (
                          <div className='no-items'>No items</div>
                        )}

                        <div className='footer-modal'>
                          <div
                            onClick={() => {
                              window.open(`/admin/companies`, "_blank");
                            }}
                            className='view-all-footer-block'
                          >
                            <span className='view-all-text'>
                              Go to Companies
                            </span>
                            <img src={chevron} alt='chevron' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {el[0] === "opportunities" && (
                  <div className='modal-statistics-list'>
                    <div className='block-of-status-data'>
                      <div className='titles-modal-header-opportunity'>
                        <SortingHeader
                          orderBy={opportunitiesOrder}
                          setOrderBy={(val) => setOpportunitiesOrder(val)}
                        />
                      </div>
                      <div className='modal-list-wrapper'>
                        <div className='activity-modal-list'>
                          {opportunitiesStatistics.opportunities?.length > 0 ? (
                            opportunitiesStatistics.opportunities.map(
                              (item, index) => (
                                <div key={index} className='modal-list-line'>
                                  <span
                                    onClick={() => {
                                      window.open(
                                        `/admin/companies/${item?.prospect_id}/${item?.id}`,
                                        "_blank"
                                      );
                                    }}
                                    className='name-of-user'
                                  >
                                    {item.name}
                                  </span>
                                  <button className='status-button'>
                                    {item.status.split("_").join(" ")}
                                  </button>
                                  <span className='revenue-count'>
                                    $
                                    {item.total_revenue
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </span>
                                </div>
                              )
                            )
                          ) : (
                            <div className='no-items'>No items</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='footer-modal'>
                      <div
                        onClick={() => {
                          window.open(`/admin/opportunities`, "_blank");
                        }}
                        className='view-all-footer-block'
                      >
                        <span className='view-all-text'>
                          View all opportunities
                        </span>
                        <img src={chevron} alt='chevron' />
                      </div>
                    </div>
                  </div>
                )}
                {el[0] === "won_revenue" && (
                  <div className='modal-statistics-list'>
                    <div className='block-of-status-data'>
                      <div
                        className={`titles-modal-header ${
                          el[0] === "won_revenue" && "no_activity"
                        }`}
                      >
                        <SortingHeader
                          orderBy={noActive}
                          setOrderBy={(val) => setNoActive(val)}
                        />
                      </div>
                      <div className='activity-modal-list'>
                        <div className='modal-list-wrapper'>
                          {noActivity.prospects?.length > 0 ? (
                            noActivity.prospects.map((item, i) => (
                              <div
                                key={i}
                                className={`modal-list-line ${
                                  el[0] === "won_revenue" ? "no_activity" : null
                                }`}
                              >
                                <span
                                  onClick={() => {
                                    window.open(
                                      `/admin/companies/${item.id}`,
                                      "_blank"
                                    );
                                  }}
                                  className='name-of-user'
                                >
                                  {item.name}
                                </span>

                                <button className='status-button'>
                                  {item.potential_revenue
                                    ? " $" +
                                      item.potential_revenue
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : "Not Specified"}
                                </button>
                              </div>
                            ))
                          ) : (
                            <div className='no-items'>No items</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='footer-modal'>
                      <div
                        onClick={() => {
                          window.open(`/admin/companies`, "_blank");
                        }}
                        className='view-all-footer-block'
                      >
                        <span className='view-all-text'>
                          View All Companies
                        </span>
                        <img src={chevron} alt='chevron' />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
