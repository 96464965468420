import React from 'react'
import MainButton from '../Components/CustomComponents/MainButton'
import {useHistory} from "react-router-dom"
import helper from "../Constants/helper";
import {useSelector} from "react-redux";
import './styles/404.css';

export default function PageNotFound() {
    const history = useHistory();
    const store = useSelector(state => state.auth.user_data)

    return (
        <div className={'page_not_found_wrapper'}>
            <div className={'forward_block_page'}>
                <div className={'content'}>
                <p>This page doesn`t exist</p>
                <svg width="382" height="142" viewBox="0 0 382 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 113.378H87.3126V138.781H110.978V113.378H128.492V92.9886H110.978V1.8718H80.0928L22 93.6571V113.378ZM87.58 92.9886H47.4031V91.9189L86.5104 29.9489H87.58V92.9886Z" fill="white"/>
                    <path d="M201.175 141.388C234.199 141.455 253.92 115.383 253.92 70.4601C253.92 25.8042 234.065 0 201.175 0C168.285 0 148.497 25.7373 148.43 70.4601C148.43 115.316 168.151 141.388 201.175 141.388ZM201.175 120.464C184.128 120.464 173.432 103.35 173.499 70.4601C173.566 37.8372 184.195 20.6567 201.175 20.6567C218.222 20.6567 228.851 37.8372 228.918 70.4601C228.918 103.35 218.289 120.464 201.175 120.464Z" fill="white"/>
                    <path d="M274.593 113.378H339.906V138.781H363.571V113.378H381.086V92.9886H363.571V1.8718H332.686L274.593 93.6571V113.378ZM340.173 92.9886H299.996V91.9189L339.104 29.9489H340.173V92.9886Z" fill="white"/>
                    <path d="M10 113.378H75.3126V138.781H98.9775V113.378H116.492V92.9886H98.9775V1.8718H68.0928L10 93.6571V113.378ZM75.58 92.9886H35.4031V91.9189L74.5104 29.9489H75.58V92.9886Z" fill="#C2CCFF"/>
                    <path d="M189.175 141.388C222.199 141.455 241.92 115.383 241.92 70.4601C241.92 25.8042 222.065 0 189.175 0C156.285 0 136.497 25.7373 136.43 70.4601C136.43 115.316 156.151 141.388 189.175 141.388ZM189.175 120.464C172.128 120.464 161.432 103.35 161.499 70.4601C161.566 37.8372 172.195 20.6567 189.175 20.6567C206.222 20.6567 216.851 37.8372 216.918 70.4601C216.918 103.35 206.289 120.464 189.175 120.464Z" fill="#C2CCFF"/>
                    <path d="M262.593 113.378H327.906V138.781H351.571V113.378H369.086V92.9886H351.571V1.8718H320.686L262.593 93.6571V113.378ZM328.173 92.9886H287.996V91.9189L327.104 29.9489H328.173V92.9886Z" fill="#C2CCFF"/>
                    <path d="M0 113.378H65.3126V138.781H88.9775V113.378H106.492V92.9886H88.9775V1.8718H58.0928L0 93.6571V113.378ZM65.58 92.9886H25.4031V91.9189L64.5104 29.9489H65.58V92.9886Z" fill="#8598FC"/>
                    <path d="M179.175 141.388C212.199 141.455 231.92 115.383 231.92 70.4601C231.92 25.8042 212.065 0 179.175 0C146.285 0 126.497 25.7373 126.43 70.4601C126.43 115.316 146.151 141.388 179.175 141.388ZM179.175 120.464C162.128 120.464 151.432 103.35 151.499 70.4601C151.566 37.8372 162.195 20.6567 179.175 20.6567C196.222 20.6567 206.851 37.8372 206.918 70.4601C206.918 103.35 196.289 120.464 179.175 120.464Z" fill="#8598FC"/>
                    <path d="M252.593 113.378H317.906V138.781H341.571V113.378H359.086V92.9886H341.571V1.8718H310.686L252.593 93.6571V113.378ZM318.173 92.9886H277.996V91.9189L317.104 29.9489H318.173V92.9886Z" fill="#8598FC"/>
                </svg>
                </div>
                <div className={'return_button_page'}>
                    <MainButton text={'Back to homepage'}
                                svg={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 8L3 12L7 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M3 12H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                }
                                className={'button_black'}
                                onClick={() => {
                                    if (localStorage.getItem(helper.JWT_TOKEN)) {
                                        store?.role === 'gitgo_admin_main'
                                            ? history.push('/admin/workspaces')
                                            : history.push('/admin/dashboard')
                                    } else {
                                        history.push('/')
                                    }
                                }}/>
                </div>
            </div>
        </div>
    )
}