import React, {useEffect, useState} from "react";
import './styles/password_recovery.css';
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";
import loginLogo from "../assets/img/gitGo_login_logo.png";
import useBottomPopup from "../Constants/useBottomPopup";
import TextInput from "../Components/CustomComponents/TextInput";
import {useHistory, useLocation} from "react-router-dom";
import API, {userLogout} from "../api/API";

export default function PasswordRecovery() {
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    let location = useLocation();
    let history = useHistory();

    const [token, setToken] = useState(null);
    const [tokenError, setTokenError] = useState(false);
    const [passwordData, setPasswordData] = useState({
        password: {
            value: '',
            error: false,
            errorMessage: '',
        },
        repeat_password: {
            value: '',
            error: false,
            errorMessage: '',
        },
    });

    useEffect(() => {
        let codeUrl = location.pathname.split('/').pop();
        if (codeUrl.includes('jwt')) {
            let urlToken = codeUrl.split('=').pop();
            API(urlToken).get(`/token_verification`)
                .then(res => {
                    if (res.status === 200) {
                        setToken(urlToken)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    if (err.response.status === 401) {
                        history.push('/404')
                    }
                })
        }
    }, [location.pathname, history])

    const passwordSubmit = (e) => {
        e.preventDefault();
        const {password, repeat_password} = passwordData;
        if (password.value && repeat_password.value) {
            if (password.value === repeat_password.value) {
                API(token).post(`/forgot_password/new_password`, {
                    password: password.value
                })
                    .then(res => {
                        if (res.status === 200) {
                            changeContinuePopup('Password changed')
                            setTimeout(() => {
                                changeContinuePopup('Password changed')
                                history.push('/')
                                userLogout()
                            }, 2000)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response.status === 401) {
                            setTokenError(true)
                        }
                    })
            } else {
                Object.entries(passwordData).forEach(el => {
                    setPasswordData(prevState => ({
                        ...prevState,
                        [el[0]]: {
                            ...prevState[el[0]],
                            error: true,
                            errorMessage: 'Passwords should match',
                        }
                    }))
                })
            }
        } else {
            Object.entries(passwordData).forEach(el => {
                if (!el[1].value) {
                    setPasswordData(prevState => ({
                        ...prevState,
                        [el[0]]: {
                            ...prevState[el[0]],
                            error: true,
                            errorMessage: 'Fill this label',
                        }
                    }))
                }
            })
        }
    }

    return [
        <ChangedConfirmedPopup successText={successText}
                               key={0}
                               changingConfirmed={changingConfirmed}/>,
        <div key={1} className={'recovery_wrapper'}>
            <div className={'content'}>
                <div className={'head'}>
                    <img src={loginLogo} alt="logo"/>
                </div>
                <div className={'body'}>
                    <h3>Set UP Password</h3>
                    <p>Please set up new password</p>
                    <form onSubmit={(e) => passwordSubmit(e)}>
                        <TextInput value={passwordData.password.value}
                                   type={'password'}
                                   title={'Password'}
                                   showPass={true}
                                   loginInput
                                   error={passwordData.password.error}
                                   errTitle={passwordData.password.errorMessage}
                                   autoComplete={'on'}
                                   onChange={({target}) => {
                                       setPasswordData(prevState => ({
                                           ...prevState,
                                           password: {
                                               ...prevState.password,
                                               value: target.value,
                                               error: false
                                           }
                                       }))
                                   }}/>
                        <TextInput value={passwordData.repeat_password.value}
                                   type={'password'}
                                   title={'Confirm Password'}
                                   showPass={true}
                                   loginInput
                                   error={passwordData.repeat_password.error}
                                   errTitle={passwordData.repeat_password.errorMessage}
                                   autoComplete={'on'}
                                   onChange={({target}) => {
                                       setPasswordData(prevState => ({
                                           ...prevState,
                                           repeat_password: {
                                               ...prevState.repeat_password,
                                               value: target.value,
                                               error: false
                                           }
                                       }))
                                   }}/>
                        {tokenError ?
                            <p className={'token_error'}>Your link has expired. Please confirm your email
                                again.</p> : null}
                        <button onClick={() => {
                            if (tokenError) {
                                history.push('/forgot_password')
                            }
                        }}
                                type={tokenError ? 'button' : 'submit'}>{tokenError ? 'Back to Forgot password page' : 'Confirm'}</button>
                    </form>
                </div>
            </div>
        </div>
    ]
}