import React, {useCallback, useEffect, useRef, useState} from "react";
import './styles/add_email_wrapper.css';
import regExp from "../Constants/regExp";
import API from "../api/API";
import {handleCurrentToken} from "../Constants/handleCurrentToken";
import OutsideClickHandler from "../Constants/OutsideClickHandler";
import {Loader} from "./Loader";

const initialState = {value: '', error: false};

const initialSearch = {
    value: false,
    loading: false,
    data: [],
}

export default function AddEmailsWrapper(props) {
    const ref = useRef();

    const {title, data, setData, error, setMembersError, svg, contactsSearch} = props;

    const [email, setEmail] = useState(initialState);
    const [filteredArray, setFilteredArray] = useState(null);
    const [searchPopup, setSearchPopup] = useState(() => initialSearch);

    useEffect(() => {
        let _filtered = searchPopup.data.filter(item => !data.includes(item))
        setFilteredArray(_filtered)
    }, [data, searchPopup.data])

    useEffect(() => {
        if (contactsSearch && email.value.length) {
            API(handleCurrentToken()).get(`/targeting/search?search=${email.value}`)
                .then(res => {
                    // console.log(res)
                    setSearchPopup(prevState => ({
                        ...prevState,
                        loading: true,
                        data: res.data
                    }))
                })
        }
    }, [contactsSearch, email.value])

    const keyComma = useCallback(key => {
        if (key.code === 'Comma' || key.code === 'Enter') {
            if (regExp.email.test(email.value)) {
                setData(prevState => ([
                    ...prevState, email.value
                ]))
                setEmail(initialState)
            } else {
                setEmail(prevState => ({
                    ...prevState,
                    error: true,
                }))
            }
        }
    }, [email, setData])

    useEffect(() => {
        document.addEventListener("keydown", keyComma);
        return () => document.removeEventListener("keydown", keyComma);
    }, [keyComma])

    const addEmail = (e) => {
        e.preventDefault();

        if (regExp.email.test(email.value)) {
            keyComma(e.key)
        }
    }

    return (
        <div className={`add_email_wrapper ${props.className ? props.className : ''}`}>
            <span className={'title'}>{title}</span>
            <div style={error ? {border: '1px solid var(--error)'} : null}
                 onClick={() => {
                     ref.current.focus()
                     setMembersError(null)
                 }} className={'content'}>
                {data.length ?
                    <div className={'content_flow'}>
                        {data.map((el, i) => (
                            <div className={'email_item'} key={i}>
                                <span>{el}</span>
                                <div
                                    onClick={() => setData(prevState => prevState.filter((item, index) => index !== i))}>
                                    {svg ? svg :
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.06071 7.99996L12.0295 5.03121C12.1704 4.89056 12.2496 4.6997 12.2498 4.50062C12.25 4.30154 12.171 4.11054 12.0304 3.96965C11.8897 3.82875 11.6989 3.7495 11.4998 3.74932C11.3007 3.74914 11.1097 3.82806 10.9688 3.96871L8.00008 6.93746L5.03133 3.96871C4.89043 3.82781 4.69934 3.74866 4.50008 3.74866C4.30082 3.74866 4.10973 3.82781 3.96883 3.96871C3.82793 4.1096 3.74878 4.3007 3.74878 4.49996C3.74878 4.69922 3.82793 4.89031 3.96883 5.03121L6.93758 7.99996L3.96883 10.9687C3.82793 11.1096 3.74878 11.3007 3.74878 11.5C3.74878 11.6992 3.82793 11.8903 3.96883 12.0312C4.10973 12.1721 4.30082 12.2513 4.50008 12.2513C4.69934 12.2513 4.89043 12.1721 5.03133 12.0312L8.00008 9.06246L10.9688 12.0312C11.1097 12.1721 11.3008 12.2513 11.5001 12.2513C11.6993 12.2513 11.8904 12.1721 12.0313 12.0312C12.1722 11.8903 12.2514 11.6992 12.2514 11.5C12.2514 11.3007 12.1722 11.1096 12.0313 10.9687L9.06071 7.99996Z"
                                                fill="#13426B"/>
                                        </svg>}
                                </div>
                            </div>
                        ))}
                    </div> : null}
                <form onSubmit={(e) => addEmail(e)}>
                    <input type="text"
                           ref={ref}
                           style={email.error ? {color: 'var(--error)'} : null}
                           value={email.value}
                           onClick={() => {
                               if (contactsSearch && email.value.length) {
                                   setSearchPopup(prevState => ({
                                       ...prevState,
                                       value: true,
                                   }))
                               }
                           }}
                           onChange={({target}) => {
                               if (target.value === ',') {
                                   setEmail(prevState => ({
                                       ...prevState,
                                       value: ''
                                   }))
                                   setSearchPopup(initialSearch)
                               } else {
                                   setEmail({
                                       error: false,
                                       value: target.value
                                   })
                                   setSearchPopup({
                                       data: [],
                                       value: true,
                                       loading: false,
                                   })
                                   if (!target.value.length) {
                                       setSearchPopup(initialSearch)
                                   }
                               }
                           }}/>
                </form>
            </div>

            {contactsSearch && searchPopup.value
                ? <OutsideClickHandler callback={() => {
                    setSearchPopup(prevState => ({
                        ...prevState,
                        value: false
                    }))
                }}>
                    <div style={!searchPopup.loading ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px'
                    } : null} className={'search_wrap overflow_scrollbar'}>
                        {searchPopup.loading
                            ? filteredArray.length
                                ? filteredArray
                                    .map((el, i) => (
                                        <span onClick={() => {
                                            setSearchPopup(prevState => ({
                                                ...prevState,
                                                value: false,
                                            }))
                                            setData(prevState => ([
                                                ...prevState, el
                                            ]))
                                            setEmail(initialState)
                                        }} className={'option'} key={i}>{el}</span>
                                    )) : <span className={'no_items_style'}>No items</span>
                            : <Loader color={{
                                '--bgColor': 'var(--gitgo-blue)',
                                '--size': '40px',
                                '--wrapper_size': '40px'
                            }}/>}
                    </div>
                </OutsideClickHandler> : null}
        </div>
    )
}