import React from "react";
import "./styles/support.css";
import OutsideClickHandler from "../Constants/OutsideClickHandler";
// import TextInput from './CustomComponents/TextInput';
// import TextBox from './CustomComponents/TextBox';
// // import attach from "../assets/img/Support/file.svg";
// import MainButton from './CustomComponents/MainButton';

export default function SupportModal(props) {
  const {
    setSupportPop,
    // supportHelp, supportData, setSupportData
  } = props;

  return (
    <div className={"support_modal_wrapper"}>
      <OutsideClickHandler callback={() => setSupportPop(false)}>
        <div className='support_modal_window'>
          <iframe
            style={{ width: "814px", height: "627px", border: "0" }}
            title='he'
            src={"https://share.hsforms.com/1Drtha7UhTG-MqZV3VZ-djQc1r5a"}
          ></iframe>
        </div>

        {/* <div className={'support_modal_window'}>
          <div className={'support_header_window'}>
            <h2>Support</h2>
            <span>Describe your problem</span>
          </div>
          <div className={'support_fields_wrapper'}>
            <form onSubmit={(e) => supportHelp(e)}>
              <div className={'fields_list'}>
                <TextInput
                  id={'name_field'}
                  value={supportData.name.value}
                  type={'text'}
                  title={'Name'}
                  error={supportData.name.error}
                  errTitle={supportData.name.errorMessage}
                  onChange={({ target }) => {
                    setSupportData((prevState) => ({
                      ...prevState,
                      name: {
                        ...prevState.name,
                        value: target.value,
                        error: false,
                      },
                    }));
                  }}
                />
                <TextInput
                  id={'email_field'}
                  value={supportData.email.value}
                  type={'text'}
                  title={'Email'}
                  error={supportData.email.error}
                  errTitle={supportData.email.errorMessage}
                  onChange={({ target }) => {
                    setSupportData((prevState) => ({
                      ...prevState,
                      email: {
                        ...prevState.email,
                        value: target.value,
                        error: false,
                      },
                    }));
                  }}
                />

                <TextBox
                  id={'message_input'}
                  value={supportData.message.value}
                  type={'text'}
                  title={'Message'}
                  error={supportData.message.error}
                  errTitle={supportData.message.errorMessage}
                  onChange={({ target }) => {
                    setSupportData((prevState) => ({
                      ...prevState,
                      message: {
                        ...prevState.message,
                        value: target.value,
                        error: false,
                      },
                    }));
                  }}
                />
              </div>
              <div className={'support_footer_submit_block'}>
                <div>
                  <MainButton type={'submit'} text={'Send'} className={'please_help_button'} />
                </div>
              </div>
            </form>
          </div>
        </div> */}
      </OutsideClickHandler>
    </div>
  );
}
