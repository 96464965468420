import React from "react";
import { Route } from "react-router-dom";
import { Navigation } from "../routers/navigation";
import useResize from "../Constants/useResize";

const ProjectNavigation = React.memo(() => {
    const { innerWidth } = useResize();

    const SwitcherNavBar = ({ routes }) => (
        routes.map((route, index) => (
            <Route path={route.path}
                exact={true}
                component={route.main}
                key={index} />
        ))
    )

    return (
        innerWidth > 1100
            ? <div className={'wrapper_app'}>
                <SwitcherNavBar routes={Navigation.mainProjectNavigation} />
            </div>
            : <div className={'wrapper_admin_panel_content_responsive'}>
                <div className={'admin_panel_content_responsive'}>
                    <span>The GitGo Outreach App is not available at this resolution.</span>
                    <span>Please make the browser larger to view the content</span>
                </div>
            </div>
    )
})

export default ProjectNavigation;