import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png";
import "./styles.css";
import useResize from "../../Constants/useResize";
import TextInput from "../CustomComponents/TextInput";
import OutsideClickHandler from "../../Constants/OutsideClickHandler";
import { Link } from "react-router-dom";
import API, { forceLogout } from "../../api/API";
import { useDispatch, useSelector } from "react-redux";
import Keys from "../../Constants/helper";
import salesforce from "../../assets/img/salesforce.png";
import zoom_info from "../../assets/img/zoom_info.png";
import { setUserData } from "../../main-store/auth/actions";
import { handleCurrentToken } from "../../Constants/handleCurrentToken";

const Header = ({ blankHeader }) => {
  let integrationAdded = true;
  const [activeSearch, setActiveSearch] = useState({
    value: "",
    popup: false,
    options: [],
  });
  const [willClose, setWillClose] = useState(false);
  const [searchWrapper, setSearchWrapper] = useState(false);
  const [integration, setActiveIntegration] = useState(false);
  const dispatch = useDispatch();
  const { innerWidth } = useResize();

  const store = useSelector((state) => state.auth.user_data);
  let gitGoAdmin = store.role === "gitgo_admin";
  const [profilePopup, setProfilePopup] = useState(false);

  useEffect(() => {
    if (store.role !== "gitgo_admin_main") {
      API(handleCurrentToken())
        .get(`/search?search=${activeSearch.value}&page=1&per_page=3`)
        .then((res) => {
          // console.log(res)
          if (res.data) {
            setActiveSearch((prevState) => ({
              ...prevState,
              options: res.data,
            }));
          }
        });
    }
  }, [activeSearch.value, store.role]);
  console.log(activeSearch);
  const searchNavPopUp = () => {
    return (
      <OutsideClickHandler
        callback={() => {
          setSearchWrapper(false);
          setWillClose(true);
          setTimeout(() => {
            setWillClose(false);
          }, 200);
        }}
      >
        <div className={"search_wrapper_popup"}>
          {Object.entries(activeSearch.options).map((item, index) => {
            return (
              <div key={index}>
                <div className={"title_of_search_category"}>
                  {activeSearch.value.length >= 2 ? (
                    <span>
                      {item[0] === "prospects" || item[0] === "companies"
                        ? "Companies"
                        : "Contacts"}
                    </span>
                  ) : (
                    <span>No results</span>
                  )}
                </div>
                <div className={"customers_wrapper_search"}>
                  {item[1] && item[1].length ? (
                    item[1].map((el) => {
                      return (
                        <Link
                          key={el.id}
                          to={
                            item[0] === "contacts"
                              ? `/admin/contacts_name_info/${el.id}`
                              : `/admin/companies/${el.id}`
                          }
                          onClick={() => {
                            setSearchWrapper(false);
                          }}
                        >
                          <div className={"search_results"}>
                            <div className={"contact_line"}>
                              <span>{el.name}</span>
                            </div>
                          </div>
                        </Link>
                      );
                    })
                  ) : (
                    <span>No Items</span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </OutsideClickHandler>
    );
  };

  const cardLayout = (picture, text, added, second) => (
    <div className={"card"}>
      <img
        style={second ? { margin: "0 auto 26px" } : null}
        src={picture}
        alt='salesforce'
      />
      <span>{text}</span>
      <button className={added ? "added" : ""}>
        {added ? (
          <svg
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21Z'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M9.5 12L11.5 14L15.5 10'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        ) : (
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12 5V19'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M5 12H19'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        )}
        <span>{added ? "Integrated" : "Add integration"}</span>
      </button>
    </div>
  );

  return (
    innerWidth > 1100 && [
      <header key={10} className={`header`}>
        {/*
                <div className={`logo_container ${store.company_name && !blankHeader ? 'logo_container_project' : ''}`}>
                    <img src={logo} alt="logo"/>
                    {store.company_name && !blankHeader ? <span>{store.company_name}</span> : null}
                </div>
                */}
        <div
          className={`logo_container ${
            store.company_name && !blankHeader ? "logo_container_project" : ""
          }`}
        >
          <img src={logo} alt='logo' />
          {/*
                        {store.company_name && !blankHeader ? <div className={'tooltip'}>
                        <span className={'header-company-name'}>{store.company_name}</span>
                        <span className={'tooltiptext'}>
                            {store.company_name}
                        </span>
                    </div> : null}
                        */}
        </div>

        <div
          style={
            store.role === "gitgo_admin_main" ? { justifyContent: "end" } : null
          }
          className={"header_content"}
        >
          {!blankHeader
            ? [
                store.role !== "gitgo_admin_main" && (
                  <div
                    key={0}
                    className={`search_bar ${
                      gitGoAdmin ? "search_bar_admin" : ""
                    }`}
                  >
                    {gitGoAdmin ? (
                      <div
                        onClick={() => {
                          dispatch(
                            setUserData({
                              ...store,
                              company_name: "",
                              role: "gitgo_admin_main",
                              first_name: "Git Go",
                              last_name: "Admin",
                            })
                          );
                          localStorage.removeItem(Keys.SUPER_ADMIN_JWT_TOKEN);
                          window.location.href = "/admin/workspaces";
                        }}
                        className={"leave_workspace"}
                      >
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0)'>
                            <path
                              d='M4 12L14 12'
                              stroke='#01426A'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <g filter='url(#filter0_d)'>
                              <path
                                d='M4 12L8 8'
                                stroke='#01426A'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </g>
                            <path
                              d='M4 12L8 16'
                              stroke='#01426A'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M20 20L20 4'
                              stroke='#01426A'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </g>
                          <defs>
                            <filter
                              id='filter0_d'
                              x='-1'
                              y='7'
                              width='14'
                              height='14'
                              filterUnits='userSpaceOnUse'
                              colorInterpolationFilters='sRGB'
                            >
                              <feFlood
                                floodOpacity='0'
                                result='BackgroundImageFix'
                              />
                              <feColorMatrix
                                in='SourceAlpha'
                                type='matrix'
                                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                result='hardAlpha'
                              />
                              <feOffset dy='4' />
                              <feGaussianBlur stdDeviation='2' />
                              <feColorMatrix
                                type='matrix'
                                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
                              />
                              <feBlend
                                mode='normal'
                                in2='BackgroundImageFix'
                                result='effect1_dropShadow'
                              />
                              <feBlend
                                mode='normal'
                                in='SourceGraphic'
                                in2='effect1_dropShadow'
                                result='shape'
                              />
                            </filter>
                            <clipPath id='clip0'>
                              <rect
                                width='24'
                                height='24'
                                fill='white'
                                transform='translate(24 24) rotate(-180)'
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Leave workspace</span>
                      </div>
                    ) : null}
                    <div
                      onClick={() => {
                        if (!willClose) {
                          setSearchWrapper(true);
                        }
                      }}
                      className={"search"}
                    >
                      <TextInput
                        value={activeSearch.value}
                        type={"text"}
                        searchIcon
                        className={"input_search_wrap"}
                        loginInput
                        onChange={({ target }) => {
                          setActiveSearch((prevState) => ({
                            ...prevState,
                            value: target.value,
                          }));
                        }}
                        onClick={() => {
                          setActiveSearch((prevState) => ({
                            ...prevState,
                            popup: true,
                          }));
                        }}
                      />
                      {searchWrapper && activeSearch.value.length >= 2
                        ? searchNavPopUp()
                        : null}
                    </div>
                  </div>
                ),
                <div key={1} className={"workspace-nav-container"}>
                  {gitGoAdmin ? (
                    <div
                      className={"leave_workspace leave_workspace-container"}
                    >
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M3 21H21'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M9 8H10'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M9 12H10'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M9 16H10'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M14 8H15'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M14 12H15'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M14 16H15'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      {store.company_name && !blankHeader ? (
                        <div className={"tooltip"}>
                          <span className={"header-company-name"}>
                            {store.company_name}
                          </span>
                          <span className={"tooltiptext"}>
                            {store.company_name}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>,
                <div key={2} className={"profile_popup_wrapper"}>
                  {store.role === "gitgo_admin_main" ? (
                    <div className={`user_name user_name_admin`}>
                      <span>
                        {store.first_name.trim()} {store.last_name.trim()}
                      </span>
                    </div>
                  ) : (
                    <>
                      <div
                        style={profilePopup ? { pointerEvents: "none" } : null}
                        onClick={() => setProfilePopup((state) => !state)}
                        className={"user_name"}
                      >
                        <span>
                          {store.first_name} {store.last_name}
                        </span>
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4 6L8 10L12 6'
                            stroke='var(--gitgo-blue)'
                            strokeWidth='3.25'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                      <OutsideClickHandler
                        callback={() => setProfilePopup(false)}
                      >
                        <div
                          className={`profile_popup ${
                            profilePopup ? "profile_popup_active" : ""
                          }`}
                        >
                          <Link
                            onClick={() => setProfilePopup(false)}
                            to={"/admin/profile_settings"}
                          >
                            Profile Settings
                          </Link>
                          <Link
                            onClick={() => setProfilePopup(false)}
                            to={"/admin/manage_seats"}
                          >
                            Manage Seats
                          </Link>
                          {/*<button onClick={() => {*/}
                          {/*    setProfilePopup(false)*/}
                          {/*    setActiveIntegration(true)*/}
                          {/*}}>Integration*/}
                          {/*</button>*/}
                          <span onClick={() => forceLogout()}>Log out</span>
                        </div>
                      </OutsideClickHandler>
                    </>
                  )}
                </div>,
              ]
            : null}
        </div>
      </header>,
      integration ? (
        <div key={20} className={"integration_wrapper"}>
          <OutsideClickHandler callback={() => setActiveIntegration(false)}>
            <div className={"integration_content"}>
              <p>Integrations</p>
              <div className={"inline"}>
                {cardLayout(salesforce, "Salesforce")}
                {cardLayout(zoom_info, "Zoom Info", integrationAdded, true)}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      ) : null,
    ]
  );
};
export default Header;
