import React from "react";
import { useLocation } from "react-router-dom";

import fileUpload from "../../assets/img/file-upload.svg";
import { handleCurrentToken } from "../../Constants/handleCurrentToken";
import "./UploadContentPopUp.css";

const UploadContentPopUp = (props, { onDrop }) => {
  let location = useLocation();
  const [drag, setDrag] = React.useState(false);
  const [filename, setFilename] = React.useState("");
  const [typeError, setTypeError] = React.useState(false);
  console.log(props);
  let dropRef = React.createRef();
  let dragCounter = 0;

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) setDrag(true);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) setDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    console.log(e.dataTransfer.files[0].type);
    // if (e.dataTransfer.files[0].type === 'text/csv') {
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setTypeError(false);
      props.onDrop(e.dataTransfer.files[0]);
      setFilename(e.dataTransfer.files[0].name);
      e.dataTransfer.clearData();
      dragCounter = 0;
    } else {
      setTypeError(true);
    }
  };

  const uploadFile = ({ target }) => {
    setFilename(target.files[0].name);
    props.onDrop(target.files[0]);
  };

  React.useEffect(() => {
    let div = dropRef.current;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDrop);
    return () => {
      div.removeEventListener("dragenter", handleDragIn);
      div.removeEventListener("dragleave", handleDragOut);
      div.removeEventListener("dragover", handleDrag);
      div.removeEventListener("drop", handleDrop);
    };
  });

  return (
    <div className='popup-box'>
      <div className='upload-box'>
        {/* <p className='upload-title-text'>Upload the contact</p>
                <p className='upload-info-text'>Platform supports the CSV format</p> */}
        <label
          style={{ cursor: "pointer" }}
          ref={dropRef}
          className={
            drag ? "filedrop drag" : filename ? "filedrop ready" : "filedrop"
          }
        >
          <input
            type='file'
            onChange={uploadFile}
            hidden
            accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          />

          {filename && !drag ? (
            <div>{filename}</div>
          ) : (
            <div className='file-upload-container'>
              <img src={fileUpload} alt={"img"} />
              <p className='uploadFileText'>Drag or upload a document</p>
            </div>
          )}
        </label>
        {(typeError || props?.error) && (
          <p
            style={{
              color: "red",
              marginBottom: -20,
              marginTop: 10,
              fontSize: 14,
            }}
          >
            {props.error ? props.error : "Please upload a .csv file"}
          </p>
        )}
        <a
          href={`${process.env.REACT_APP_API_URL}/data/download_template?data=${
            location.pathname.split("/")[2]
          }&jwt=${handleCurrentToken()}`}
          download
          style={{ cursor: "pointer" }}
          className='upload-download-template-text'
        >
          Download template ↓
        </a>
        <div className='upload-buttons-container'>
          <button onClick={props.handleClose}>Cancel</button>
          <button onClick={props.uploadFile}>Upload</button>
        </div>
        {props.content}
      </div>
    </div>
  );
};

export default UploadContentPopUp;
