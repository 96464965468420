import React, { Component } from "react";
import "../Charts/dashboard_column.css";
import Chart from "react-apexcharts";
import API from "../../api/API";
import { handleCurrentToken } from "../../Constants/handleCurrentToken";

class DashboardColumn extends Component {
  constructor(props) {
    super(props);
    const arrStatus = [
      "New",
      "Property Information Requested",
      "Ready for Engagement",
      "Inactive",
      // "Existing",
    ];

    this.state = {
      options: {
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            distributed: true,
            columnWidth: "50%",
            cssClass: "bar_tags",
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: ["#FFB648", "#F57C1F", "#4BDE97", "#F26464", "#49BACA"],
          width: 0,
        },
        states: {
          neww: {
            filter: {
              type: "none",
              value: 1,
            },
          },
          property: {
            filter: {
              type: "none",
              value: 2,
            },
          },
          ready: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 3,
            },
          },
          inactive: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 4,
            },
          },
        },

        chart: {
          id: "basic-bar",
          height: 350,
          type: "bar",
          stacked: true,
          width: "10%",
          toolbar: {
            show: false,
          },
          events: {
            click: this.onChartItemClick,
          },
        },
        tooltip: {
          enabled: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            /*eslint no-useless-concat: "off"*/

            return (
              '<div class="arrow_box">' +
              "<div>" +
              '<div class="status_block">' +
              '<span class="block_name">' +
              "Contact Status" +
              "</span>" +
              '<span  class="status_type">' +
              arrStatus[dataPointIndex] +
              "</span>" +
              "<div/>" +
              "</div>" +
              '<div class="count_footer">' +
              '<span class="count_number">' +
              series[seriesIndex][dataPointIndex] +
              "</span>" +
              '<span class="record_type">' +
              "Record count" +
              "</span>" +
              "</div>" +
              "</div>"
            );
          },
          fillSeriesColor: false,
          theme: false,
          style: {
            colors: ["#FFB648", "#F57C1F", "#4BDE97", "#F26464", "#49BACA"],
            fontSize: "14px",
            fontFamily: "Inter, sans-serif",
          },
          fixed: {
            enabled: false,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          show: true,
          showAlways: true,
          tickAmount: 3,
          crosshairs: {
            show: true,
            position: "back",
            stroke: {
              color: "#ffffff",
              width: 10,
              dashArray: 0,
            },
          },

          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            formatter: function (val, fixed) {
              if (
                (typeof val === "number" || typeof val === "string") &&
                !isNaN(val - parseFloat(val))
              ) {
                val = String(val);
                let split = val.split(".");
                if (split.length > 1) {
                  let left = split[0];
                  let right = split[1].substr(0, !fixed ? 4 : fixed);
                  return Number(left + (fixed !== 0 ? "." + right : ""));
                } else {
                  return Number(val);
                }
              }
            },
            style: {
              colors: ["#FFFFFF"],
              fontSize: "14px",
              fontFamily: "Inter, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
          },
        },
        xaxis: {
          categories: [" ", " ", " ", " "],
          markers: {
            colors: ["#FFB648", "#F57C1F", "#4BDE97", "#F26464", "#49BACA"],
          },
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: ["#FFB648", "#F57C1F", "#4BDE97", "#F26464", "#49BACA"],
              fontSize: "16px",
              fontFamily: "Inter, sans-serif",
              fontWeight: "normal",
              cssClass: "apexcharts-xaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            format: undefined,
            formatter: undefined,
            datetimeUTC: true,
          },
        },
        colors: ["#FFB648", "#F57C1F", "#4BDE97", "#F26464", "#49BACA"],
      },
      series: [
        {
          name: "Contact Status",
          data: [30, 40, 45, 60],
        },
      ],
    };
  }

  //prospects:
  // statuses:
  // active: {total: 1, percents: 11.11}
  // closed: {total: 5, percents: 55.56}
  // new: {total: 3, percents: 33.33}
  // [[Prototype]]: Object

  componentDidMount() {
    API(handleCurrentToken())
      .get(`/dashboard/graphs`)
      .then((res) => {
        console.log("res: ", res.data.contacts.statuses);
        const neww = res.data.contacts.statuses.New;
        const property =
          res.data.contacts.statuses["Property Information Requested"];
        const ready = res.data.contacts.statuses["Ready for Engagement"];
        const inactive = res.data.contacts.statuses.Inactive;
        // const existing = res.data.contacts.statuses.Existing;
        console.log(neww, property, ready, inactive);

        this.setState({
          series: [
            {
              name: "Contact Status",
              data: [
                neww.total,
                property.total,
                ready.total,
                inactive.total,
                // existing.total,
              ],
              test: res.data.contacts.statuses.New.total,
            },
          ],
        });
      });
  }

  onChartItemClick = (_, __, config) => {
    const categories = [
      "New",
      "Property_Information_Requested",
      "Ready_for_Engagement",
      "Inactive",
      // "Existing",
    ];
    window.open(
      `/admin/contacts?status=${categories[config.dataPointIndex]}`,
      "_blank"
    );
  };

  render() {
    return (
      <button className='app'>
        <div className='mixed-chart'>
          {this.state.series[0].test > 0 ? (
            <div className='block-chart'>
              <Chart
                id={"my_chart"}
                options={this.state.options}
                series={this.state.series}
                type='bar'
                width='380'
              />
              <div className='block-names'>
                <div
                  className='block-name'
                  onClick={() => {
                    window.open(`/admin/contacts?status=New`, "_blank");
                  }}
                >
                  <div
                    style={{ backgroundColor: "#FFB648" }}
                    className='block'
                  ></div>
                  <span style={{ color: "#FFB648" }}>New</span>
                </div>
                <div
                  className='block-name'
                  onClick={() => {
                    window.open(
                      `/admin/contacts?status=Property_Information_Requested`,
                      "_blank"
                    );
                  }}
                >
                  <div
                    style={{ backgroundColor: "#F57C1F" }}
                    className='block'
                  ></div>
                  <span style={{ color: "#F57C1F" }}>
                    Property Information Requested
                  </span>
                </div>
                <div
                  className='block-name'
                  onClick={() => {
                    window.open(
                      `/admin/contacts?status=Ready_for_Engagement`,
                      "_blank"
                    );
                  }}
                >
                  <div
                    style={{ backgroundColor: "#4BDE97" }}
                    className='block'
                  ></div>
                  <span style={{ color: "#4BDE97" }}>Ready for Engagement</span>
                </div>
                <div
                  className='block-name'
                  onClick={() => {
                    window.open(`/admin/contacts?status=Inactive`, "_blank");
                  }}
                >
                  <div
                    style={{ backgroundColor: "#F26464" }}
                    className='block'
                  ></div>
                  <span style={{ color: "#F26464" }}>Inactive</span>
                </div>
                {/* <div
                  className='block-name'
                  onClick={() => {
                    window.open(`/admin/contacts?status=Existing`, "_blank");
                  }}
                >
                  <div
                    style={{ backgroundColor: "#49BACA" }}
                    className='block'
                  ></div>
                  <span style={{ color: "#49BACA" }}>Existing</span>
                </div> */}
              </div>
            </div>
          ) : (
            <div className={"dashboard-graphs-line"}>
              <div className={"no-activity-title-graphs"}>
                <span>There have been no contacts yet</span>
              </div>
            </div>
          )}
        </div>
      </button>
    );
  }
}

export default DashboardColumn;
