import React from "react";
import {Redirect, Route} from "react-router-dom";
import helper from '../Constants/helper'
import SideBarRoute from "../Navigation/SideBarRoute";

export default function PrivateRoute({children, ...rest}) {
    let responseComponent;
    let getToken = localStorage.getItem(helper.JWT_TOKEN);

    if (getToken) {
        responseComponent = props => (<SideBarRoute {...props} />);
    } else responseComponent = () => (<Redirect to='/'/>);

    return <Route {...rest} render={responseComponent}/>;
}