import React from "react";

import './DeletePopUp.css'

const DeletePopUp = props => {
    return (
        <div className="popup-box">
            <div className="delete-box">
                <p className='delete-popup-title'>Delete</p>
                <p className='item-name'>Jarod Johnson</p>
                <p className='delete-question-text'>Are you sure you want to permanently delete this user?</p>
                <div className='delete-popup-buttons-container'>
                    <button onClick={props.handleClose}>Cancel</button>
                    <button>Delete</button>
                </div>
                {props.content}
            </div>
        </div>
    );
};

export default DeletePopUp