import React, { useEffect, useState } from "react";
import "./styles/contacts.css";
import "./styles/user_info.css";
import { useLocation, useHistory, Link } from "react-router-dom";
import API from "../../api/API";
import { handleCurrentToken } from "../../Constants/handleCurrentToken";
import MainButton from "../../Components/CustomComponents/MainButton";
import { Loader } from "../../Components/Loader";
// import ModalWindow from "../../Components/ModalWindow";
// import {bodyOverflow} from "../../Constants/bodyOverflow";
import OutsideClickHandler from "../../Constants/OutsideClickHandler";
import moment from "moment";
import { capitalizeFirstLetter } from "../../Constants/capitalizeFirstLetter";
import TextBox from "../../Components/CustomComponents/TextBox";
import DatePickerWrapper from "../../Components/DatePickerWrapper";

const meetingIcon = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16 3V7'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 3V7'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4 11H20'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 15H8V17H10V15Z'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const mailIcon = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3 7L12 13L21 7'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const callIcon = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21C14.0993 20.763 10.4202 19.1065 7.65683 16.3432C4.8935 13.5798 3.23705 9.90074 3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const initialState = {
  name: "",
  email: "",
  job_title: "",
  phone: "",
  company: "",
  status: "",
};

// const modalState = {
//     value: false,
//     data: '',
// };

const addLogState = ["meeting", "email", "call"];

const activityInitial = {
  id: null,
  type: null,
  create: true,
  date: {
    title: "Date of Engagement",
    value: "",
    error: false,
    errorMessage: "Fill this label",
  },
  notes: {
    title: "Notes",
    value: "",
    error: false,
    errorMessage: "Fill this label",
  },
};

const ContactsUserInfo = () => {
  const history = useHistory();
  const location = useLocation();

  const [editMode, setEditMode] = useState(null);
  // const [activeModal, setActiveModal] = useState(modalState);
  const [loading, setLoading] = useState(false);
  const [activityData, setActivityData] = useState({
    activity: [],
    popup: false,
  });
  const [createData, setCreateData] = useState(() => initialState);
  console.log(11111, activityData);
  useEffect(() => {
    let editMode = location.pathname.split("/").pop();
    if (!isNaN(Number(editMode))) {
      setEditMode(editMode);
      Promise.all([
        API(handleCurrentToken()).get(`/contacts/details?id=${editMode}`),
        API(handleCurrentToken()).get(
          `/contacts/activity?contact_id=${editMode}`
        ),
      ]).then((res) => {
        console.log(res);
        setCreateData({
          name: res[0].data.contact.name,
          email: res[0].data.contact.email,
          job_title: res[0].data.contact.job_title,
          phone: res[0].data.contact.phone,
          company: res[0].data.contact.company.name,
          status: res[0].data.contact.status
            ? res[0].data.contact.status
            : "No status",
        });
        setActivityData((prevState) => ({
          ...prevState,
          activity: res[1].data.activity.map((el) => {
            return {
              id: el.id,
              type: el.type,
              create: false,
              date: {
                title: "Date of Engagement",
                value: el.date ?? "",
                error: false,
                errorMessage: "Fill this label",
              },
              notes: {
                title: "Notes",
                value: el.notes ?? "",
                error: false,
                errorMessage: "Fill this label",
              },
            };
          }),
        }));
        setLoading(true);
      });
    }
  }, [location.pathname]);

  // const handleDeleteContact = () => {
  //     API(handleCurrentToken()).delete(`/contacts/details?id=${editMode}`)
  //         .then(res => {
  //             if (res.status === 200) {
  //                 history.push(`/admin/contacts`)
  //             }
  //         })
  // }

  const addActivityLog = (el, i) => {
    console.log(el);
    if (el.notes.value && el.date.value) {
      API(handleCurrentToken())
        .post(`/contacts/activity`, {
          contact_id: +editMode,
          type: el.type,
          date: moment(el.date.value, "MMM DD YYYY").format("DD/MM/YYYY"),
          notes: el.notes.value,
        })
        .then((res) => {
          console.log(res);
          setActivityData((prevState) => ({
            ...prevState,
            activity: [
              ...prevState.activity.slice(0, i),
              {
                ...prevState.activity[i],
                create: false,
                id: res.data.id ?? "",
              },
              ...prevState.activity.slice(i + 1),
            ],
          }));
        });
    } else {
      Object.entries(el).forEach((c) => {
        if (c[1] instanceof Object) {
          if (!c[1].value) {
            setActivityData((prevState) => ({
              ...prevState,
              activity: [
                ...prevState.activity.slice(0, i),
                {
                  ...prevState.activity[i],
                  [c[0]]: {
                    ...prevState.activity[i][c[0]],
                    error: true,
                  },
                },
                ...prevState.activity.slice(i + 1),
              ],
            }));
          }
        }
      });
    }
  };

  const [showMoreActivities, setShowMoreActivities] = useState([]);

  const handleShowMore = (index) => {
    let arr = [...showMoreActivities];

    if (arr.includes(index)) {
      setShowMoreActivities(arr.filter((el) => el !== index));
    } else {
      arr.push(index);
      setShowMoreActivities(arr);
    }
  };

  console.log(loading);

  return [
    <div key={1} className={"user_info_wrapper"}>
      <div className={"navigation"}>
        <Link to={"/admin/contacts"}>Contacts</Link>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M6 4L10 8L6 12'
            stroke='#475569'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <span>{loading ? createData.name : "Contact name"}</span>
      </div>
      {loading ? (
        <div className={"title_wrap"}>
          <h2 className={"page_title"}>{createData.name}</h2>
          <MainButton
            type={"button"}
            text={"Edit"}
            svg={
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M4 19.9998H8L18.5 9.49981C19.0304 8.96938 19.3284 8.24996 19.3284 7.49981C19.3284 6.74967 19.0304 6.03025 18.5 5.49981C17.9696 4.96938 17.2501 4.67139 16.5 4.67139C15.7499 4.67139 15.0304 4.96938 14.5 5.49981L4 15.9998V19.9998Z'
                  stroke='var(--gitgo-blue)'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M13.5 6.5L17.5 10.5'
                  stroke='var(--gitgo-blue)'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
            className={"edit_button"}
            onClick={() =>
              history.push(`/admin/contacts/create_contact/${editMode}`)
            }
          />
        </div>
      ) : null}
      <div
        style={
          !loading
            ? {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "345px",
              }
            : null
        }
      >
        {loading ? (
          <div className={"contact_details"}>
            <h6>Contact Details</h6>
            <div className={"content"}>
              {Object.entries(createData).map((el, i) => (
                <div
                  className={el[0] === "prospects" ? "full_line" : ""}
                  key={i}
                >
                  <span>
                    {el[0].includes("_") ? el[0].split("_").join(" ") : el[0]}
                  </span>
                  {el[1] instanceof Object ? (
                    el[1].id && el[1].name ? (
                      <div className={"prospects"}>
                        <Link
                          to={`/admin/companies/${el[1].id}`}
                          key={el[1].id}
                        >
                          {el[1].name}
                        </Link>
                      </div>
                    ) : (
                      <span className={"unassigned"}>Unassigned</span>
                    )
                  ) : el[0] === "status" ? (
                    <p
                      className='p-style'
                      style={{
                        color: "#fff",
                        backgroundColor: `${
                          el[1] === "New"
                            ? "#FFB648"
                            : el[1] === "Property Information Requested"
                            ? "#F57C1F"
                            : el[1] === "Ready for Engagement"
                            ? "#4BDE97"
                            : "#F26464"
                        }`,
                      }}
                    >
                      {el[1]}
                    </p>
                  ) : (
                    <p>{el[1]}</p>
                  )}
                  {/* "New" ||
                    "Ready For Engagement" ||
                    "Property Information Requested" ||
                    "Inactive" */}
                </div>
              ))}
            </div>
            {/* <div onClick={() => {
                            setActiveModal({
                                value: true,
                                data: createData.name,
                            })
                        }} className={'delete_contact'}>
                            <p>Delete User’s Account</p>
                            <div>
                                <span>Delete</span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.375 6.125L5.25 16.625C5.25 17.0891 5.43437 17.5342 5.76256 17.8624C6.09075 18.1906 6.53587 18.375 7 18.375H14C14.4641 18.375 14.9092 18.1906 15.2374 17.8624C15.5656 17.5342 15.75 17.0891 15.75 16.625L16.625 6.125"
                                        stroke="#94A3B8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M7.875 6.125V3.5C7.875 3.26794 7.96719 3.04538 8.13128 2.88128C8.29538 2.71719 8.51794 2.625 8.75 2.625H12.25C12.4821 2.625 12.7046 2.71719 12.8687 2.88128C13.0328 3.04538 13.125 3.26794 13.125 3.5V6.125"
                                        stroke="#94A3B8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M3.5 6.125H17.5" stroke="#94A3B8" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M8.75 9.625V14.875" stroke="#94A3B8" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M12.25 9.625V14.875" stroke="#94A3B8" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>

                            </div>
                        </div> */}
          </div>
        ) : (
          <Loader
            color={{
              "--bgColor": "var(--gitgo-blue)",
              "--size": "48px",
              "--wrapper_size": "40px",
            }}
          />
        )}
      </div>
      <div className={"activity_wrapper"}>
        <div className={"title_wrap"}>
          <span>Outreach</span>
          <div className={"add_activity_log"}>
            <MainButton
              type={"button"}
              svg={
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12 5V19'
                    stroke='white'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M5 12H19'
                    stroke='white'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              }
              className={"button_default_plus"}
              text={"Add log"}
              onClick={() => {
                setActivityData((prevState) => ({
                  ...prevState,
                  popup: true,
                }));
              }}
            />
            {activityData.popup ? (
              <OutsideClickHandler
                callback={() => {
                  setActivityData((prevState) => ({
                    ...prevState,
                    popup: false,
                  }));
                }}
              >
                <div className={"content"}>
                  {addLogState.map((el, i) => (
                    <div
                      onClick={() => {
                        setActivityData((prevState) => ({
                          ...prevState,
                          popup: false,
                          activity: prevState.activity.every((b) => b.id)
                            ? [
                                {
                                  ...activityInitial,
                                  type: el,
                                  create: true,
                                },
                                ...prevState.activity,
                              ]
                            : //todo : pererobity sogodni
                              [
                                ...prevState.activity.slice(0, 0),
                                {
                                  ...activityInitial,
                                  type: el,
                                  create: true,
                                },
                                ...prevState.activity.slice(1),
                              ],
                        }));
                      }}
                      key={i}
                    >
                      {el === "call"
                        ? callIcon
                        : el === "meeting"
                        ? meetingIcon
                        : mailIcon}
                      <span>{el}</span>
                    </div>
                  ))}
                </div>
              </OutsideClickHandler>
            ) : null}
          </div>
        </div>

        <div
          style={
            !loading
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "345px",
                }
              : null
          }
          className={"activity_content overflow_scrollbar"}
        >
          {loading ? (
            activityData.activity.length ? (
              activityData.activity.map((el, i) => {
                if (el.create) {
                  return (
                    <div className={"activity_item activity_item_new"} key={i}>
                      <div className={"max_width"}>
                        <div className={"logged"}>
                          {el.type === "call"
                            ? callIcon
                            : el.type === "meeting"
                            ? meetingIcon
                            : mailIcon}
                          <span>{el.type} Logged</span>
                        </div>
                        <DatePickerWrapper
                          format={"MMM DD, YYYY"}
                          title={"Date of Engagement"}
                          error={el.date.error}
                          errTitle={el.date.errorMessage}
                          icon={
                            <svg
                              width='24'
                              height='25'
                              viewBox='0 0 24 25'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M18 5.5H6C4.89543 5.5 4 6.39543 4 7.5V19.5C4 20.6046 4.89543 21.5 6 21.5H18C19.1046 21.5 20 20.6046 20 19.5V7.5C20 6.39543 19.1046 5.5 18 5.5Z'
                                stroke='var(--gitgo-blue)'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M16 3.5V7.5'
                                stroke='var(--gitgo-blue)'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M8 3.5V7.5'
                                stroke='var(--gitgo-blue)'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M4 11.5H20'
                                stroke='var(--gitgo-blue)'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M11 15.5H12'
                                stroke='var(--gitgo-blue)'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M12 15.5V18.5'
                                stroke='var(--gitgo-blue)'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          }
                          value={el.date.value}
                          onChange={(date, dateString) => {
                            setActivityData((prevState) => ({
                              ...prevState,
                              activity: [
                                ...prevState.activity.slice(0, i),
                                {
                                  ...prevState.activity[i],
                                  date: {
                                    ...prevState.activity[i].date,
                                    value: dateString,
                                    error: false,
                                  },
                                },
                                ...prevState.activity.slice(i + 1),
                              ],
                            }));
                          }}
                        />
                      </div>
                      <div className={"notes"}>
                        <span>Notes</span>
                        <TextBox
                          value={el.notes.value}
                          rows={8}
                          error={el.notes.error}
                          errTitle={el.notes.errorMessage}
                          onChange={({ target }) => {
                            setActivityData((prevState) => ({
                              ...prevState,
                              activity: [
                                ...prevState.activity.slice(0, i),
                                {
                                  ...prevState.activity[i],
                                  notes: {
                                    ...prevState.activity[i].notes,
                                    value: target.value,
                                    error: false,
                                  },
                                },
                                ...prevState.activity.slice(i + 1),
                              ],
                            }));
                          }}
                        />
                      </div>

                      <div className={"buttons_wrapper"}>
                        <MainButton
                          type={"button"}
                          text={"Cancel"}
                          className={"cancel_button"}
                          onClick={() => {
                            setActivityData((prevState) => ({
                              ...prevState,
                              activity: prevState.activity.filter(
                                (b, num) => num !== i
                              ),
                            }));
                          }}
                        />
                        <MainButton
                          type={"button"}
                          text={"Save"}
                          onClick={() => addActivityLog(el, i)}
                          className={"submit_button"}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className={"activity_item"} key={i}>
                      <div className={"left"}>
                        <div className={"logged"}>
                          {el.type === "call"
                            ? callIcon
                            : el.type === "meeting"
                            ? meetingIcon
                            : mailIcon}
                          <span>{el.type} Logged</span>
                        </div>
                        <span className={"time"}>
                          {el.date.value.includes("/")
                            ? moment(el.date.value, "DD/MM/YYYY").format(
                                "MMM DD, YYYY"
                              )
                            : el.date.value}
                        </span>
                      </div>
                      <div className={"notes"}>
                        <span>Notes:</span>
                        <div>
                          <p
                            className={
                              !showMoreActivities.includes(i)
                                ? "notes-text"
                                : "notes-text-full"
                            }
                          >
                            {capitalizeFirstLetter(el.notes.value)}
                          </p>
                          {el.notes.value.length > 160 && (
                            <p
                              style={{
                                marginTop: 10,
                                fontSize: 12,
                                color: "#475569",
                                fontWeight: "600",
                              }}
                              onClick={() => handleShowMore(i)}
                            >
                              {!showMoreActivities.includes(i)
                                ? "show more"
                                : "hide"}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <span className={"no_items_style"}>No activities yet</span>
            )
          ) : (
            <Loader
              color={{
                "--bgColor": "var(--gitgo-blue)",
                "--size": "40px",
                "--wrapper_size": "40px",
              }}
            />
          )}
        </div>
      </div>
    </div>,
    // activeModal.value ?
    //     <ModalWindow key={2} nestedObj setActiveModal={val => setActiveModal(val)}
    //                  deleteModal>
    //         <div className={'delete_modal'}>
    //             <span>Delete</span>
    //             <h3>{activeModal.data}</h3>
    //             <p>Are you sure you want to permanently delete this Contact?</p>
    //             <div className={'modal_window_buttons'}>
    //                 <MainButton type={'button'}
    //                             className={'cancel_button'}
    //                             text={'Cancel'}
    //                             onClick={() => {
    //                                 setActiveModal(modalState)
    //                                 bodyOverflow(false)
    //                             }}
    //                 />
    //                 <MainButton type={'button'}
    //                             className={'delete_button'}
    //                             text={'Delete'}
    //                             onClick={() => handleDeleteContact()}
    //                 />
    //             </div>
    //         </div>
    //     </ModalWindow> : null,
  ];
};

export default ContactsUserInfo;
