import React, { useEffect, useState } from "react";
import "../Pages/styles/workspaces.css";
import API, { baseURL } from "../api/API";
import MainButton from "../Components/CustomComponents/MainButton";
import TextInput from "../Components/CustomComponents/TextInput";
import { useGetLastChartHash } from "../Constants/useGetLastChartHash";
import { Loader } from "../Components/Loader";
import OutsideClickHandler from "../Constants/OutsideClickHandler";
import { useHistory } from "react-router-dom";
import Keys from "../Constants/helper";
import { setUserData } from "../main-store/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import SortingHeader from "../Components/SortingHeader";
import ModalWindow from "../Components/ModalWindow";
import { bodyOverflow } from "../Constants/bodyOverflow";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";
import useBottomPopup from "../Constants/useBottomPopup";
import PaginationWrapper from "../Components/PaginationWrapper";

const modalState = {
  value: null,
  data: "",
  type: "",
};

export default function Workspaces() {
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeHashUrl } = useGetLastChartHash();
  const store = useSelector((state) => state.auth.user_data);

  const [data, setData] = useState(null);
  const [deleteWork, setDeleteWork] = useState(false);
  const [activeModal, setActiveModal] = useState(() => modalState);
  const [orderBy, setOrderBy] = useState({
    value: { request: "name", render: "Name" },
    sorting: true,
    options: [
      { request: "name", render: "Name" },
      { request: "created", render: "Created" },
      { request: "last_login", render: "Last Loggin" },
      { request: "contacts", render: "Contacts" },
      { request: "city", render: "City" },
      { request: "state", render: "State" },
      { request: "superadmin", render: "superadmin" },
    ],
  });
  const [searchData, setSearchData] = useState({
    value: "",
  });
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState({
    page: null,
    pages: null,
    total: null,
  });
  const [perPage, setPerPage] = useState({
    data: {
      value: "10",
      options: ["10", "50", "100"],
      popup: false,
    },
  });

  useEffect(() => {
    if (store.role !== "gitgo_admin_main") {
      if (!localStorage.getItem(Keys.SUPER_ADMIN_JWT_TOKEN)) {
        history.push("/404");
      } else {
        history.push("/admin/dashboard");
      }
    }
  }, [history, store.role]);

  useEffect(() => {
    if (activeHashUrl) {
      setActivePage((prevState) => ({
        ...prevState,
        page: activeHashUrl,
      }));
    }
    return () => null;
  }, [activeHashUrl]);

  useEffect(() => {
    if (activePage.page) {
      API()
        .get(
          `/gitgo_admin/workspaces?order_by=${orderBy.value.request}&sorting=${
            orderBy.sorting ? "asc" : "desc"
          }&search=${searchData.value}&page=${activePage.page}&per_page=${
            perPage.data.value
          }`
        )
        .then((res) => {
          res.data.workspaces.forEach((el) => (el.popup = false));
          setData(res.data.workspaces);
          setActivePage((prevState) => ({
            ...prevState,
            pages: res.data.pages,
            total: res.data.total,
          }));
          setLoading(true);
        });
    }
  }, [orderBy, activePage.page, searchData.value, perPage.data.value]);

  const openWorkspace = (el) => {
    API()
      .post(`/gitgo_admin/open_workspace?id=${el.id}`)
      .then((res) => {
        localStorage.setItem(Keys.SUPER_ADMIN_JWT_TOKEN, res.data.access_token);
        dispatch(
          setUserData({
            //todo: look later
            ...store,
            role: res.data.role,
            company_name: el.name,
            first_name: el.superadmin
              ? el.superadmin.split(" ")[0]
              : "Superadmin",
            last_name: el.superadmin ? el.superadmin.split(" ")[1] : "account",
          })
        );
      })
      .then(() => {
        window.location.href = "/admin/dashboard";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteWorkspace = () => {
    API()
      .delete(`/gitgo_admin/workspaces?ids=${selectedWorkspaces.join()}`)
      .then(() => {
        setData((prevState) =>
          prevState.filter((el) => el.id !== activeModal.value)
        );
        bodyOverflow(false);
        setActiveModal(modalState);
        setDeleteWork(false);
        selectWorkspace([]);
        changeContinuePopup("Workspace deleted");
        setTimeout(() => {
          changeContinuePopup("Workspace deleted");
        }, 2000);
      });
  };
  const exportEmailToExel = () => {
    const token = localStorage.getItem(Keys.JWT_TOKEN);
    window.open(baseURL + "/gitgo_admin/export_data?jwt=" + token);
  };
  const [selectedWorkspaces, selectWorkspace] = useState([]);

  const exportProspectsIds = () => {
    const token = localStorage.getItem(Keys.JWT_TOKEN);
    window.open(
      baseURL +
        `/gitgo_admin/export_data?workspaces_ids=${selectedWorkspaces.join()}&jwt=` +
        token
    );
  };

  console.log(selectedWorkspaces);

  const handleSelectWorspace = (id) => {
    if (selectedWorkspaces.includes(id)) {
      selectWorkspace(selectedWorkspaces.filter((item) => item !== id));
    } else {
      selectWorkspace((prevState) => [...prevState, id]);
    }
  };

  return [
    <ChangedConfirmedPopup
      successText={successText}
      key={10}
      changingConfirmed={changingConfirmed}
    />,
    <div key={0} className={"workspace_wrapper"}>
      <div className={"title"}>
        <h2 className={"page_title"}>Workspaces</h2>
        <div style={{ display: "flex", gap: 16 }}>
          <MainButton
            type={"button"}
            svg={
              <svg
                width='25'
                height='24'
                viewBox='0 0 25 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M4.5 17V19C4.5 19.5304 4.71071 20.0391 5.08579 20.4142C5.46086 20.7893 5.96957 21 6.5 21H18.5C19.0304 21 19.5391 20.7893 19.9142 20.4142C20.2893 20.0391 20.5 19.5304 20.5 19V17'
                  stroke='white'
                  strokeWidth='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M7.5 11L12.5 16L17.5 11'
                  stroke='white'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M12.5 4V16'
                  stroke='white'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
            className={"button_default_plus"}
            text={"Export Data"}
            onClick={() => exportEmailToExel()}
          />
          <MainButton
            type={"button"}
            svg={
              <svg
                width='25'
                height='24'
                viewBox='0 0 25 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12.5 5V19'
                  stroke='white'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M5.5 12H19.5'
                  stroke='white'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            }
            className={"button_default_plus"}
            text={"New workspace"}
            onClick={() => history.push("/admin/workspaces/new_workspace")}
          />
        </div>
      </div>

      <div className={"workspace_search"}>
        <TextInput
          value={searchData.value}
          type={"text"}
          searchIcon
          searchPages={
            <svg
              className={"search_icon"}
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z'
                stroke='#13426B'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M21 21L15 15'
                stroke='#13426B'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
          className={"input_search_pages"}
          loginInput
          onChange={({ target }) => {
            setSearchData((prevState) => ({
              ...prevState,
              value: target.value,
            }));
          }}
        />
        {selectedWorkspaces.length > 0 && (
          <div style={{ display: "flex", gap: 16, width: 400, marginTop: 16 }}>
            <MainButton
              style={{
                backgroundColor: "transparent",
                color: "#F26464",
                borderColor: "#F26464",
              }}
              type={"button"}
              svg={
                <svg
                  width='27'
                  height='26'
                  viewBox='0 0 27 26'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M5.61572 7.61548L6.69265 20.5386C6.69265 21.1098 6.91957 21.6576 7.32349 22.0616C7.72742 22.4655 8.27526 22.6924 8.84649 22.6924H17.4619C18.0331 22.6924 18.581 22.4655 18.9849 22.0616C19.3888 21.6576 19.6157 21.1098 19.6157 20.5386L20.6926 7.61548'
                    fill='#FEEFEF'
                  />
                  <path
                    d='M5.61572 7.61548L6.69265 20.5386C6.69265 21.1098 6.91957 21.6576 7.32349 22.0616C7.72742 22.4655 8.27526 22.6924 8.84649 22.6924H17.4619C18.0331 22.6924 18.581 22.4655 18.9849 22.0616C19.3888 21.6576 19.6157 21.1098 19.6157 20.5386L20.6926 7.61548'
                    stroke='#F26464'
                    strokeWidth='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M9.92334 7.61555V4.38478C9.92334 4.09917 10.0368 3.82525 10.2388 3.62328C10.4407 3.42132 10.7146 3.30786 11.0003 3.30786H15.308C15.5936 3.30786 15.8675 3.42132 16.0695 3.62328C16.2714 3.82525 16.3849 4.09917 16.3849 4.38478V7.61555'
                    fill='#FEEFEF'
                  />
                  <path
                    d='M9.92334 7.61555V4.38478C9.92334 4.09917 10.0368 3.82525 10.2388 3.62328C10.4407 3.42132 10.7146 3.30786 11.0003 3.30786H15.308C15.5936 3.30786 15.8675 3.42132 16.0695 3.62328C16.2714 3.82525 16.3849 4.09917 16.3849 4.38478V7.61555'
                    stroke='#F26464'
                    strokeWidth='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M4.53857 7.61548H21.7693'
                    stroke='#F26464'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M11 11.9231V18.3846'
                    stroke='#F26464'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M15.3081 11.9231V18.3846'
                    stroke='#F26464'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              }
              className={"button_default_plus"}
              text={`Delete ${selectedWorkspaces.length} ${
                selectedWorkspaces.length < 2
                  ? "Workspace (account)"
                  : "Workspaces (accounts)"
              }`}
              onClick={() => {
                setDeleteWork(true);
              }}
            />
            <MainButton
              type={"button"}
              style={{
                backgroundColor: "transparent",
                color: "#13426B",
                borderColor: "#13426B",
              }}
              svg={
                <svg
                  width='26'
                  height='26'
                  viewBox='0 0 26 26'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M4.38477 18.3848V20.5386C4.38477 21.1098 4.61169 21.6577 5.01561 22.0616C5.41954 22.4655 5.96738 22.6925 6.53861 22.6925H19.4617C20.0329 22.6925 20.5808 22.4655 20.9847 22.0616C21.3886 21.6577 21.6155 21.1098 21.6155 20.5386V18.3848'
                    stroke='#13426B'
                    strokeWidth='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M7.61572 11.9231L13.0003 17.3077L18.385 11.9231'
                    stroke='#13426B'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M13.0005 4.38477V17.3078'
                    stroke='#13426B'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              }
              className={"button_default_plus"}
              text={`Export Data from ${selectedWorkspaces.length} ${
                selectedWorkspaces.length < 2 ? "Workspace" : "Workspaces"
              }`}
              onClick={() => exportProspectsIds()}
            />
          </div>
        )}
        {deleteWork ? (
          <ModalWindow
            key={1}
            nestedObj
            setActiveModal={(val) => setActiveModal(val)}
            deleteModal
          >
            <div className={"delete_modal"}>
              <span>Delete</span>
              <h3>{activeModal.data}</h3>
              <p>{`Are you sure you want to permanently delete ${
                selectedWorkspaces.length
              } ${
                selectedWorkspaces.length < 2 ? "workspace" : "workspaces"
              }?`}</p>
              <div className={"modal_window_buttons"}>
                <MainButton
                  type={"button"}
                  className={"cancel_button"}
                  text={"Cancel"}
                  onClick={() => {
                    setActiveModal(modalState);
                    setDeleteWork(false);
                    bodyOverflow(false);
                  }}
                />
                <MainButton
                  type={"button"}
                  className={"delete_button"}
                  text={"Delete"}
                  onClick={() => deleteWorkspace()}
                />
              </div>
            </div>
          </ModalWindow>
        ) : null}
      </div>

      <div className={"workspace_content"}>
        {loading ? (
          data.length ? (
            <div className={"workspace_container overflow_scrollbar"}>
              <div className={"head"} style={{ paddingLeft: 100 }}>
                <SortingHeader
                  orderBy={orderBy}
                  setOrderBy={(val) => setOrderBy(val)}
                />
              </div>
              <div className={"container_body"}>
                {data.map((el, i) => (
                  <div key={el.id} className={"item"}>
                    <span
                      className={"checkbox"}
                      style={{ lineHeight: 0, cursor: "pointer" }}
                      onClick={() => handleSelectWorspace(el.id)}
                    >
                      {selectedWorkspaces.includes(el.id) && (
                        <svg
                          width='8'
                          height='6'
                          viewBox='0 0 8 6'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1 3L3 5L7 1'
                            stroke='#01426A'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      )}
                    </span>
                    <div className={"popup_wrap"}>
                      <svg
                        style={el.popup ? { pointerEvents: "none" } : null}
                        onClick={() => {
                          el.popup = true;
                          setData((prevState) => [
                            ...prevState.slice(0, i),
                            el,
                            ...prevState.slice(i + 1),
                          ]);
                        }}
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z'
                          stroke='#CBD5E1'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z'
                          stroke='#CBD5E1'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z'
                          stroke='#CBD5E1'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      {el.popup ? (
                        <OutsideClickHandler
                          callback={() => {
                            el.popup = false;
                            setData((prevState) => [
                              ...prevState.slice(0, i),
                              el,
                              ...prevState.slice(i + 1),
                            ]);
                          }}
                        >
                          <div className={"popup_content"}>
                            <span
                              onClick={() =>
                                history.push(`/admin/workspaces/${el.id}`)
                              }
                            >
                              Edit
                            </span>
                            <span
                              onClick={() => {
                                bodyOverflow(true);
                                setActiveModal({
                                  value: el.id,
                                  data: el.name,
                                });
                              }}
                              className={"delete"}
                            >
                              Delete
                            </span>
                          </div>
                        </OutsideClickHandler>
                      ) : null}
                    </div>
                    <p>{el.name}</p>
                    <span>{el.created}</span>
                    <span>{el.last_login ?? "No login yet"}</span>
                    <span>{el.contacts}</span>
                    <span>{el.city}</span>
                    <span>{el.state}</span>
                    <span>{el.superadmin ?? "No information"}</span>
                    <div
                      onClick={() => openWorkspace(el)}
                      className={"open_workspace"}
                    >
                      <span>Open</span>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5 12H19'
                          stroke='var(--gitgo-blue)'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M13 18L19 12'
                          stroke='var(--gitgo-blue)'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M13 6L19 12'
                          stroke='var(--gitgo-blue)'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <span className={"no_items_style"}>No items</span>
          )
        ) : (
          <Loader
            color={{
              "--bgColor": "var(--gitgo-blue)",
              "--size": "40px",
              "--wrapper_size": "40px",
            }}
          />
        )}
      </div>

      <PaginationWrapper
        perPage={perPage}
        setPerPage={(e) => setPerPage(e)}
        loading={loading}
        activePage={activePage}
        setActivePage={(e) => setActivePage(e)}
      />
    </div>,
    activeModal.value ? (
      <ModalWindow
        key={1}
        nestedObj
        setActiveModal={(val) => setActiveModal(val)}
        deleteModal
      >
        <div className={"delete_modal"}>
          <span>Delete</span>
          <h3>{activeModal.data}</h3>
          <p>Are you sure you want to permanently delete this workspace?</p>
          <div className={"modal_window_buttons"}>
            <MainButton
              type={"button"}
              className={"cancel_button"}
              text={"Cancel"}
              onClick={() => {
                setActiveModal(modalState);
                bodyOverflow(false);
              }}
            />
            <MainButton
              type={"button"}
              className={"delete_button"}
              text={"Delete"}
              onClick={() => deleteWorkspace()}
            />
          </div>
        </div>
      </ModalWindow>
    ) : null,
  ];
}
