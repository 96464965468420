import * as types from "./types";
import Keys from "../../Constants/helper";

const initState = {
    user_data: localStorage.getItem(Keys.USER_DATA) ?
        JSON.parse(localStorage.getItem(Keys.USER_DATA))
        : null,
    prospect_data: localStorage.getItem(Keys.PROSPECT_DATA) ?
        JSON.parse(localStorage.getItem(Keys.PROSPECT_DATA))
        : null,
    edit_prospect: localStorage.getItem(Keys.EDIT_PROSPECT) ?
        JSON.parse(localStorage.getItem(Keys.EDIT_PROSPECT))
        : null,
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case types.USER_DATA:
            localStorage.setItem(Keys.USER_DATA, JSON.stringify(action.payload))
            return {
                ...state,
                user_data: action.payload,
            };
        case types.PROSPECT_DATA:
            localStorage.setItem(Keys.PROSPECT_DATA, JSON.stringify(action.payload))
            return {
                ...state,
                prospect_data: action.payload,
            };
        case types.EDIT_PROSPECT:
            localStorage.setItem(Keys.EDIT_PROSPECT, JSON.stringify(action.payload))
            return {
                ...state,
                edit_prospect: action.payload,
            };
        default: {
            return state;
        }
    }
};

export default authReducer;
