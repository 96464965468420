import React from "react";

export default function SortingHeader({orderBy, setOrderBy}) {

    return (
        orderBy.options.map((el, i) => (
            <div className={'item'} key={i} onClick={() => setOrderBy(prevState => ({
                ...prevState,
                value: el,
                sorting: prevState.value.request === el.request ? !prevState.sorting : true
            }))}>
                <span>{el.render}</span>
                <svg style={el.request === orderBy.value.request ? {
                    transform: "rotate(180deg)",
                    transition: '.2s'
                } : {transition: '.2s'}}
                     width="18" height="18" viewBox="0 0 18 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 7.5L9 10.5L12 7.5L6 7.5Z" fill="#0F172A" stroke="#0F172A"
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        ))
    )
}