import React, { useEffect, useState } from "react";
import "../Pages/styles/prospects.css";
import MainButton from "../Components/CustomComponents/MainButton";
import { useHistory, useLocation } from "react-router-dom";
import { handleCurrentToken } from "../Constants/handleCurrentToken";
import API, { baseURL } from "../api/API";
import { useDispatch, useSelector } from "react-redux";
import { useGetLastChartHash } from "../Constants/useGetLastChartHash";
import SortingHeader from "../Components/SortingHeader";
import OutsideClickHandler from "../Constants/OutsideClickHandler";
import { Loader } from "../Components/Loader";
import PaginationWrapper from "../Components/PaginationWrapper";
import { setEditProspectData } from "../main-store/auth/actions";
import ModalWindow from "../Components/ModalWindow";
import { bodyOverflow } from "../Constants/bodyOverflow";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";
import useBottomPopup from "../Constants/useBottomPopup";
import StatisticModal from "../Components/StatisticModal";
import plus from "../assets/img/plus.svg";
import UploadContentPopUp from "../Components/UploadContentPopUp/UploadContentPopUp";

const topData = [
  {
    text: "revenue_potential",
    how: "#EDFCF5",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M24.7391 11.6667C24.4488 10.8431 23.9187 10.1252 23.217 9.60536C22.5153 9.08557 21.674 8.78767 20.8016 8.75H14.9683C13.8079 8.75 12.6951 9.21094 11.8747 10.0314C11.0542 10.8519 10.5933 11.9647 10.5933 13.125C10.5933 14.2853 11.0542 15.3981 11.8747 16.2186C12.6951 17.0391 13.8079 17.5 14.9683 17.5H20.8016C21.9619 17.5 23.0747 17.9609 23.8952 18.7814C24.7157 19.6019 25.1766 20.7147 25.1766 21.875C25.1766 23.0353 24.7157 24.1481 23.8952 24.9686C23.0747 25.7891 21.9619 26.25 20.8016 26.25H14.9683C14.0958 26.2123 13.2546 25.9144 12.5529 25.3946C11.8511 24.8748 11.321 24.1569 11.0308 23.3333'
          stroke='#4BDE97'
          strokeWidth='2.15385'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8848 26.25V30.625M17.8848 4.375V8.75V4.375Z'
          stroke='#4BDE97'
          strokeWidth='2.15385'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    text: "won_opportunities",
    how: "#FFF8ED",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17.8846 25.8853L8.88381 30.6176L10.6032 20.5944L3.31152 13.4967L13.374 12.0384L17.8744 2.91943L22.3749 12.0384L32.4374 13.4967L25.1457 20.5944L26.8651 30.6176L17.8846 25.8853Z'
          stroke='#FFB648'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    text: "contacts",
    how: "#FFF6E8",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17.8845 21.875C20.3008 21.875 22.2595 19.9162 22.2595 17.5C22.2595 15.0838 20.3008 13.125 17.8845 13.125C15.4683 13.125 13.5095 15.0838 13.5095 17.5C13.5095 19.9162 15.4683 21.875 17.8845 21.875Z'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8846 29.1668C24.3279 29.1668 29.5512 23.9435 29.5512 17.5002C29.5512 11.0568 24.3279 5.8335 17.8846 5.8335C11.4412 5.8335 6.2179 11.0568 6.2179 17.5002C6.2179 23.9435 11.4412 29.1668 17.8846 29.1668Z'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8845 2.9165V5.83317'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8845 29.1665V32.0832'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.5511 17.5H32.4678'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.30115 17.5H6.21781'
          stroke='#F57C1F'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    text: "engagements",
    how: "#EFEAFD",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.75977 29.1667L6.6556 23.4792C5.01708 21.0558 4.42434 18.186 4.98758 15.4034C5.55083 12.6207 7.23173 10.1145 9.71776 8.35083C12.2038 6.58712 15.3258 5.68591 18.5032 5.81474C21.6807 5.94357 24.6974 7.09369 26.9925 9.05125C29.2875 11.0088 30.7048 13.6406 30.9807 16.4573C31.2566 19.274 30.3724 22.0839 28.4925 24.3646C26.6126 26.6453 23.8649 28.2415 20.7603 28.8566C17.6557 29.4716 14.4055 29.0636 11.6139 27.7083L4.75977 29.1667'
          stroke='#5F2EEA'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    text: "opportunities",
    how: "#EAEDFA",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.75928 30.625H31.0093'
          stroke='var(--gitgo-blue)'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.67578 30.625V10.2083L19.3425 4.375V30.625'
          stroke='var(--gitgo-blue)'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.9995 30V15.2222L28.9995 11V30'
          stroke='var(--gitgo-blue)'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    text: "no_activity_prospects",
    how: "#FEEFEF",
    icon: (
      <svg
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17.8843 30.625C25.133 30.625 31.0093 24.7487 31.0093 17.5C31.0093 10.2513 25.133 4.375 17.8843 4.375C10.6355 4.375 4.75928 10.2513 4.75928 17.5C4.75928 24.7487 10.6355 30.625 17.8843 30.625Z'
          stroke='#F26464'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8843 11.6667V17.5'
          stroke='#F26464'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8843 23.3333H17.8989'
          stroke='#F26464'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
];

export default function Prospects() {
  const history = useHistory();
  const location = useLocation();
  const { activeHashUrl } = useGetLastChartHash();
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();

  const store = useSelector((state) => state.auth.user_data);
  const dispatch = useDispatch();
  const [prospectsData, setProspectsData] = useState(null);
  const [activeModal, setActiveModal] = useState({
    value: false,
    count: null,
  });
  const [openStats, setOpenStats] = useState(false);
  const [statisticData, setStatisticData] = useState({
    statistics: null,
    loading: false,
  });
  const [activeDropDown, setActiveDropDown] = useState(false);
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState({
    value: { request: "name", render: "Company" },
    sorting: true,
    options: [
      { request: "name", render: "Company" },
      { request: "owner", render: "Company owner" },
      { request: "industry", render: "Industry" },
      { request: "city", render: "City" },
      { request: "state", render: "State" },
      { request: "contacts", render: "Contacts" },
      { request: "total_revenue", render: "Revenue Potential" },
      // {request: 'travel_intent', render: 'Travel Intent',},
      { request: "last_engaged", render: "Last Engaged" },
    ],
  });
  const [perPage, setPerPage] = useState({
    data: {
      value: "10",
      options: ["10", "50", "100"],
      popup: false,
    },
  });
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState({
    page: null,
    pages: null,
    total: null,
  });

  useEffect(() => {
    if (activeHashUrl) {
      setActivePage((prevState) => ({
        ...prevState,
        page: activeHashUrl,
      }));
    }

    return () => null;
  }, [activeHashUrl]);

  useEffect(() => {
    if (store.role === "gitgo_admin_main") {
      history.push("/admin/workspaces");
    }
    return () => null;
  }, [history, store.role]);

  // useEffect(() => {
  //     API(handleCurrentToken()).get(`/dashboard/statistics`)
  //         .then(res => {
  //             // console.log(res)
  //             setStatisticData(prevState => ({
  //                 ...prevState,
  //                 loading: true,
  //                 statistics_data: [
  //                     {
  //                         ...prevState.statistics.revenue_potential,
  //                         total: res.data.statistics.revenue_potential.total,
  //                         percents: res.data.statistics.revenue_potential.percents,
  //                         symbol: res.data.statistics.revenue_potential.symbol,
  //                     },
  //                     {
  //                         ...prevState.statistics.won_opportunities,
  //                         total: res.data.statistics.won_opportunities.total,
  //                         percents: res.data.statistics.won_opportunities.percents,
  //                         symbol: res.data.statistics.won_opportunities.symbol,
  //                     },
  //                     {
  //                         ...prevState.statistics.prospects,
  //                         total: res.data.statistics.prospects.total,
  //                         percents: res.data.statistics.prospects.percents,
  //                         symbol: res.data.statistics.prospects.symbol,
  //                     }]
  //             }))
  //         })
  // }, [])
  useEffect(() => {
    API(handleCurrentToken())
      .get(`/dashboard/statistics`)
      .then((res) => {
        setStatisticData((prevState) => ({
          ...prevState,
          statistics: res.data.statistics,
          loading: true,
        }));
      });
  }, [location.search]);

  useEffect(() => {
    if (activePage.page) {
      API(handleCurrentToken())
        .get(
          `/prospects?order_by=${orderBy.value.request}&sorting=${
            orderBy.sorting ? "asc" : "desc"
          }&page=${activePage.page}&per_page=${perPage.data.value}`
        )
        .then((res) => {
          res.data.prospects.forEach((el) => {
            el.popup = false;
            el.checked = false;
          });
          setProspectsData(res.data.prospects);
          setActivePage((prevState) => ({
            ...prevState,
            pages: res.data.pages,
            total: res.data.total,
          }));
          setLoading(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [orderBy, activePage.page, perPage.data.value]);

  const fetchData = () => {
    if (activePage.page) {
      API(handleCurrentToken())
        .get(
          `/prospects?order_by=${orderBy.value.request}&sorting=${
            orderBy.sorting ? "asc" : "desc"
          }&page=${activePage.page}&per_page=${perPage.data.value}`
        )
        .then((res) => {
          res.data.prospects.forEach((el) => {
            el.popup = false;
            el.checked = false;
          });
          setProspectsData(res.data.prospects);
          setActivePage((prevState) => ({
            ...prevState,
            pages: res.data.pages,
            total: res.data.total,
          }));
          setLoading(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleDeleteProspects = () => {
    let checkedIdsArray = prospectsData
      .filter((el) => el.checked)
      .map((item) => item.id);
    let deleteData = checkedIdsArray
      .map((el, i) => `ids=${el}${i + 1 !== checkedIdsArray.length ? "&" : ""}`)
      .join("");
    if (checkedIdsArray.length) {
      API(handleCurrentToken())
        .delete(`/prospects?${deleteData}`)
        .then((res) => {
          if (res.status === 200) {
            changeContinuePopup("Prospects deleted");
            setTimeout(() => {
              changeContinuePopup("Prospects deleted");
            }, 2000);
            bodyOverflow(false);
            setActiveModal({
              value: false,
              count: null,
            });
            setProspectsData((prevState) =>
              prevState.filter((el) => !checkedIdsArray.includes(el.id))
            );
          }
        });
    }
  };

  // const travelIntentProspect = (el, i) => {
  //     API(handleCurrentToken()).post(`/prospects/travel_intent`, {
  //         id: el.id
  //     })
  //         .then(res => {
  //             if (res.status === 200) {
  //                 // console.log(res)
  //                 setProspectsData(prevState => [
  //                     ...prevState.slice(0, i),
  //                     {...el, travel_intent: !prevState[i].travel_intent},
  //                     ...prevState.slice(i + 1)
  //                 ])
  //             }
  //         })
  // }

  const uploadFile = () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      API(handleCurrentToken())
        .post(`/data/upload`, formData)
        .then((res) => {
          // toggleUploadPopup();
          setUploadIsOpen(!uploadIsOpen);
          changeContinuePopup(
            "The contacts from your CSV file have been added"
          );
          setTimeout(() => {
            changeContinuePopup(
              "The contacts from your CSV file have been added"
            );
          }, 2000);
          fetchData();
        })
        .catch((error) => {
          console.log("ERRROROR", error.response.data?.error);
          setError(error.response?.data?.error);
        });
    }
  };
  const exportProspectsIds = () => {
    window.open(
      baseURL +
        `/data/export?prospects_ids=${prospectsData
          .filter((el) => el.checked)
          .map((el) => el.id)
          .join()}&jwt=` +
        handleCurrentToken()
    );
  };
  console.log(statisticData);
  return [
    <ChangedConfirmedPopup
      successText={successText}
      key={10}
      changingConfirmed={changingConfirmed}
    />,
    uploadIsOpen && (
      <UploadContentPopUp
        handleClose={() => setUploadIsOpen(!uploadIsOpen)}
        error={error}
        onDrop={(file) => setFile(file)}
        uploadFile={uploadFile}
      />
    ),
    <div key={0} className={"prospects_wrapper"}>
      <div className={"title"}>
        <h2 className={"page_title"}>Companies</h2>
        <div
          className={`contacts-button-new ${activeDropDown ? "active" : ""}`}
        >
          <button
            style={activeDropDown ? { pointerEvents: "none" } : null}
            onClick={() => setActiveDropDown(true)}
          >
            <img alt={"add"} src={plus} />
            <span>New Company </span>
          </button>
          {activeDropDown && (
            <OutsideClickHandler callback={() => setActiveDropDown(false)}>
              <div className='contacts-button-dropdown-container'>
                <div className='contacts-button-dropdown'>
                  <button
                    onClick={() =>
                      history.push("/admin/companies/new_prospect")
                    }
                  >
                    <p>Create </p>
                  </button>
                  <button onClick={() => setUploadIsOpen(true)}>
                    <p>Upload </p>
                  </button>
                </div>
              </div>
            </OutsideClickHandler>
          )}
        </div>
        {/* <MainButton type={'button'}
                    svg={<svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 5V19" stroke="white" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round" />
                        <path d="M5.5 12H19.5" stroke="white" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round" />
                    </svg>}
                    className={'button_default_plus'}
                    text={'New prospects'}
                    onClick={() => history.push('/admin/prospects/new_prospect')} /> */}
      </div>
      <div className={"dashboard_total_blocks prospect"}>
        {statisticData.loading
          ? Object.entries(statisticData.statistics)
              .slice(0, 3)
              .map((el, i) => {
                let colorBlock = topData.find((i) => i.text === el[0]);
                return (
                  <div
                    onClick={() => {
                      setOpenStats(el[1]);
                    }}
                    key={i}
                    className={"total_stat_block"}
                  >
                    <div className={"left_block_side"}>
                      <span className={"value_total"}>
                        {el[0] === "revenue_potential" ? "$" : ""}
                        {el[1].total
                          ? el[1].total
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : 0}
                      </span>
                      <span className={"total_text"}>
                        Total {el[0].split("_").join(" ")}
                      </span>
                      <div className={"rating_of_month"}>
                        <svg
                          width='22'
                          height='22'
                          viewBox='0 0 22 22'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.00952 15.375L8.25952 10.125L11.7595 13.625L18.7595 6.625'
                            stroke={
                              el[1].symbol === "down"
                                ? "var(--error)"
                                : el[1].symbol === "up"
                                ? "#4BDE97"
                                : "#FFB648"
                            }
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12.6345 6.625H18.7595V12.75'
                            stroke={
                              el[1].symbol === "down"
                                ? "var(--error)"
                                : el[1].symbol === "up"
                                ? "#4BDE97"
                                : "#FFB648"
                            }
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <span
                          style={
                            el[1].symbol === "down"
                              ? { color: "var(--error)" }
                              : el[1].symbol === "up"
                              ? { color: "#4BDE97" }
                              : { color: "#FFB648" }
                          }
                          className={"procent_number"}
                        >
                          {el[1].percents}%
                        </span>
                        <span className={"period_text"}>Last month</span>
                      </div>
                    </div>
                    <div
                      style={{ background: colorBlock?.how }}
                      className={"ball_content"}
                    >
                      {topData.find((i) => i.text === el[0]).icon}
                    </div>
                    {openStats === el[1] ? (
                      <StatisticModal
                        colorBlock={colorBlock}
                        setOpenStats={(e) => setOpenStats(e)}
                        openStats={openStats}
                        el={el}
                        svg={topData.find((i) => i.text === el[0]).icon}
                      />
                    ) : null}
                  </div>
                );
              })
          : null}
      </div>
      <div
        style={{
          display: "grid",
          gridGap: "8px",
          marginBottom: "16px",
          gridTemplateColumns: "max-content 1fr",
          alignItems: "flex-start",
          height: "max-content",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, max-content)",
            justifySelf: "end",
            gridGap: "8px",
            justifyItems: "end",
          }}
        >
          {loading && prospectsData.some((el) => el.checked)
            ? [
                <MainButton
                  key={4}
                  type={"button"}
                  rightSvg={
                    <svg
                      width='27'
                      height='27'
                      viewBox='0 0 27 27'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6.11548 8.11572L7.1924 21.0388C7.1924 21.61 7.41932 22.1579 7.82325 22.5618C8.22717 22.9657 8.77501 23.1926 9.34625 23.1926H17.9616C18.5329 23.1926 19.0807 22.9657 19.4846 22.5618C19.8886 22.1579 20.1155 21.61 20.1155 21.0388L21.1924 8.11572'
                        fill='#FEEFEF'
                      />
                      <path
                        d='M6.11548 8.11572L7.1924 21.0388C7.1924 21.61 7.41932 22.1579 7.82325 22.5618C8.22717 22.9657 8.77501 23.1926 9.34625 23.1926H17.9616C18.5329 23.1926 19.0807 22.9657 19.4846 22.5618C19.8886 22.1579 20.1155 21.61 20.1155 21.0388L21.1924 8.11572'
                        stroke='#F26464'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M10.4231 8.1158V4.88503C10.4231 4.59941 10.5366 4.32549 10.7385 4.12353C10.9405 3.92157 11.2144 3.80811 11.5 3.80811H15.8077C16.0933 3.80811 16.3672 3.92157 16.5692 4.12353C16.7712 4.32549 16.8846 4.59941 16.8846 4.88503V8.1158'
                        fill='#FEEFEF'
                      />
                      <path
                        d='M10.4231 8.1158V4.88503C10.4231 4.59941 10.5366 4.32549 10.7385 4.12353C10.9405 3.92157 11.2144 3.80811 11.5 3.80811H15.8077C16.0933 3.80811 16.3672 3.92157 16.5692 4.12353C16.7712 4.32549 16.8846 4.59941 16.8846 4.88503V8.1158'
                        stroke='#F26464'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M5.03833 8.11572H22.2691'
                        stroke='#F26464'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M11.5 12.4233V18.8849'
                        stroke='#F26464'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M15.8076 12.4233V18.8849'
                        stroke='#F26464'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  }
                  className={"delete_button_trash"}
                  text={`Delete ${
                    prospectsData.filter((el) => el.checked).length
                  } compan${
                    prospectsData.filter((el) => el.checked).length > 1
                      ? "ies"
                      : "y"
                  }`}
                  style={{ height: "max-content", padding: "6px 12px" }}
                  onClick={() => {
                    bodyOverflow(true);
                    setActiveModal({
                      value: true,
                      count: prospectsData.filter((el) => el.checked).length,
                    });
                  }}
                />,
                <MainButton
                  key={1}
                  type={"button"}
                  style={{
                    backgroundColor: "transparent",
                    color: "#13426B",
                    borderColor: "#13426B",
                    borderRadius: 4,
                    height: "max-content",
                    padding: "6px 12px",
                  }}
                  svg={
                    <svg
                      width='26'
                      height='26'
                      viewBox='0 0 26 26'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.38477 18.3848V20.5386C4.38477 21.1098 4.61169 21.6577 5.01561 22.0616C5.41954 22.4655 5.96738 22.6925 6.53861 22.6925H19.4617C20.0329 22.6925 20.5808 22.4655 20.9847 22.0616C21.3886 21.6577 21.6155 21.1098 21.6155 20.5386V18.3848'
                        stroke='#13426B'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M7.61572 11.9231L13.0003 17.3077L18.385 11.9231'
                        stroke='#13426B'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M13.0005 4.38477V17.3078'
                        stroke='#13426B'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  }
                  onClick={() => exportProspectsIds()}
                  className={"button_default_plus contacts"}
                  text={`Export Data from ${
                    prospectsData.filter((el) => el.checked).length
                  } Compan${
                    prospectsData.filter((el) => el.checked).length > 1
                      ? "ies"
                      : "y"
                  }`}
                />,
              ]
            : null}
        </div>
      </div>
      <div className={"prospects_content"}>
        {loading ? (
          prospectsData.length ? (
            <div className={"prospects_container overflow_scrollbar"}>
              <div className={"head"}>
                <SortingHeader
                  orderBy={orderBy}
                  setOrderBy={(val) => setOrderBy(val)}
                />
              </div>
              <div className={"container_body"}>
                {prospectsData.map((el, i) => (
                  <div key={el.id} className={"item"}>
                    <div
                      onClick={() => {
                        setProspectsData((prevState) => [
                          ...prevState.slice(0, i),
                          { ...el, checked: !prevState[i].checked },
                          ...prevState.slice(i + 1),
                        ]);
                      }}
                      className={"choose_item_wrap"}
                    >
                      <div
                        className={`choose_item ${
                          el.checked ? "choose_item_active" : ""
                        }`}
                      >
                        <svg
                          width='8'
                          height='6'
                          viewBox='0 0 8 6'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1 3L3 5L7 1'
                            stroke='#478ECC'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    </div>
                    <p
                      onClick={() => {
                        dispatch(setEditProspectData(false));
                        history.push(`/admin/companies/${el.id}`);
                      }}
                    >
                      {el.name}
                    </p>
                    <span>{el.owner ? el.owner : "Not Specified"}</span>
                    <span>{el.industry ? el.industry : "Not Specified"}</span>
                    <span>{el.city ? el.city : "Not Specified"}</span>
                    <span>{el.state ? el.state : "Not Specified"}</span>
                    <span>{el.contacts}</span>
                    <span>
                      {el.total_revenue
                        ? "$" +
                          el.total_revenue
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "Not Specified"}
                    </span>
                    {/*<div onClick={() => travelIntentProspect(el, i)}*/}
                    {/*     className={'choose_item_wrap'}>*/}
                    {/*    <div*/}
                    {/*        className={`choose_item ${el.travel_intent ? 'choose_item_active' : ''}`}>*/}
                    {/*        <svg width="8" height="6" viewBox="0 0 8 6" fill="none"*/}
                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                    {/*            <path d="M1 3L3 5L7 1" stroke="#478ECC" strokeWidth="2"*/}
                    {/*                  strokeLinecap="round" strokeLinejoin="round"/>*/}
                    {/*        </svg>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <span>{el.last_engaged ?? "No activity yet"}</span>

                    <div className={"popup_wrap"}>
                      <svg
                        style={el.popup ? { pointerEvents: "none" } : null}
                        onClick={() => {
                          el.popup = true;
                          setProspectsData((prevState) => [
                            ...prevState.slice(0, i),
                            el,
                            ...prevState.slice(i + 1),
                          ]);
                        }}
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z'
                          stroke='#CBD5E1'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z'
                          stroke='#CBD5E1'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z'
                          stroke='#CBD5E1'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      {el.popup ? (
                        <OutsideClickHandler
                          callback={() => {
                            el.popup = false;
                            setProspectsData((prevState) => [
                              ...prevState.slice(0, i),
                              el,
                              ...prevState.slice(i + 1),
                            ]);
                          }}
                        >
                          <div className={"popup_content"}>
                            <span
                              onClick={() => {
                                dispatch(setEditProspectData(true));
                                history.push(`/admin/companies/${el.id}`);
                              }}
                            >
                              Edit
                            </span>
                            <span onClick={() => {}} className={"delete"}>
                              Delete
                            </span>
                          </div>
                        </OutsideClickHandler>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <span className={"no_items_style"}>No items</span>
          )
        ) : (
          <Loader
            color={{
              "--bgColor": "var(--gitgo-blue)",
              "--size": "40px",
              "--wrapper_size": "40px",
            }}
          />
        )}
      </div>

      <PaginationWrapper
        perPage={perPage}
        setPerPage={(e) => setPerPage(e)}
        activePage={activePage}
        loading={loading}
        setActivePage={(e) => setActivePage(e)}
      />
    </div>,
    activeModal.value ? (
      <ModalWindow
        key={2}
        nestedObj
        setActiveModal={(val) => setActiveModal(val)}
        deleteModal
      >
        <div className={"delete_modal"}>
          <span>Delete</span>
          <h3>
            Are you sure you want to delete this {activeModal.count} Compan
            {activeModal.count > 1 ? "ies" : "y"}?
          </h3>
          <p>This action cannot be restored</p>
          <div className={"modal_window_buttons"}>
            <MainButton
              type={"button"}
              className={"cancel_button"}
              text={"Cancel"}
              onClick={() => {
                setActiveModal({
                  value: false,
                  count: null,
                });
                bodyOverflow(false);
              }}
            />
            <MainButton
              type={"button"}
              className={"delete_button"}
              text={"Delete"}
              onClick={() => handleDeleteProspects()}
            />
          </div>
        </div>
      </ModalWindow>
    ) : null,
  ];
}
