import React, { useEffect, useState } from "react";
import "./styles/opportunity_details.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import MainButton from "../Components/CustomComponents/MainButton";
import OpportunityInitial from "../Components/Prospects/OpportunityInitial";
import { useDispatch, useSelector } from "react-redux";
import { setProspectData } from "../main-store/auth/actions";
import { handleCurrentToken } from "../Constants/handleCurrentToken";
import API from "../api/API";
import { Loader } from "../Components/Loader";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";
import useBottomPopup from "../Constants/useBottomPopup";
import moment from "moment";
import ModalWindow from "../Components/ModalWindow";
import { bodyOverflow } from "../Constants/bodyOverflow";
import TextBox from "../Components/CustomComponents/TextBox";

const initialState = {
  name: {
    title: "Opportunity Name",
    value: "",
    error: false,
    errorMessage: "Fill this label",
    full: true,
  },
  primary_contact: {
    title: "Primary Contact",
    value: "",
    id: "",
    monoNestedOptions: true,
    error: false,
    options: [],
    popup: false,
    errorMessage: "Fill this label",
  },
  status: {
    title: "Travel Opportunity Status",
    value: "",
    error: false,
    options: ["New Opportunity", "Proposal", "Tentative", "Closed Won", "Lost"],
    popup: false,
    errorMessage: "Fill this label",
  },
  decision_date: {
    title: "Decision Date",
    date: true,
    value: "",
    error: false,
    errorMessage: "Fill this label",
  },
  next_planned_day: {
    title: "Next Planned Day",
    date: true,
    value: "",
    error: false,
    popup: false,
  },
  date_of_sourcing: {
    title: "Date of Sourcing",
    date: true,
    value: "",
    error: false,
    errorMessage: "Fill this label",
  },
  engagement_requested: {
    title: "Engagement Requested",
    date: true,
    value: "",
    error: false,
    errorMessage: "Fill this label",
  },
  potential_revenue: {
    title: "Potential Revenue",
    value: "",
    error: false,
    errorMessage: "Fill this label",
    type: "number",
  },
};

export default function OpportunityDetails() {
  const store = useSelector((state) => state.auth.prospect_data);
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();
  const [notes, setNotes] = useState("");
  const [editMode, setEditMode] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [editOpportunityInfo, setEditOpportunityInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [basicInfo, setBasicInfo] = useState(() => initialState);
  // const [switchData, setSwitchData] = useState(() => choseState);

  useEffect(() => {
    let prospect = location.pathname.split("/")[3];
    let editMode = location.pathname.split("/")[4];
    if (!isNaN(Number(editMode)) && !isNaN(Number(prospect))) {
      setEditMode(editMode);
      Promise.all([
        API(handleCurrentToken()).get(
          `/prospects/opportunities/details?id=${editMode}`
        ),
        API(handleCurrentToken()).get(`/contacts`),
      ]).then((res) => {
        console.log("==============", res[1]);

        setNotes(res[0].data.opportunity.notes);
        setBasicInfo((prevState) => ({
          name: {
            ...prevState.name,
            value: res[0].data.opportunity.name ?? "",
          },
          primary_contact: {
            ...prevState.primary_contact,
            value: res[0].data.opportunity.primary_contact ?? "",
            options: res[1].data.contacts.map((item) => item),
          },
          status: {
            ...prevState.status,
            value: res[0].data.opportunity.status ?? "",
          },
          decision_date: {
            ...prevState.decision_date,
            value: res[0].data.opportunity.decision_date ?? "",
          },
          next_planned_day: {
            ...prevState.next_planned_day,
            value: res[0].data.opportunity.next_planned_day ?? "",
          },
          date_of_sourcing: {
            ...prevState.date_of_sourcing,
            value: res[0].data.opportunity.date_of_sourcing ?? "",
          },
          engagement_requested: {
            ...prevState.engagement_requested,
            value: res[0].data.opportunity.engagement_requested ?? "",
          },
          potential_revenue: {
            ...prevState.potential_revenue,
            value: res[0].data.opportunity.potential_revenue ?? "",
          },
        }));
        setLoading(true);
      });
    } else {
      history.push("/404");
    }
    return () => null;
  }, [location.pathname, history, editOpportunityInfo]);

  useEffect(() => {
    // if (!store?.id && !store?.name) {
    //   history.push("/404");
    //   console.log(22222);
    // }
    return () => {
      dispatch(
        setProspectData({
          id: "",
          name: "",
        })
      );
    };
  }, [dispatch, store, history]);

  const handleOpportunityInfo = (e) => {
    e.preventDefault();
    const {
      name,
      primary_contact,
      status,
      decision_date,
      potential_revenue,
      next_planned_day,
      date_of_sourcing,
      engagement_requested,
      notes,
    } = basicInfo;

    let condition =
      name.value &&
      status.value &&
      primary_contact.value &&
      decision_date.value &&
      next_planned_day.value &&
      date_of_sourcing.value &&
      engagement_requested.value &&
      potential_revenue.value;

    let formData = new FormData();

    console.log("-----------------------", condition);
    if (condition) {
      formData.append("id", +editMode);
      formData.append("prospect_id", +store.id);
      formData.append("name", name.value);
      formData.append("primary_contact", primary_contact.prospect_id);
      formData.append("status", status.value);
      formData.append(
        "decision_date",
        moment(decision_date.value, "MMM DD YYYY").format("DD/MM/YYYY")
      );
      formData.append(
        "next_planned_day",
        moment(next_planned_day.value, "MMM DD YYYY").format("DD/MM/YYYY")
      );
      formData.append(
        "date_of_sourcing",
        moment(date_of_sourcing.value, "MMM DD YYYY").format("DD/MM/YYYY")
      );
      formData.append(
        "engagement_requested",
        moment(engagement_requested.value, "MMM DD YYYY").format("DD/MM/YYYY")
      );
      formData.append("potential_revenue", potential_revenue.value);
      formData.append("notes", notes);
      console.log("++++++++++", formData);
      for (let key in formData.entries()) {
        console.log(key);
      }
      API(handleCurrentToken())
        .post(
          `/prospects/opportunities/details`,
          formData
          //       {id: +editMode,
          //       prospect_id: +store.id,
          //       name: name.value,
          //       primary_contact: primary_contact.value,
          //       status: status.value,
          //       decision_date: decision_date.value,
          //       next_planned_day: next_planned_day.value,
          //       date_of_sourcing: date_of_sourcing.value,
          //       engagement_requested: engagement_requested.value,
          //       potential_revenue: potential_revenue.value,
          //       notes: notes,
          // }
        )
        .then((res) => {
          changeContinuePopup("Opportunity edited");
          setEditOpportunityInfo(false);
          setTimeout(() => {
            changeContinuePopup("Opportunity edited");
          }, 2000);
        })
        .catch((error) => {
          console.log(11111, error);
        });
    } else {
      Object.entries(basicInfo).forEach((el) => {
        if (!el[1].value) {
          setBasicInfo((prevState) => ({
            ...prevState,
            [el[0]]: {
              ...prevState[el[0]],
              error: true,
              success: false,
              errorMessage: "Fill this label",
            },
          }));
        } else {
          setBasicInfo((prevState) => ({
            ...prevState,
            [el[0]]: {
              ...prevState[el[0]],
              success: true,
              error: false,
            },
          }));
        }
      });
    }
  };

  const deleteOpportunity = () => {
    API(handleCurrentToken())
      .delete(`/prospects/opportunities/details?id=${editMode}`)
      .then((res) => {
        if (res.status === 200) {
          changeContinuePopup("Opportunity deleted");
          setTimeout(() => {
            changeContinuePopup("Opportunity deleted");
          }, 2000);
          bodyOverflow(false);
          setActiveModal(false);
          history.push(`/admin/companies/${store.id}`);
        }
      })
      .catch((err) => {
        if (
          err.response.data.error === "there must be at least one opportunity"
        ) {
          alert("There must be at least one opportunity in prospect");
          bodyOverflow(false);
          setActiveModal(false);
        }
      });
  };
  console.log(basicInfo);
  return [
    <ChangedConfirmedPopup
      successText={successText}
      key={0}
      changingConfirmed={changingConfirmed}
    />,
    <div key={1} className={"new_opportunity_wrapper"}>
      <div className={"navigation"}>
        <Link to={"/admin/companies"}>Companies</Link>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M6 4L10 8L6 12'
            stroke='#475569'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <Link to={`/admin/companies/${store?.id}`}>{store?.name}</Link>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M6 4L10 8L6 12'
            stroke='#475569'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <span>Opportunity Details</span>
      </div>
      <div className={"title_wrap"}>
        <h2 className={"page_title"}>Opportunity Details</h2>
        <MainButton
          type={"button"}
          text={"Edit"}
          svg={
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4 19.9998H8L18.5 9.49981C19.0304 8.96938 19.3284 8.24996 19.3284 7.49981C19.3284 6.74967 19.0304 6.03025 18.5 5.49981C17.9696 4.96938 17.2501 4.67139 16.5 4.67139C15.7499 4.67139 15.0304 4.96938 14.5 5.49981L4 15.9998V19.9998Z'
                stroke='var(--gitgo-blue)'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M13.5 6.5L17.5 10.5'
                stroke='var(--gitgo-blue)'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
          className={"edit_button"}
          onClick={() => setEditOpportunityInfo(true)}
        />
      </div>
      <div className={"content content-custom"}>
        {editMode && editOpportunityInfo ? (
          <form
            onSubmit={(e) => handleOpportunityInfo(e)}
            className={"basic_info"}
          >
            <div
              style={
                !loading
                  ? {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "400px",
                    }
                  : null
              }
              className={"inputs"}
            >
              {loading ? (
                <OpportunityInitial
                  data={basicInfo}
                  setData={(e) => setBasicInfo(e)}
                />
              ) : (
                <Loader
                  color={{
                    "--bgColor": "var(--gitgo-blue)",
                    "--size": "40px",
                    "--wrapper_size": "40px",
                  }}
                />
              )}
            </div>
            <div style={{ marginBottom: 20 }}>
              <span className='notes-label'>Notes</span>
              <TextBox
                height={112}
                rows={8}
                type={"text"}
                value={notes}
                onChange={({ target }) => setNotes(target.value)}
              />
            </div>
            <div className={"buttons_wrapper"}>
              <MainButton
                type={"button"}
                text={"Cancel"}
                className={"cancel_button"}
                onClick={() => setEditOpportunityInfo(false)}
              />
              <MainButton
                type={"submit"}
                text={"Save"}
                className={"submit_button"}
              />
            </div>
          </form>
        ) : (
          <>
            <div
              className={"basic_info edit_basic_info"}
              style={{ border: "none", margin: 0 }}
            >
              {Object.entries(basicInfo).map((el) => {
                return (
                  <div
                    className={`non_editable ${el[1].full ? "full_width" : ""}`}
                    key={el[1].title}
                  >
                    <h6>{el[1].title}</h6>
                    <span>
                      {el[0] === "potential_revenue" ? "$" : ""}
                      {el[1].date
                        ? moment(el[1].value, "DD/MM/YYYY").format(
                            "MMM DD YYYY"
                          )
                        : el[1].value.toString().includes("_")
                        ? el[1].value.split("_").join(" ")
                        : el[1].value instanceof Object
                        ? el[1].value.name
                        : el[1].value
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className='notes-custom'>
              <h6 className='notes-style'>Notes</h6>
              <span style={{ wordBreak: "break-all" }}>{notes} </span>
            </div>
          </>
        )}

        {/* 
                <form onSubmit={e => handleOpportunityAdditional(e)} className={'additional_info'}>
                    <SwitchBlock disable={editMode && !editOpportunityInfo}
                                 data={switchData}
                                 setData={e => setSwitchData(e)} unsetMargin/>
                    {editMode && editOpportunityInfo ?
                        <div className={'buttons_wrapper'}>
                            <MainButton type={'button'}
                                        text={'Cancel'}
                                        className={'cancel_button'}
                                        onClick={() => setEditOpportunityInfo(false)}/>
                            <MainButton type={'submit'}
                                        text={'Save'}
                                        className={'submit_button'}/>
                        </div> : null}
                </form> */}
      </div>
      <div
        onClick={() => {
          bodyOverflow(true);
          setActiveModal(true);
        }}
        className={"delete_travel_opportunity"}
      >
        <h4>Delete Travel Opportunity</h4>
      </div>
    </div>,
    activeModal ? (
      <ModalWindow
        key={3}
        setActiveModal={(val) => setActiveModal(val)}
        deleteModal
      >
        <div className={"delete_modal"}>
          <span>Delete</span>
          <h3>Are you sure you want to delete this Opportunity?</h3>
          <p>This action cannot be restored</p>
          <div className={"modal_window_buttons"}>
            <MainButton
              type={"button"}
              className={"cancel_button"}
              text={"Cancel"}
              onClick={() => {
                setActiveModal(null);
                bodyOverflow(false);
              }}
            />
            <MainButton
              type={"button"}
              className={"delete_button"}
              text={"Delete"}
              onClick={() => deleteOpportunity()}
            />
          </div>
        </div>
      </ModalWindow>
    ) : null,
  ];
}
