import React from "react";
import "./styles/services.css";

let blockData = {
  blocks: {
    fifthBlock: {
      background: "#01426a",
      color: "white",
      subText: "Subtitle Text",
      title_text: "Strategy Wizard",
      text: "Reach out to the right people, at the right business, at the right time. Tap into our hotel specific data sources to capture the best business contacts and leads for your hotels.",
      button: "Learn More",
    },
    firstBlock: {
      background: "#daa520",
      color: "white",
      subText: "Subtitle Text",
      title_text: "Marketwork",
      text: "Perfect for hotels with sales teams who want to upgrade selling with cutting edge marketing automation. From copywriting to creative development we’ll create automated email campaigns specific to your hotels.",
      button: "Learn More",
    },
    secBlock: {
      background: "#97999b",
      color: "white",
      subText: "Subtitle Text",
      title_text: "Masterwork",
      text: "Unlock the power of outreach with Masterwork. We combine outbound sales prospecting, automated email campaigns, and inbound sales qualification to grow hotel revenues fast. From RFP’s to hunting down new business, the GitGo Outreach Team does it all.",
      button: "Learn More",
    },
    sixthBlock: {
      background: "#01426a",
      color: "white",
      subText: "Subtitle Text",
      title_text: (
        <span>
          Prospecting <br /> Success
        </span>
      ),
      text: "Prospecting isn’t a bad word. It’s just a poorly taught skill. This course teaches: how to engage customers with confidence, breaking down silos, using data not hype, proven practices vs. legacy ideals, and delivers clear/concise process maps for the most difficult parts of engaging customers.",
      button: "Learn More",
    },
    thirdBlock: {
      background: "#daa520",
      color: "white",
      subText: "Subtitle Text",
      title_text: "Foundational Sales Training - Light",
      text: "Need to train a seller quickly? Light training covers what many sellers never do correctly – the basics. The modules include Hotel Sales Onboarding, Sales Process Deep-Dive, Boolean Search, Qualifying, Value Props, Benefits, Overcoming Objections, and Closing. Just in time – right on target.",
      button: "Learn More",
    },
    forthBlock: {
      background: "#97999b",
      color: "white",
      subText: "Subtitle Text",
      title_text: "Foundational Sales Training - Full",
      text: "Need the kitchen sink? Set up your seller and forecast for consistent success! Foundational Sales Course is 50+ modules of hospitality-specific sales training and quizzes designed to make your mentee a mentor in no time.  Built for the serious seller to deliver serious results.",
      button: "Learn More",
    },
  },
};

// const secondBlockData = {
//     blocks: {
//         firstBlock: {
//             background: "#FEEFEF",
//             color: "var(--gitgo-blue)",
//             subText: "Light",
//             title_text: "Foundational Sales Training",
//             text: "Need to train a seller quickly? Light training covers what many sellers never do correctly – the basics. The modules include Hotel Sales Onboarding, Sales Process Deep-Dive, Boolean Search, Qualifying, Value Props, Benefits, Overcoming Objections, and Closing. Just in time – right on target.",
//             button: "Learn More"
//         },
//         secondBlock: {
//             background: '#FEEFEF',
//             color: "var(--gitgo-blue)",
//             subText: "Full",
//             title_text: "Foundational Sales Training",
//             text: "Need the kitchen sink? Set up your seller and forecast for consistent success! Foundational Sales Course is 50+ modules of hospitality-specific sales training and quizzes designed to make your mentee a mentor in no time.  Built for the serious seller to deliver serious results.",
//             button: "Learn More"
//         },
//     }
// }

export default function Services() {
  return (
    <div className={"services_wrapper"}>
      <div className={"services_title"}>
        <h2 className={"page_title"}>Services</h2>
        <span>Supercharge Your Prospecting.</span>
      </div>
      <div className={"title_of_blocks_header"}>
        <span className={"title_of_blocks"}>
          Accelerate Your Path To Revenue & Partner With The Best
          Hotel-to-Business Outreach Team
        </span>
      </div>
      <div className={"block_wrapper"}>
        {Object.entries(blockData.blocks).map((item, index) => {
          return (
            <div className={"text_block"} key={index}>
              <div>
                <div
                  style={{ background: item[1].background }}
                  className={"color_header_of_block"}
                >
                  <h2
                    style={{
                      color: item[1].color,
                      fontSize: 28,
                      fontWeight: "700",
                    }}
                  >
                    {item[1].title_text}
                  </h2>
                </div>
                <div className={"content_of_block"}>
                  <span>{item[1].text}</span>
                </div>
              </div>
              <div>
                <button
                  className={"block_button"}
                  onClick={() => {
                    item[1].title_text === "Marketwork"
                      ? window.open(
                          `https://gitgogroup.com/programs/gitgo-works/gitgo-marketwork/`
                        )
                      : item[1].title_text === "Masterwork"
                      ? window.open(
                          `https://gitgogroup.com/programs/gitgo-works/#masterwork`
                        )
                      : window.open(
                          `https://gitgogroup.com/programs/gitgo-works/`
                        );
                  }}
                  style={{ backgroundColor: item[1].background }}
                >
                  {item[1].button}
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {/* <div className={"block_wrapper"} style={{gridTemplateColumns: '1fr 1fr', gridGap: 64}}>
                {Object.entries(secondBlockData.blocks).map((item, index) => {
                    return (
                        <div className={"text_block"} style={{maxWidth: '100%'}} key={index}>
                            <div>
                                <div style={{ background: item[1].background }} className={"color_header_of_block"}>
                                    <h2 style={{ color: item[1].color, fontSize: 24, fontWeight: '700' }}>{item[1].title_text} - <span style={{color: '#F57C1F'}}>{item[1].subText}</span></h2>
                                </div>
                                <div className={"content_of_block"}>
                                    <span>{item[1].text}</span>
                                </div>
                            </div>
                            <div>
                                <button className={"block_button"}>
                                    {item[1].button}
                                </button>
                            </div>
                        </div>
                    )
                })
                }
            </div> */}
    </div>
  );
}
