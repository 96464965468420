import React from "react";
import Dashboard from "../Pages/Dashboard";
import Prospects from "../Pages/Prospects";
import CreateWork from "../Pages/CreateWork";
import NewProspect from "../Pages/NewProspect";
import Workspaces from "../Pages/Workspaces";
import Targeting from "../Pages/Targeting";
import Contact from "../Pages/Contacts/Contacts";
import Services from "../Pages/Services";
import CreateContact from "../Pages/Contacts/CreateContact";
import CreateAccount from "../Pages/CreateAccount";
import ProfileSettings from "../Pages/Profile/ProfileSettings";
import ManageSeats from "../Pages/Profile/ManageSeats";
import PageNotFound from "../Pages/404";
import OpportunityDetails from "../Pages/OpportunityDetails";
import Opportunities from "../Pages/Opportunities";
import ContactsUserInfo from "../Pages/Contacts/ContactsUserInfo";
import EditManageSeats from "../Pages/Profile/EditManageSeats";
import services_logo from "../assets/Sidebar/services.png";
import targeting_logo from "../assets/Sidebar/targeting.png";
import contacts_logo from "../assets/Sidebar/contacts.png";
import prospects_logo from "../assets/Sidebar/prospects.png";
import dashboard_logo from "../assets/Sidebar/dashboard.png";
import workspace_logo from "../assets/Sidebar/workspace.png";

export const Navigation = {
  mainNavigation: [
    {
      exact: true,
      label: "Dashboard",
      path: "/admin/dashboard",
      pathname: "dashboard",
      user: ["gitgo_admin", "superadmin", "manager"],
      main: () => <Dashboard />,
      icon: <img src={dashboard_logo} alt={"dashboard"} />,
    },
    {
      exact: true,
      label: "Companies",
      path: "/admin/companies",
      user: ["gitgo_admin", "superadmin", "manager"],
      pathname: "companies",
      main: () => <Prospects />,
      icon: <img src={prospects_logo} alt={"prospects"} />,
    },
    {
      exact: true,
      label: "Contacts",
      path: "/admin/contacts",
      user: ["gitgo_admin", "superadmin", "manager"],
      pathname: "contacts",
      main: () => <Contact />,
      icon: <img src={contacts_logo} alt={"contact"} />,
    },
    {
      exact: true,
      label: "Refuel Your Contacts",
      // path: "/admin/targeting",
      path: "https://gitgo.spiffy.co/checkout/groundwork-refuel",
      pathname: "targeting",
      user: ["gitgo_admin", "superadmin", "manager"],
      main: () => <Targeting />,
      icon: <img src={targeting_logo} alt={"targeting"} />,
    },
    {
      exact: true,
      label: "Services",
      // path: "/admin/services",
      path: "https://gitgogroup.com/programs/gitgo-services-for-groundwork-users/",
      pathname: "services",
      user: ["gitgo_admin", "superadmin", "manager"],
      main: () => <Services />,
      icon: <img src={services_logo} alt={"services"} />,
    },
    {
      exact: true,
      label: "Workspaces",
      user: ["gitgo_admin_main"],
      path: "/admin/workspaces",
      pathname: "workspaces",
      main: () => <Workspaces />,
      icon: <img src={workspace_logo} alt={"workspaces"} />,
    },
    {
      exact: true,
      path: "/admin/workspaces/:id",
      pathname: "workspaces",
      user: ["gitgo_admin_main"],
      main: () => <CreateWork />,
    },
    {
      exact: true,
      path: "/admin/companies/:id",
      pathname: "companies",
      user: ["gitgo_admin", "superadmin", "manager"],
      main: () => <NewProspect />,
    },
    {
      exact: true,
      path: "/admin/companies/:id/:id",
      pathname: "companies",
      user: ["gitgo_admin", "superadmin", "manager"],
      main: () => <OpportunityDetails />,
    },
    {
      exact: true,
      path: "/admin/opportunities",
      pathname: "opportunities",
      user: ["gitgo_admin", "superadmin", "manager"],
      main: () => <Opportunities />,
    },
    {
      exact: true,
      path: "/admin/profile_settings",
      pathname: "profile_settings",
      user: ["gitgo_admin", "superadmin", "manager"],
      main: () => <ProfileSettings />,
    },
    {
      exact: true,
      path: "/admin/manage_seats",
      pathname: "manage_seats",
      user: ["gitgo_admin", "superadmin", "manager"],
      main: () => <ManageSeats />,
    },
    {
      exact: true,
      path: "/admin/contacts/create_contact/:id",
      pathname: "create_contact",
      user: ["gitgo_admin", "superadmin", "manager"],
      main: () => <CreateContact />,
    },
    {
      exact: true,
      path: "/admin/contacts/contact_info/:id",
      pathname: "contact_info",
      user: ["gitgo_admin", "superadmin", "manager"],
      main: () => <ContactsUserInfo />,
    },
    {
      exact: true,
      path: "/admin/manage_seats/edit_manager/:id",
      pathname: "contact_info",
      user: ["gitgo_admin", "superadmin"],
      main: () => <EditManageSeats />,
    },
  ],
  mainProjectNavigation: [
    {
      exact: true,
      path: "/create_account",
      pathname: "create_account",
      main: () => <CreateAccount />,
    },
    {
      exact: true,
      path: "/404",
      main: () => <PageNotFound />,
    },
  ],
};
