import React from "react";
import './AssignPopUp.css'
import CustomSelect from "../CustomSelect";
import API from "../../api/API";
import {handleCurrentToken} from "../../Constants/handleCurrentToken";
import OutsideClickHandler from "../../Constants/OutsideClickHandler";

const AssignPopUp = (props) => {

    const {
        assignPopupData,
        setAssignPopupData,
        assignData,
        changeContinuePopup,
        setAssignIsOpen,
        setContactsData
    } = props;

    const assignProspect = (e) => {
        e.preventDefault();

        const {contact_prospects} = assignPopupData;
        if (contact_prospects.value && contact_prospects.prospect_id) {
            API(handleCurrentToken()).post(`/contacts/assign_prospect`, {
                contact_id: assignData.data.id,
                prospect_id: contact_prospects.prospect_id ? +contact_prospects.prospect_id : null
            })
                .then(res => {
                    //console.(res)
                    if (res.status === 200) {
                        changeContinuePopup('Prospect assigned')
                        setAssignIsOpen({
                            value: false,
                            index: null,
                            data: null,
                        })
                        setContactsData(prevState => [
                            ...prevState.slice(0, assignData.index),
                            {
                                ...assignData.data, prospect: {
                                    name: assignPopupData.contact_prospects.value.name,
                                    id: assignPopupData.contact_prospects.value.id
                                }, popup: false,
                            },
                            ...prevState.slice(assignData.index + 1)
                        ])
                        setTimeout(() => {
                            changeContinuePopup('Prospect assigned')
                        }, 2000)
                    }
                })
        } else {
            setAssignPopupData(prevState => ({
                ...prevState,
                contact_prospects: {
                    ...prevState.contact_prospects,
                    error: true,
                }
            }))
        }
    }

    return (
        <div className="popup-box">
            <OutsideClickHandler callback={() => {
                setAssignIsOpen({
                    value: false,
                    data: null,
                    index: null,
                })
            }}>
                <form onSubmit={(e) => assignProspect(e)} className="box">
                    <div className={'assign-prospect-name-content'}>
                        <div className={'assign-prospect-name-container'}>
                            <p className='assign-prospect-text'>Assign</p>
                            <p className='assign-item-name'>{assignData.data.name}</p>
                        </div>
                        <p className='assign-prospect-text'>to the Prospect</p>
                        </div>
                    <div className={'assign-custom-select'}>
                        <CustomSelect title={'Prospect Name'}
                                      loading={assignPopupData.contact_prospects.loading}
                                      options={assignPopupData.contact_prospects.options}
                                      monoNestedOptions={assignPopupData.contact_prospects.monoNestedOptions}
                                      unassignedValue
                                      popupMaxHeight
                                      optionalLabel={'contact_prospects'}
                                      prospect_id={'prospect_id'}
                                      popup={assignPopupData.contact_prospects.popup}
                                      setData={e => setAssignPopupData(e)}
                                      error={assignPopupData.contact_prospects.error}
                                      errTitle={assignPopupData.contact_prospects.errorMessage}
                                      value={assignPopupData.contact_prospects.value.name}/>
                    </div>
                    <div className='assign-popup-button-container'>
                        <button type={'button'} onClick={() => {
                            setAssignIsOpen({
                                value: false,
                                data: null,
                                index: null,
                            })
                        }}>Close
                        </button>
                        <button
                            type={"submit"}
                            className={'create-button'}>
                            {'Confirm'}
                        </button>
                    </div>
                </form>
            </OutsideClickHandler>
        </div>
    )
}

export default AssignPopUp