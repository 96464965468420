import React from 'react';
import ProviderAuth from './ProviderAuth';
import {Switch, Redirect, BrowserRouter as Router, Route} from 'react-router-dom'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import '../global_styles.css';
import ScrollToTop from "../Constants/ScrollToTop";
import PageNotFound from "../Pages/404";
import PrivateRoute from "./PrivateRouter";
import LoginSignup from "../Pages/LoginSignup";
import GitGoAdminLogin from "../Pages/GitGoAdminLogin";
import PrivateRouteProject from "./PrivateRouteProject";
import ForgotPassword from "../Pages/ForgotPassword";
import PasswordRecovery from "../Pages/PasswordRecovery";

const MainRouter = () => (
    <ProviderAuth>
        <Router>
            <ScrollToTop/>
            <Switch>

                <Route exact path='/' component={LoginSignup}/>
                <Route exact path='/gitgo_admin' component={GitGoAdminLogin}/>
                <Route exact path='/forgot_password' component={ForgotPassword}/>
                <Route exact path='/password_recovery/jwt=:jwt' component={PasswordRecovery}/>
                <Route exact path='/jwt=:jwt' component={LoginSignup}/>
                <PrivateRouteProject path={'/create_account'}/>

                <PrivateRoute path='/admin/:slug'/>
                <PrivateRoute path='/admin/:slug/:id/:id?'/>

                <Route path="*">
                    <PageNotFound/>
                    <Redirect to={'/404'}/>
                </Route>
            </Switch>
        </Router>
    </ProviderAuth>
);

export default MainRouter;
