import React from "react";
import loginLogo from "../assets/img/gitGo_login_logo.png";
import TextInput from "./CustomComponents/TextInput";
import MainButton from "./CustomComponents/MainButton";
import {Link} from "react-router-dom";

export default function LoginWrapper(props) {

    const {loginStep, handleLoginSubmit, loginData, setLoginData, setLoginError, loginError} = props;

    return (
        <div className={'login_wrapper'}>
            <div className={'content'}>
                <div className={'head'}>
                    <img src={loginLogo} alt="logo"/>
                </div>
                <div className={`card_content ${loginStep === 'setup' ? 'card_content_setup' : null}`}>
                    <h2>{loginStep === 'setup' ? 'Set UP Password' : 'Login'}</h2>
                    {loginStep === 'setup' ? <p>Please set up new password</p> : null}
                    <form onSubmit={(e) => handleLoginSubmit(e)}>
                        {loginStep === 'login' ?
                            <TextInput value={loginData.email.value}
                                       type={'text'}
                                       loginInput
                                       title={'Email'}
                                       error={loginData.email.error}
                                       errTitle={loginData.email.errorMessage}
                                       onChange={({target}) => {
                                           setLoginData(prevState => ({
                                               ...prevState,
                                               email: {
                                                   ...prevState.email,
                                                   value: target.value,
                                                   error: false
                                               }
                                           }))
                                           setLoginError(null)
                                       }}/> : null}
                        <TextInput value={loginData.password.value}
                                   type={'password'}
                                   title={'Password'}
                                   showPass={true}
                                   loginInput
                                   error={loginData.password.error}
                                   errTitle={loginData.password.errorMessage}
                                   autoComplete={'on'}
                                   onChange={({target}) => {
                                       setLoginData(prevState => ({
                                           ...prevState,
                                           password: {
                                               ...prevState.password,
                                               value: target.value,
                                               error: false
                                           }
                                       }))
                                       setLoginError(null)
                                   }}/>
                        {loginStep === 'setup'
                            ? <TextInput value={loginData.repeat_password.value}
                                         type={'password'}
                                         title={'Confirm Password'}
                                         showPass={true}
                                         loginInput
                                         error={loginData.repeat_password.error}
                                         errTitle={loginData.repeat_password.errorMessage}
                                         autoComplete={'on'}
                                         onChange={({target}) => {
                                             setLoginData(prevState => ({
                                                 ...prevState,
                                                 repeat_password: {
                                                     ...prevState.repeat_password,
                                                     value: target.value,
                                                     error: false
                                                 }
                                             }))
                                             setLoginError(null)
                                         }}/> : null}
                        {loginError ? <p className={'login_error_format'}>{loginError}</p> : null}
                        <MainButton type={'submit'}
                                    text={loginStep === 'login' ? 'Log in' : 'Confirm'}
                                    onClick={() => {
                                    }}
                        />
                    </form>
                    {loginStep === 'login' ?
                        <Link className={'forgot_password'} to={'/forgot_password'}>Forgot password?</Link> : null}
                </div>
            </div>
        </div>
    )
}