import React, { useEffect, useState } from "react";
import "../Pages/styles/new_prospect.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import API, { baseURL } from "../api/API";
import RenderInput from "../Components/Prospects/RenderInput";
import SwitchBlock from "../Components/Prospects/SwitchBlock";
import MainButton from "../Components/CustomComponents/MainButton";
import OpportunityInitial from "../Components/Prospects/OpportunityInitial";
import ContactsPopup from "../Components/Prospects/ContactsPopup";
import { bodyOverflow } from "../Constants/bodyOverflow";
import { handleCurrentToken } from "../Constants/handleCurrentToken";
import { Loader } from "../Components/Loader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setProspectData } from "../main-store/auth/actions";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";
import useBottomPopup from "../Constants/useBottomPopup";
import ModalWindow from "../Components/ModalWindow";
import TextBox from "../Components/CustomComponents/TextBox";
import OutsideClickHandler from "../Constants/OutsideClickHandler";

const meetingIcon = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16 3V7'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 3V7'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4 11H20'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 15H8V17H10V15Z'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const mailIcon = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3 7L12 13L21 7'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const callIcon = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21C14.0993 20.763 10.4202 19.1065 7.65683 16.3432C4.8935 13.5798 3.23705 9.90074 3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4'
      stroke='#94A3B8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const firstState = {
  name: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Company Name",
    max_length: 100,
  },
  phone: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Phone",
    max_length: 30,
    type: "phone",
  },
  website: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Website",
    max_length: 100,
  },
  source: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Source",
    max_length: 100,
  },
  owner: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Company Owner",
    max_length: 100,
  },
};

const secondState = {
  // estimated_revenue_potential: {
  //     value: '',
  //     error: false,
  //     placeholder: '$50,000',
  //     errorMessage: 'Fill this label',
  //     title: 'Estimated Revenue Potential',
  //     type: 'number',
  // },
  company_annual_revenue: {
    value: "",
    error: false,
    title: "Company Annual Revenue",
    errorMessage: "Fill this label",
    type: "number",
  },
  industry: {
    value: "",
    error: false,
    options: [
      "Agriculture",
      "Apparel",
      "Architecture, Engineering & Design",
      "Banking",
      "Biotechnology",
      "Business Services",
      "Chemicals",
      "Commercial & Residential Construction",
      "Communication",
      "Construction",
      "Consulting",
      "Consumer Services",
      "Education",
      "Electronics",
      "Energy",
      "Energy, Utilities & Waste",
      "Engineering",
      "Entertainment",
      "Environment",
      "Finance",
      "Food & Beverage",
      "Freight & Logistics Services",
      "Government",
      "Healthcare",
      "Healthcare Services",
      "Holding Companies & Conglomerates",
      "Hospitality",
      "Hospitals & Physicians Clinics",
      "Insurance",
      "Law Firms & Legal Services",
      "Machinery",
      "Manufacturing",
      "Media",
      "Media & Internet",
      "Minerals & Mining",
      "Not for Profit",
      "Organizations",
      "Other",
      "Real Estate",
      "Recreation",
      "Retail",
      "Shipping",
      "Software",
      "Technology",
      "Telecommunications",
      "Transportation",
      "Utilities",
    ],
    popup: false,
    errorMessage: "Fill this label",
    title: "Industry",
    max_length: 100,
  },
  employees: {
    value: "",
    error: false,
    placeholder: "125",
    errorMessage: "Fill this label",
    title: "Employees",
    max_length: 100,
    type: "number",
  },
  //market_range: {
  //    value: '',
  //    options: ['111', '222', '333', '444', '555'],
  //    popup: false,
  //    error: false,
  //    errorMessage: 'Fill this label',
  //    title: 'Market Range',
  //},
};

const thirdState = {
  billing_address: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Billing Address",
    max_length: 100,
    full_line: true,
  },
  state: {
    value: "",
    error: false,
    options: [],
    popup: false,
    errorMessage: "Fill this label",
    title: "State",
    loading: false,
  },
  city: {
    value: "",
    error: false,
    placeholder: "",
    errorMessage: "Fill this label",
    title: "City",
    max_length: 30,
  },
  zip: {
    value: "",
    error: false,
    placeholder: "City",
    errorMessage: "Fill this label",
    title: "Zip",
    max_length: 30,
  },
};

const fourthState = {
  number_travelers: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Number of Travelers",
    max_length: 100,
    type: "number",
  },
  travel_policy: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Travel Policy",
    max_length: 100,
  },
  travel_reason: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Travel Reason",
    max_length: 100,
  },
};

const fifthState = {
  local_competitors: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Local Competitors",
    max_length: 100,
  },
  room_night_potential: {
    value: "",
    error: false,
    errorMessage: "Fill this label",
    title: "Room Night Potential",
    max_length: 100,
    type: "number",
  },
};

const choseState = {
  preferred_amenities: {
    title: "Preferred Amenities",
    available_options: [
      "Parking",
      "Direct Bill",
      "Breakfast",
      "Pool",
      "Kitchenette",
      "Microwave/Fridge",
      "Meeting Space",
      "Shuttle",
      "Laundry",
      "Fitness Center",
      "Business Center Restaurant",
    ],
    chosen_options: [],
    error: false,
    errorMessage: "Chose at least one option",
  },
  preferred_brands: {
    title: "Preferred Brands",
    available_options: [
      "Americas Best Value",
      "avid",
      "Baymont",
      "Best Western",
      "Clarion Inn",
      "Comfort Inn",
      "Comfort Suites",
      "Country Inn & Suites",
      "Courtyard",
      "Crowne Plaza",
      "Days Inn",
      "DoubleTree",
      "Econo Lodge",
      "Embassy Suites",
      "EVEN",
      "Extended Stay America",
      "Fairfield Inn",
      "Hampton Inn",
      "Hilton",
      "Hilton Garden Inn",
      "Holiday Inn",
      "Holiday Inn Express",
      "Home2 Suites",
      "Homewood Suites",
      "Hyatt",
      "Hyatt Place",
      "La Quinta",
      "Mainstay Suites",
      "Microtel",
      "Motel 6",
      "Quality Inn",
      "Radisson",
      "Ramada",
      "Red Lion",
      "Red Roof Inn",
      "Residence Inn",
      "Rodeway Inn",
      "Sleep Inn",
      "Staybridge Suites",
      "Studio 6",
      "Super 8",
      "Wingate",
      "WoodSpring Suites",
    ],
    chosen_options: [],
    error: false,
    errorMessage: "Chose at least one option",
  },
  preferred_chains: {
    title: "Preferred Chains",
    available_options: [
      "Best Western",
      "Choice",
      "Extended Stay America",
      "G6",
      "Hilton",
      "Hyatt",
      "IHG",
      "Marriott",
      "Radisson Hotel",
      "Red Lion Hotels",
      "Red Roof Inn",
      "Wyndham",
    ],
    chosen_options: [],
    error: false,
    errorMessage: "Chose at least one option",
  },
  brand_scale: {
    title: "Brand Scale",
    available_options: ["Economy", "Mid-Scale", "Up-Scale", "Luxury"],
    chosen_options: [],
    error: false,
    errorMessage: "Chose at least one option",
  },
};

const initialState = {
  open: false,
  opportunity_status: "new",
  edit: false,
  edit_view: false,
  id: null,
  name: {
    title: "Opportunity Name",
    value: "",
    error: false,
    errorMessage: "Fill this label",
    full: true,
  },
  primary_contact: {
    title: "Primary Contact",
    value: "",
    error: false,
    options: [],
    monoNestedOptions: true,
    popup: false,
    errorMessage: "Fill this label",
  },
  status: {
    title: "Travel Opportunity Status",
    value: "",
    error: false,
    options: ["New Opportunity", "Proposal", "Tentative", "Closed Won", "Lost"],
    popup: false,
    errorMessage: "Fill this label",
  },
  decision_date: {
    title: "Decision Date",
    date: true,
    value: "",
    error: false,
    errorMessage: "Fill this label",
  },
  next_planned_day: {
    title: "Next Planned Day",
    date: true,
    value: "",
    error: false,
    popup: false,
  },
  date_of_sourcing: {
    title: "Date of Sourcing",
    date: true,
    value: "",
    error: false,
    errorMessage: "Fill this label",
  },
  engagement_requested: {
    title: "Engagement Requested",
    date: true,
    value: "",
    error: false,
    errorMessage: "Fill this label",
  },
  potential_revenue: {
    title: "Potential Revenue",
    value: "",
    error: false,
    errorMessage: "Fill this label",
    type: "number",
  },
};

const opportunityState = {
  error: false,
  submit: false,
  errorMessage: "Should be at least one item",
  initial: initialState,
  opportunities: [],
};

export default function NewProspect() {
  const history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.auth.edit_prospect);
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const [notes, setNotes] = useState("");
  const [editMode, setEditMode] = useState(null);
  const [loading, setLoading] = useState(null);
  const [editProspectInfo, setEditProspectInfo] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [firstBlock, setFirstBlock] = useState(() => firstState);
  const [secondBlock, setSecondBlock] = useState(() => secondState);
  const [thirdBlock, setThirdBlock] = useState(() => thirdState);
  const [fourthBlock, setFourthBlock] = useState(() => fourthState);
  const [fifthBlock, setFifthBlock] = useState(() => fifthState);
  const [switchData, setSwitchData] = useState(() => choseState);
  const [opportunityData, setOpportunityData] = useState(
    () => opportunityState
  );
  const [prospectContacts, setProspectContacts] = useState({
    total: 0,
    contacts: [],
    loading: false,
    error: false,
    errorMessage: "Should be at least one item",
  });
  const [activeModal, setActiveModal] = useState({
    value: false,
    type: "",
    data: null,
  });

  useEffect(() => {
    setEditProspectInfo(store);
    return () => null;
  }, [store]);

  useEffect(() => {
    let editMode = location.pathname.split("/")[3];
    if (!isNaN(Number(editMode))) {
      setEditMode(editMode);
      Promise.all([
        API(handleCurrentToken()).get(
          `/prospects/contacts?prospect_id=${editMode}`
        ),
        API(handleCurrentToken()).get(`/prospects/details?id=${editMode}`),
        API().get(`/states`),
        API(handleCurrentToken()).get(
          `/prospects/opportunities?id=${editMode}`
        ),
      ])
        .then((res) => {
          let dataArray = (prevState) => {
            return res[3].data.opportunities.map((b) => {
              console.log("b.primary_contact.name", b.primary_contact);
              return {
                open: false,
                opportunity_status: "edit",
                edit_view: false,
                edit: true,
                id: b.id,
                name: {
                  ...prevState.initial.name,
                  value: b.name,
                },
                primary_contact: {
                  ...prevState.initial.primary_contact,
                  value: b.primary_contact?.name ?? "",
                  prospect_id: b.primary_contact?.id ?? "",
                },
                status: {
                  ...prevState.initial.status,
                  value: b.status,
                },
                decision_date: {
                  ...prevState.initial.decision_date,
                  value: b.decision_date,
                },
                next_planned_day: {
                  ...prevState.initial.next_planned_day,
                  value: b.next_planned_day,
                },
                date_of_sourcing: {
                  ...prevState.initial.date_of_sourcing,
                  value: b.date_of_sourcing,
                },
                engagement_requested: {
                  ...prevState.initial.engagement_requested,
                  value: b.engagement_requested,
                },
                potential_revenue: {
                  ...prevState.initial.potential_revenue,
                  value: b.potential_revenue,
                },
              };
            });
          };
          setOpportunityData((prevState) => {
            console.log("prevState", prevState);
            console.log(
              "res[3].data.opportunities.length",
              res[3].data.opportunities.length
            );
            console.log("dataArray(prevState)", dataArray(prevState));
            return {
              ...prevState,
              submit: !!res[3].data.opportunities.length,
              opportunities: res[3].data.opportunities.length
                ? dataArray(prevState)
                : [],
            };
          });
          setProspectContacts({
            ...res[0].data,
            loading: true,
            contacts: res[0].data.contacts.length
              ? res[0].data.contacts.reverse()
              : [],
          });

          setFirstBlock((prevState) => ({
            ...prevState,
            name: {
              ...prevState.name,
              value: res[1].data.name ?? "",
            },
            phone: {
              ...prevState.phone,
              value: res[1].data.phone ?? "",
            },
            website: {
              ...prevState.website,
              value: res[1].data.website ?? "",
            },
            owner: {
              ...prevState.owner,
              value: res[1].data.owner ?? "",
            },
            source: {
              ...prevState.source,
              value: res[1].data.source ?? "",
            },
          }));
          setSecondBlock((prevState) => ({
            ...prevState,
            company_annual_revenue: {
              ...prevState.company_annual_revenue,
              value: res[1].data.company_annual_revenue ?? "",
            },
            industry: {
              ...prevState.industry,
              value: res[1].data.industry ?? "",
            },
            employees: {
              ...prevState.employees,
              value: res[1].data.employees ?? "",
            },
          }));
          setThirdBlock((prevState) => ({
            ...prevState,
            billing_address: {
              ...prevState.billing_address,
              value: res[1].data.billing_address ?? "",
            },
            state: {
              ...prevState.state,
              value: res[1].data.state ?? "",
              options: res[2].data,
              loading: true,
            },
            zip: {
              ...prevState.zip,
              value: res[1].data.zip ?? "",
            },
            city: {
              ...prevState.city,
              value: res[1].data.city ?? "",
            },
          }));
          setFourthBlock((prevState) => ({
            ...prevState,
            number_travelers: {
              ...prevState.number_travelers,
              value: res[1].data.number_travelers ?? "",
            },
            travel_policy: {
              ...prevState.travel_policy,
              value: res[1].data.travel_policy ?? "",
            },
            travel_reason: {
              ...prevState.travel_reason,
              value: res[1].data.travel_reason ?? "",
            },
          }));
          setFifthBlock((prevState) => ({
            ...prevState,
            local_competitors: {
              ...prevState.local_competitors,
              value: res[1].data.local_competitors ?? "",
            },
            room_night_potential: {
              ...prevState.room_night_potential,
              value: res[1].data.room_night_potential ?? "",
            },
          }));
          setSwitchData((prevState) => ({
            ...prevState,
            preferred_brands: {
              ...prevState.preferred_brands,
              chosen_options: res[1].data.preferred_brands ?? [],
              available_options: prevState.preferred_brands.available_options
                .filter((x) => !new Set(res[1].data.preferred_brands).has(x))
                .sort(function (a, b) {
                  return a.localeCompare(b);
                }),
            },
            preferred_amenities: {
              ...prevState.preferred_amenities,
              chosen_options: res[1].data.preferred_amenities ?? [],
              available_options: prevState.preferred_amenities.available_options
                .filter((x) => !new Set(res[1].data.preferred_amenities).has(x))
                .sort(function (a, b) {
                  return a.localeCompare(b);
                }),
            },
            preferred_chains: {
              ...prevState.preferred_chains,
              available_options: prevState.preferred_chains.available_options
                .filter((x) => !new Set(res[1].data.preferred_chains).has(x))
                .sort(function (a, b) {
                  return a.localeCompare(b);
                }),
              chosen_options: res[1].data.preferred_chains ?? [],
            },
            brand_scale: {
              ...prevState.brand_scale,
              chosen_options: res[1].data.brand_scale ?? [],
              available_options: prevState.brand_scale.available_options
                .filter((x) => !new Set(res[1].data.brand_scale).has(x))
                .sort(function (a, b) {
                  return a.localeCompare(b);
                }),
            },
          }));
          setLoading(true);
        })
        .catch((err) => {
          if (err.response.data.error === "prospect does not exist") {
            history.push("/404");
          }
        });
    }
  }, [location.pathname, history]);

  useEffect(() => {
    setOpportunityData((prevState) => ({
      ...prevState,
      initial: {
        ...prevState.initial,
        primary_contact: {
          ...prevState.initial.primary_contact,
          options: [
            ...prevState.initial.primary_contact.options,
            ...prospectContacts.contacts,
          ],
        },
      },
      opportunities: prevState.opportunities.map((b) => {
        return {
          ...b,
          primary_contact: {
            ...b.primary_contact,
            options: [
              ...b.primary_contact.options,
              ...prospectContacts.contacts,
            ],
          },
        };
      }),
    }));
  }, [prospectContacts.contacts]);

  useEffect(() => {
    if (!editMode) {
      API()
        .get(`/states`)
        .then((res) => {
          // console.log(res)
          setThirdBlock((prevState) => ({
            ...prevState,
            state: {
              ...prevState.state,
              options: res.data,
              loading: true,
            },
          }));
        });
    }
  }, [editMode]);

  const addOpportunity = (el, i) => {
    if (editMode) {
      let condition =
        el.name.value &&
        el.primary_contact.value &&
        el.status.value &&
        el.potential_revenue.value;

      if (condition) {
        API(handleCurrentToken())
          .post(`/prospects/opportunities/details`, {
            id: el.id ? +el.id : null,
            prospect_id: +editMode,
            name: el.name.value.trim(),
            primary_contact: el.primary_contact.prospect_id,
            status: el.status.value,
            decision_date: moment(el.decision_date.value, "MMM DD YYYY").format(
              "DD/MM/YYYY"
            ),
            next_planned_day: moment(
              el.next_planned_day.value,
              "MMM DD YYYY"
            ).format("DD/MM/YYYY"),
            date_of_sourcing: moment(
              el.date_of_sourcing.value,
              "MMM DD YYYY"
            ).format("DD/MM/YYYY"),
            engagement_requested: moment(
              el.engagement_requested.value,
              "MMM DD YYYY"
            ).format("DD/MM/YYYY"),
            potential_revenue: el.potential_revenue.value,
            notes,
          })
          .then((res) => {
            setOpportunityData((prevState) => ({
              ...prevState,
              submit: true,
              opportunities: [
                ...prevState.opportunities.slice(0, i),
                {
                  ...el,
                  id: res.data.id ?? el.id ?? null,
                  prospect_id: +editMode,
                  open: true,
                  primary_contact: {
                    ...el.primary_contact,
                    options: [
                      ...el.primary_contact.options,
                      ...prospectContacts.contacts,
                    ],
                  },
                  opportunity_status: "edit",
                  edit_view: false,
                  edit: true,
                },
                ...prevState.opportunities.slice(i + 1),
              ],
            }));
            changeContinuePopup(
              el.id ? "Opportunity edited" : "Opportunity created"
            );
            setTimeout(() => {
              changeContinuePopup(
                el.id ? "Opportunity edited" : "Opportunity created"
              );
            }, 2000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const noValidateField = [
          "Opportunity Name",
          "Primary Contact",
          "Potential Revenue",
          "Travel Opportunity Status",
        ];

        Object.entries(el).forEach((a) => {
          a.forEach((d) => {
            if (d instanceof Object) {
              if (!d.value) {
                if (noValidateField.includes(d.title)) {
                  d.error = true;
                  setOpportunityData((prevState) => ({
                    ...prevState,
                    opportunities: [
                      ...prevState.opportunities.slice(0, i),
                      el,
                      ...prevState.opportunities.slice(i + 1),
                    ],
                  }));
                }
              }
            }
          });
        });
      }
    } else {
      Object.entries(el).forEach((a) => {
        a.forEach((d) => {
          if (d instanceof Object) {
            if (!d.value) {
              d.error = true;
              setOpportunityData((prevState) => ({
                ...prevState,
                opportunities: [
                  ...prevState.opportunities.slice(0, i),
                  el,
                  ...prevState.opportunities.slice(i + 1),
                ],
              }));
            } else {
              setOpportunityData((prevState) => ({
                ...prevState,
                submit: true,
                opportunities: [
                  ...prevState.opportunities.slice(0, i),
                  {
                    ...el,
                    open: false,
                    opportunity_status: "edit",
                    primary_contact: {
                      ...el.primary_contact,
                      options: [
                        ...el.primary_contact.options,
                        ...prospectContacts.contacts,
                      ],
                    },
                    edit_view: false,
                    edit: !!editMode,
                  },
                  ...prevState.opportunities.slice(i + 1),
                ],
              }));
              changeContinuePopup(
                el.id ? "Opportunity edited" : "Opportunity created"
              );
              setTimeout(() => {
                changeContinuePopup(
                  el.id ? "Opportunity edited" : "Opportunity created"
                );
              }, 2000);
            }
          }
        });
      });
    }
  };

  // const editProspectContacts = () => {
  //     let dataArray = [];
  //     prospectContacts.contacts.forEach(el => dataArray.push(el.id))
  //     if (dataArray.length) {
  //         API(handleCurrentToken()).post(`/prospects/contacts`, {
  //             contact_ids: dataArray,
  //             prospect_id: editMode,
  //         })
  //             .then(() => {
  //                 changeContinuePopup('Contacts edited')
  //                 setTimeout(() => {
  //                     changeContinuePopup('Contacts edited')
  //                 }, 2000)
  //             })
  //     } else {
  //         setProspectContacts(prevState => ({
  //             ...prevState,
  //             error: true,
  //         }))
  //     }
  // }

  const handleDeleteItem = () => {
    if (activeModal.data) {
      API(handleCurrentToken())
        .delete(`/contacts/details?id=${activeModal.data.id}`)
        .then((res) => {
          if (res.status === 200) {
            setProspectContacts((prevState) => ({
              ...prevState,
              contacts: prevState.contacts.filter(
                (item) => item.id !== activeModal.data.id
              ),
            }));
            changeContinuePopup(`Contact ${activeModal.data.name} deleted`);
            setTimeout(() => {
              changeContinuePopup(`Contact ${activeModal.data.name} deleted`);
            }, 2000);
            bodyOverflow(false);
            setActiveModal({
              value: false,
              type: "",
              data: null,
            });
          }
        });
    } else {
      API(handleCurrentToken())
        .delete(`/prospects`, {
          params: {
            ids: editMode,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            changeContinuePopup("Prospect deleted");
            setTimeout(() => {
              changeContinuePopup("Prospect deleted");
            }, 2000);
            bodyOverflow(false);
            setActiveModal({
              value: false,
              type: "",
              data: null,
            });
            history.push(`/admin/companies`);
          }
        });
    }
  };

  const handleSubmitError = (data, setState) => {
    Object.entries(data).forEach((el) => {
      if ("chosen_options" in el[1]) {
        if (!el[1].chosen_options.length) {
          setState((prevState) => ({
            ...prevState,
            [el[0]]: {
              ...prevState[el[0]],
              error: true,
            },
          }));
        }
      }
      if (!el[1].value) {
        if (el[0] === "name" || el[0] === "status") {
          setState((prevState) => ({
            ...prevState,
            [el[0]]: {
              ...prevState[el[0]],
              error: true,
            },
          }));
        }
        // setState(prevState => ({
        //     ...prevState,
        //     [el[0]]: {
        //         ...prevState[el[0]],
        //         error: true
        //     }
        // }))
      }
    });
  };
  console.log("opportunityData", opportunityData);
  const handleSubmitProspect = () => {
    const { name, phone, website, source, owner } = firstBlock;
    const { company_annual_revenue, industry, employees } = secondBlock;
    const { billing_address, state, zip, city } = thirdBlock;
    const { number_travelers, travel_policy, travel_reason } = fourthBlock;
    const { local_competitors, room_night_potential } = fifthBlock;
    const {
      preferred_brands,
      preferred_amenities,
      preferred_chains,
      brand_scale,
    } = switchData;

    const condition = name.value;

    if (condition) {
      let createPostData;
      if (!editMode) {
        // let opportunitiesArray = opportunityData.opportunities.map((b) => {
        //     return {
        //         name: b.name.value,
        //         primary_contact: b.primary_contact.value,
        //         status: b.status.value,
        //         decision_date: b.decision_date.value,
        //         next_planned_day: b.next_planned_day.value,
        //         date_of_sourcing: b.date_of_sourcing.value,
        //         engagement_requested: b.engagement_requested.value,
        //         potential_revenue: b.potential_revenue.value,
        //     }
        // })

        let prospectContactsArray = prospectContacts.contacts.map((b) => {
          return {
            name: b.name,
            email: b.email,
            job_title: b.job_title,
            phone: b.phone ?? "",
            status: "New",
            opportunities: opportunityData.opportunities
              .filter((c) => c.primary_contact.value.name === b.name)
              .map((d) => {
                return {
                  name: d.name.value,
                  primary_contact: d.primary_contact.id,
                  status: d.status.value,
                  decision_date: moment(
                    d.decision_date.value,
                    "MMM DD YYYY"
                  ).format("DD/MM/YYYY"),
                  next_planned_day: moment(
                    d.next_planned_day.value,
                    "MMM DD YYYY"
                  ).format("DD/MM/YYYY"),
                  date_of_sourcing: moment(
                    d.date_of_sourcing.value,
                    "MMM DD YYYY"
                  ).format("DD/MM/YYYY"),
                  engagement_requested: moment(
                    d.engagement_requested.value,
                    "MMM DD YYYY"
                  ).format("DD/MM/YYYY"),
                  potential_revenue: moment(
                    d.potential_revenue.value,
                    "MMM DD YYYY"
                  ).format("DD/MM/YYYY"),
                };
              }),
          };
        });
        createPostData = {
          prospect: {
            name: name.value.trim(),
            phone: phone.value.trim(),
            website: website.value.trim(),
            source: source.value.trim(),
            // estimated_revenue_potential: estimated_revenue_potential.value,
            company_annual_revenue: company_annual_revenue.value,
            industry: industry.value ?? "",
            employees: employees.value,
            billing_address: billing_address.value.trim(),
            state: state.value,
            zip: zip.value.trim(),
            owner: owner.value,
            city: city.value,
            number_travelers: number_travelers.value,
            travel_policy: travel_policy.value.trim(),
            travel_reason: travel_reason.value.trim(),
            local_competitors: local_competitors.value.trim(),
            room_night_potential: room_night_potential.value,
            preferred_brands: preferred_brands.chosen_options,
            preferred_amenities: preferred_amenities.chosen_options,
            preferred_chains: preferred_chains.chosen_options,
            brand_scale: brand_scale.chosen_options,
          },
          // opportunities: opportunitiesArray,
          contacts: prospectContactsArray,
        };
      }
      let editPostData = {
        prospect: {
          id: +editMode,
          name: name.value.trim(),
          phone: phone.value.trim(),
          website: website.value.trim(),
          source: source.value.trim(),
          // estimated_revenue_potential: estimated_revenue_potential.value,
          company_annual_revenue: company_annual_revenue.value,
          industry: industry.value ?? "",
          employees: employees.value,
          billing_address: billing_address.value.trim(),
          state: state.value,
          zip: zip.value.trim(),
          owner: owner.value,
          city: city.value,
          number_travelers: number_travelers.value,
          travel_policy: travel_policy.value.trim(),
          travel_reason: travel_reason.value.trim(),
          local_competitors: local_competitors.value.trim(),
          room_night_potential: room_night_potential.value,
          preferred_brands: preferred_brands.chosen_options,
          preferred_amenities: preferred_amenities.chosen_options,
          preferred_chains: preferred_chains.chosen_options,
          brand_scale: brand_scale.chosen_options,
        },
      };

      // for (let key of formData.entries()) {
      //     console.log(key)
      // }

      API(handleCurrentToken())
        .post(`/prospects/details`, editMode ? editPostData : createPostData)
        .then((res) => {
          changeContinuePopup(
            editMode ? "Prospect info edited" : "Prospect created"
          );
          if (!editMode) {
            history.push("/admin/companies");
          } else {
            setEditProspectInfo(false);
          }
          setTimeout(() => {
            changeContinuePopup(
              editMode ? "Prospect info edited" : "Prospect created"
            );
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      document.getElementById("scroll_top").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      if (!editMode) {
        if (!prospectContacts.contacts.length) {
          setProspectContacts((prevState) => ({
            ...prevState,
            error: true,
          }));
        }
        if (!opportunityData.opportunities.length && !opportunityData.submit) {
          setOpportunityData((prevState) => ({
            ...prevState,
            error: true,
            submit: false,
          }));
        }
      }
      handleSubmitError(firstBlock, setFirstBlock);
      // handleSubmitError(secondBlock, setSecondBlock)
      // handleSubmitError(thirdBlock, setThirdBlock)
      // handleSubmitError(fourthBlock, setFourthBlock)
      // handleSubmitError(fifthBlock, setFifthBlock)
      // handleSubmitError(switchData, setSwitchData)
    }
  };

  const editLayout = (title, content, icon, style, website) => (
    <div>
      <div className={`title ${icon ? "title_inline" : ""}`}>
        <h6>{title}</h6>
        {icon ? icon : null}
      </div>
      <div className={"content"}>
        <span
          style={website ? { textTransform: "none" } : null}
          className={style ? "status" : ""}
        >
          {content}
        </span>
      </div>
    </div>
  );

  const [activities, setActivities] = useState({ activities: [] });
  const [hideModalOutreeach, setHideModalOutreeach] = useState(false);

  useEffect(() => {
    let prospect_id = location.pathname.split("/")[3];

    API(handleCurrentToken())
      .get(`/prospects/activities?prospect_id=${prospect_id}`)
      .then((res) => {
        // console.log('FDSFDSF', res.data.activities)
        setActivities(res.data);
      });
  }, [location.pathname]);

  const [showMoreActivities, setShowMoreActivities] = useState([]);

  const handleShowMore = (index) => {
    let arr = [...showMoreActivities];

    if (arr.includes(index)) {
      setShowMoreActivities(arr.filter((el) => el !== index));
    } else {
      arr.push(index);
      setShowMoreActivities(arr);
    }
  };

  const allRecentActivity = () => {
    return (
      <div className={"recent_engagement_wrapper"}>
        <OutsideClickHandler
          callback={() => {
            // setViewEngagement(false)
          }}
        >
          <div className={"recent_activity_modal_window"}>
            <div className={"fixed_modal_header"}>
              <div className={"recent_engagement_modal_header"}>
                <span className={"recent_modal_title"}>Outreach</span>
                <MainButton
                  type={"button"}
                  text={"Close"}
                  className={"cancel_button"}
                  onClick={() => setHideModalOutreeach(!hideModalOutreeach)}
                />
              </div>
            </div>
            <div className={"modal_list_of_activity"}>
              <div>
                {activities && activities.activities.length > 0 ? (
                  activities.activities.map((item, index) => (
                    <div key={index} className={"engagement_line_custom"}>
                      <div
                        style={{ cursor: "pointer" }}
                        className={"left_engagement_side"}
                        onClick={() =>
                          window.open(
                            `/admin/contacts/contact_info/${item.contact.id}`,
                            "_blank"
                          )
                        }
                      >
                        <div className={"user_data"}>
                          <span className={"user_name"}>
                            {item.contact.name}
                          </span>
                          <p style={{ fontSize: 12, color: "#475569" }}>
                            {item.contact.prospect}
                          </p>
                          <div
                            style={
                              !item.phone
                                ? { gridGap: "0", justifySelf: "center" }
                                : null
                            }
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: 10,
                              }}
                            >
                              {item.type === "call"
                                ? callIcon
                                : item.type === "meeting"
                                ? meetingIcon
                                : mailIcon}
                              <p
                                style={{
                                  marginLeft: 6,
                                  color: "#94A3B8",
                                  fontSize: 12,
                                }}
                              >
                                {item.type === "call"
                                  ? "Call"
                                  : item.type === "meeting"
                                  ? "Meeting"
                                  : "Email"}{" "}
                                Logged
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                      <span style={{ display: "flex" }}>
                        <p
                          style={{
                            color: "#475569",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Notes
                        </p>
                        <div style={{ marginLeft: 16 }}>
                          <p
                            style={{ color: "#94A3B8", fontSize: 12 }}
                            className={
                              showMoreActivities.includes(index)
                                ? "notes-modal-full-text"
                                : "notes-modal-text"
                            }
                          >
                            {item.notes}
                          </p>
                          {item.notes > 160 && (
                            <p
                              style={{
                                marginTop: 10,
                                fontSize: 12,
                                color: "#475569",
                                fontWeight: "600",
                                cursor: "pointer",
                              }}
                              onClick={() => handleShowMore(index)}
                            >
                              {!showMoreActivities.includes(index)
                                ? "show more"
                                : "hide"}
                            </p>
                          )}
                        </div>
                      </span>
                      <div className={"date_of_engagement"}>
                        <span>{item.date}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={"no_activity_title"}>
                    <span>There has been no engagement recently</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    );
  };

  const exportProspectsIds = () => {
    window.open(
      baseURL +
        `/data/export?prospects_ids=${location.pathname.split("/")[3]}&jwt=` +
        handleCurrentToken()
    );
  };

  return [
    <ChangedConfirmedPopup
      successText={successText}
      key={10}
      changingConfirmed={changingConfirmed}
    />,
    <div id={"scroll_top"} key={0} className={"new_prospect_wrapper"}>
      {location.pathname.split("/")[3] !== "new_prospect" && (
        <MainButton
          type={"button"}
          style={{
            position: "absolute",
            width: 160,
            right: 40,
          }}
          svg={
            <svg
              width='25'
              height='24'
              viewBox='0 0 25 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.5 17V19C4.5 19.5304 4.71071 20.0391 5.08579 20.4142C5.46086 20.7893 5.96957 21 6.5 21H18.5C19.0304 21 19.5391 20.7893 19.9142 20.4142C20.2893 20.0391 20.5 19.5304 20.5 19V17'
                stroke='white'
                strokeWidth='2'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M7.5 11L12.5 16L17.5 11'
                stroke='white'
                strokeWidth='2'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M12.5 4V16'
                stroke='white'
                strokeWidth='2'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          }
          className={"button_default_plus"}
          text={"Export Data"}
          onClick={() => exportProspectsIds()}
        />
      )}
      <div className={"navigation"}>
        <Link to={"/admin/companies"}>Companies</Link>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M6 4L10 8L6 12'
            stroke='#475569'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <span>
          {editMode
            ? loading
              ? firstBlock.name.value
              : "Edit Company"
            : "New Company"}
        </span>
      </div>
      {hideModalOutreeach && allRecentActivity()}
      <h2 className={"page_title"}>
        {editMode
          ? loading
            ? firstBlock.name.value
            : "Edit Company"
          : "New Company"}
      </h2>
      <div className={"new_prospect_container"}>
        {editMode && !editProspectInfo ? (
          <div
            style={
              !loading
                ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "204px",
                  }
                : null
            }
            className={"basic_info edit_basic_info"}
          >
            {loading ? (
              <>
                <div className={"name_edit"}>
                  <div className={"name_edit_content"}>
                    {editLayout("Company Name", firstBlock.name.value)}
                    {editLayout("Phone", firstBlock.phone.value ?? "")}
                    {editLayout(
                      "Website",
                      firstBlock.website.value,
                      null,
                      null,
                      true
                    )}
                    {editLayout("Source", firstBlock.source.value)}
                    {editLayout("Company Owner", firstBlock.owner.value)}
                  </div>
                  <MainButton
                    type={"button"}
                    text={"Edit"}
                    svg={
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 19.9998H8L18.5 9.49981C19.0304 8.96938 19.3284 8.24996 19.3284 7.49981C19.3284 6.74967 19.0304 6.03025 18.5 5.49981C17.9696 4.96938 17.2501 4.67139 16.5 4.67139C15.7499 4.67139 15.0304 4.96938 14.5 5.49981L4 15.9998V19.9998Z'
                          stroke='var(--gitgo-blue)'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M13.5 6.5L17.5 10.5'
                          stroke='var(--gitgo-blue)'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    }
                    className={"edit_button"}
                    onClick={() => setEditProspectInfo(true)}
                  />
                </div>

                <div className={"industry_edit"}>
                  <div className={"top"}>
                    {/* {editLayout('Estimated Revenue Potential', `$${secondBlock.estimated_revenue_potential.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`)} */}
                    {editLayout(
                      "Company Annual Revenue",
                      `$${secondBlock.company_annual_revenue.value
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    )}
                    {/* </div>
                                <div className={'bottom'}> */}
                    {editLayout("Industry", secondBlock.industry.value)}
                    {/*editLayout('Market Range', secondBlock.employees.value)*/}
                    {/*editLayout('Employees', secondBlock.market_range.value)*/}
                  </div>
                </div>

                <div className={"billing_edit"}>
                  {editLayout(
                    "Billing Address",
                    `${thirdBlock.billing_address.value}, ${thirdBlock.city.value}, ${thirdBlock.state.value}, ${thirdBlock.zip.value}`,
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M8 9.3335C9.10457 9.3335 10 8.43807 10 7.3335C10 6.22893 9.10457 5.3335 8 5.3335C6.89543 5.3335 6 6.22893 6 7.3335C6 8.43807 6.89543 9.3335 8 9.3335Z'
                        stroke='#94A3B8'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M11.7713 11.1045L8.94266 13.9331C8.69265 14.1829 8.35371 14.3232 8.00033 14.3232C7.64694 14.3232 7.308 14.1829 7.05799 13.9331L4.22866 11.1045C3.48281 10.3586 2.97488 9.40827 2.76912 8.37371C2.56335 7.33916 2.66899 6.26681 3.07266 5.29229C3.47634 4.31777 4.15992 3.48483 5.03698 2.89881C5.91404 2.31279 6.94517 2 7.99999 2C9.05481 2 10.0859 2.31279 10.963 2.89881C11.8401 3.48483 12.5236 4.31777 12.9273 5.29229C13.331 6.26681 13.4366 7.33916 13.2309 8.37371C13.0251 9.40827 12.5172 10.3586 11.7713 11.1045V11.1045Z'
                        stroke='#94A3B8'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  )}
                </div>
                <div className={"travel_edit"}>
                  {editLayout(
                    "Number of Travelers",
                    `${fourthBlock.number_travelers.value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  )}
                  {/*{editLayout('Market', fourthBlock.number_travelers.value)}*/}
                  {editLayout("Travel Policy", fourthBlock.travel_policy.value)}
                  {editLayout("Travel Reason", fourthBlock.travel_reason.value)}
                </div>
                <div className={"competitors_edit"}>
                  {editLayout(
                    "Local Competitors",
                    fifthBlock.local_competitors.value
                  )}
                  {editLayout(
                    "Room Night Potential",
                    fifthBlock.room_night_potential.value
                  )}
                </div>
                <SwitchBlock
                  disable={editMode && !editProspectInfo}
                  data={switchData}
                  setData={(e) => setSwitchData(e)}
                />
              </>
            ) : (
              <Loader
                color={{
                  "--bgColor": "var(--gitgo-blue)",
                  "--size": "40px",
                  "--wrapper_size": "40px",
                }}
              />
            )}
          </div>
        ) : (
          <div className={"basic_info"}>
            <div className={"name_info"}>
              <RenderInput
                obj={firstBlock}
                setState={(e) => setFirstBlock(e)}
              />
            </div>
            <div className={"industry_info"}>
              <RenderInput
                obj={secondBlock}
                setState={(e) => setSecondBlock(e)}
              />
            </div>
            <div className={"billing_info"}>
              <RenderInput
                obj={thirdBlock}
                setState={(e) => setThirdBlock(e)}
              />
            </div>
            <div className={"travel_info"}>
              <RenderInput
                obj={fourthBlock}
                setState={(e) => setFourthBlock(e)}
              />
            </div>
            <div className={"competitors_info"}>
              <RenderInput
                obj={fifthBlock}
                setState={(e) => setFifthBlock(e)}
              />
            </div>
            <SwitchBlock data={switchData} setData={(e) => setSwitchData(e)} />
            <div className={"buttons_wrapper"}>
              <MainButton
                type={"button"}
                text={"Cancel"}
                className={"cancel_button"}
                onClick={() => {
                  if (editMode) {
                    setEditProspectInfo(false);
                  } else {
                    history.push("/admin/companies");
                  }
                }}
              />
              <MainButton
                type={"button"}
                text={
                  // location.pathname.split("/")[3] !== "new_prospect"
                  //   ? "Edit" :
                  "Save"
                }
                onClick={() => handleSubmitProspect()}
                className={"submit_button"}
              />
            </div>
          </div>
        )}
        <div className={"opportunities_contacts"}>
          <div className={"contacts_wrap"}>
            <div className={"title"}>
              <div className={"inline"}>
                <h3>Contacts</h3>
                {prospectContacts.contacts.length ? (
                  <span>{prospectContacts.contacts.length}</span>
                ) : null}
              </div>
              <MainButton
                type={"button"}
                svg={
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M12 5V19'
                      stroke='var(--gitgo-blue)'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M5 12H19'
                      stroke='var(--gitgo-blue)'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                }
                className={"button_add_new"}
                text={"New"}
                onClick={() => {
                  bodyOverflow(true);
                  setActiveModal({
                    value: true,
                    type: "contacts",
                    data: null,
                  });
                }}
              />
            </div>
            {/* {!prospectContacts.contacts.length && prospectContacts.error ?
                            <p className={'error'}>{prospectContacts.errorMessage}</p> : null} */}
            {prospectContacts.contacts.length ? (
              <div className={"body"}>
                {prospectContacts.contacts.map(
                  (el, i) =>
                    (showContacts ? i < 1000 : i <= 3) && (
                      <div
                        key={el.id ?? i}
                        className={"item"}
                        onClick={() =>
                          window.open(
                            `/admin/contacts/contact_info/${el.id}`,
                            "_blank"
                          )
                        }
                      >
                        <div className={"inline"}>
                          <div className={"full"}>
                            <h6>{el.name}</h6>
                            <h6>{el.job_title}</h6>
                          </div>
                          <div
                            className={"full"}
                            style={!el.phone ? { gridGap: "0" } : null}
                          >
                            <p>{el.phone}</p>
                            <p>{el.email}</p>
                          </div>
                        </div>
                        <div className={"full"}>
                          <span>Last Engaged</span>
                          <span>{el.last_engaged ?? "No activity yet"}</span>
                        </div>
                      </div>
                    )
                )}
                {prospectContacts.contacts.length > 4 || editMode ? (
                  <div
                    style={
                      prospectContacts.contacts.length > 4
                        ? { justifyContent: "space-between" }
                        : { justifyContent: "end" }
                    }
                    className={"contacts_edit"}
                  >
                    {prospectContacts.contacts.length > 4 ? (
                      <button
                        onClick={() => {
                          setShowContacts((prevState) => !prevState);
                        }}
                        className={"view_all"}
                      >
                        <span>{"Show less"}</span>
                        <svg
                          width='6'
                          height='8'
                          viewBox='0 0 6 8'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.65722 0L0.712891 0.94L3.78028 4L0.712891 7.06L1.65722 8L5.67564 4L1.65722 0Z'
                            fill='var(--gitgo-blue)'
                          />
                        </svg>
                      </button>
                    ) : null}
                    {/*{editMode ?*/}
                    {/*    <MainButton type={'button'}*/}
                    {/*                text={'Save'}*/}
                    {/*                className={'submit_button'}*/}
                    {/*                onClick={() => editProspectContacts()}/> : null}*/}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className={"opportunities_wrap"}>
            <div className={"title"}>
              <h3>View Opportunities</h3>
              <MainButton
                type={"button"}
                svg={
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M12 5V19'
                      stroke='var(--gitgo-blue)'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M5 12H19'
                      stroke='var(--gitgo-blue)'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                }
                className={"button_add_new"}
                text={"New"}
                onClick={() => {
                  setOpportunityData((prevState) => ({
                    ...prevState,
                    error: false,
                    opportunities: [
                      ...prevState.opportunities,
                      {
                        ...prevState.initial,
                        open: true,
                      },
                    ],
                  }));
                }}
              />
            </div>
            {/* {opportunityData.error ? <p className={'error'}>{opportunityData.errorMessage}</p> : null} */}

            {opportunityData?.opportunities?.length ? (
              <div className={"opportunity_options"}>
                {opportunityData.opportunities.map((el, i) => (
                  <div className={"opportunity_item"} key={i}>
                    <div className={"item_title"}>
                      <span>
                        {el.edit
                          ? el.name.value
                          : el.open
                          ? el.opportunity_status === "new"
                            ? "New"
                            : "Edit"
                          : el.name.value
                          ? el.name.value
                          : "New"}
                      </span>
                      <div
                        onClick={() => {
                          setOpportunityData((prevState) => ({
                            ...prevState,
                            opportunities: [
                              ...prevState.opportunities.slice(0, i),
                              { ...el, open: !prevState.opportunities[i].open },
                              ...prevState.opportunities.slice(i + 1),
                            ],
                          }));
                        }}
                        className={`open ${el.open ? "opened" : ""}`}
                      >
                        <div />
                        <div />
                      </div>
                    </div>
                    {el.open ? (
                      el.edit && !el.edit_view ? (
                        <div
                          className={`opportunity_content edit_opportunity_content`}
                        >
                          <div className={"half_screen"}>
                            {Object.entries(el).map((x) => {
                              if (
                                x[1] instanceof Object &&
                                x[1].title !== "Opportunity Name"
                              ) {
                                return (
                                  <div key={x[1].title}>
                                    <div className={"title"}>
                                      <h6>{x[1].title}</h6>
                                    </div>
                                    <div className={"content"}>
                                      <span>
                                        {x[0] === "potential_revenue"
                                          ? "$"
                                          : ""}
                                        {x[1].date
                                          ? moment(
                                              x[1].value,
                                              "DD/MM/YYYY"
                                            ).format("MMM DD YYYY")
                                          : x[1].value?.toString().includes("_")
                                          ? x[1].value.split("_").join(" ")
                                          : x[1].value instanceof Object
                                          ? x[1].value.name
                                          : x[1]?.value
                                              ?.toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                      </span>
                                    </div>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </div>
                          <div className={"edit_buttons"}>
                            <button
                              onClick={() => {
                                history.push(
                                  `/admin/companies/${editMode}/${el.id}`
                                );
                                dispatch(
                                  setProspectData({
                                    id: editMode,
                                    name: firstBlock.name.value,
                                  })
                                );
                              }}
                              className={"view_profile"}
                            >
                              <span>View profile</span>
                              <svg
                                width='17'
                                height='16'
                                viewBox='0 0 17 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M7.15722 4L6.21289 4.94L9.28028 8L6.21289 11.06L7.15722 12L11.1756 8L7.15722 4Z'
                                  fill='var(--gitgo-blue)'
                                />
                              </svg>
                            </button>
                            <MainButton
                              type={"button"}
                              text={"Edit"}
                              svg={
                                <svg
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M4 19.9998H8L18.5 9.49981C19.0304 8.96938 19.3284 8.24996 19.3284 7.49981C19.3284 6.74967 19.0304 6.03025 18.5 5.49981C17.9696 4.96938 17.2501 4.67139 16.5 4.67139C15.7499 4.67139 15.0304 4.96938 14.5 5.49981L4 15.9998V19.9998Z'
                                    stroke='var(--gitgo-blue)'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <path
                                    d='M13.5 6.5L17.5 10.5'
                                    stroke='var(--gitgo-blue)'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                              }
                              className={"edit_button"}
                              onClick={() => {
                                setOpportunityData((prevState) => ({
                                  ...prevState,
                                  opportunities: [
                                    ...prevState.opportunities.slice(0, i),
                                    { ...el, edit_view: true },
                                    ...prevState.opportunities.slice(i + 1),
                                  ],
                                }));
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className={"opportunity_content"}>
                            <OpportunityInitial
                              data={opportunityData}
                              nested={"opportunities"}
                              index={i}
                              setData={(e) => setOpportunityData(e)}
                            />
                          </div>
                          <div className={"notes"}>
                            <span className='notes-label'>Notes</span>
                            <TextBox
                              height={112}
                              rows={8}
                              value={notes}
                              onChange={({ target }) => setNotes(target.value)}
                            />
                          </div>
                          <div
                            style={
                              opportunityData.opportunities.length === i + 1
                                ? { margin: "0" }
                                : { margin: "0 0 2rem" }
                            }
                            className={"buttons_wrapper"}
                          >
                            <MainButton
                              type={"button"}
                              text={"Cancel"}
                              className={"cancel_button"}
                              onClick={() => {
                                if (el.edit) {
                                  setOpportunityData((prevState) => ({
                                    ...prevState,
                                    opportunities: [
                                      ...prevState.opportunities.slice(0, i),
                                      { ...el, edit_view: false },
                                      ...prevState.opportunities.slice(i + 1),
                                    ],
                                  }));
                                } else {
                                  if (el.opportunity_status === "edit") {
                                    setOpportunityData((prevState) => ({
                                      ...prevState,
                                      opportunities: [
                                        ...prevState.opportunities.slice(0, i),
                                        { ...el, open: false },
                                        ...prevState.opportunities.slice(i + 1),
                                      ],
                                    }));
                                  } else {
                                    setOpportunityData((prevState) => ({
                                      ...prevState,
                                      opportunities:
                                        prevState.opportunities.filter(
                                          (item, num) => num !== i
                                        ),
                                    }));
                                  }
                                }
                              }}
                            />
                            <MainButton
                              type={"button"}
                              text={"Save"}
                              className={"submit_button"}
                              onClick={() => addOpportunity(el, i)}
                            />
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          {location.pathname.split("/")[3] !== "new_prospect" && (
            <>
              <div className={"contacts_wrap"}>
                <div className={"title"}>
                  <div className={"inline"}>
                    <h3>Outreach</h3>
                  </div>
                </div>
                {!activities.activities.length && prospectContacts.error ? (
                  <p className={"error"}>{prospectContacts.errorMessage}</p>
                ) : null}
                {activities.activities.length ? (
                  <div className={"body"}>
                    {activities.activities.map(
                      (el, i) =>
                        (showContacts ? i < 1000 : i <= 3) && (
                          <div
                            key={el.id ?? i}
                            className={"item"}
                            onClick={() =>
                              window.open(
                                `/admin/contacts/contact_info/${el.contact.id}`,
                                "_blank"
                              )
                            }
                          >
                            <div className={"inline"}>
                              <div className={"full"}>
                                <h6>{el.contact.name}</h6>
                                <p style={{ fontSize: 12, color: "#475569" }}>
                                  {el.contact.prospect}
                                </p>
                              </div>
                              <div
                                style={
                                  !el.phone
                                    ? { gridGap: "0", justifySelf: "center" }
                                    : null
                                }
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {el.type === "call"
                                    ? callIcon
                                    : el.type === "meeting"
                                    ? meetingIcon
                                    : mailIcon}
                                  <p
                                    style={{
                                      marginLeft: 6,
                                      color: "#94A3B8",
                                      fontSize: 12,
                                    }}
                                  >
                                    {el.type === "call"
                                      ? "Call"
                                      : el.type === "meeting"
                                      ? "Meeting"
                                      : "Email"}{" "}
                                    Logged
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className={"full"}>
                              <span>{el.date ?? "No activity yet"}</span>
                            </div>
                          </div>
                        )
                    )}
                    {activities.activities.length > 4 || editMode ? (
                      <div
                        style={
                          activities.activities.length > 4
                            ? { justifyContent: "space-between" }
                            : { justifyContent: "end" }
                        }
                        className={"contacts_edit"}
                      >
                        {activities.activities.length > 4 ? (
                          <button
                            onClick={() =>
                              setHideModalOutreeach(!hideModalOutreeach)
                            }
                            className={"view_all"}
                          >
                            <span>
                              {showContacts ? "Show less" : "View all"}
                            </span>
                            <svg
                              width='6'
                              height='8'
                              viewBox='0 0 6 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M1.65722 0L0.712891 0.94L3.78028 4L0.712891 7.06L1.65722 8L5.67564 4L1.65722 0Z'
                                fill='var(--gitgo-blue)'
                              />
                            </svg>
                          </button>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>{" "}
            </>
          )}
        </div>
        {loading && editMode ? (
          <div
            onClick={() => {
              bodyOverflow(true);
              setActiveModal({
                value: true,
                type: "delete",
                data: null,
              });
            }}
            className={"delete_travel_opportunity"}
          >
            <h4>Delete Prospect</h4>
          </div>
        ) : null}
      </div>
    </div>,
    activeModal.value ? (
      activeModal.type === "contacts" ? (
        <ContactsPopup
          key={20}
          changeContinuePopup={changeContinuePopup}
          prospectContacts={prospectContacts}
          setProspectContacts={(e) => setProspectContacts(e)}
          activeModal={activeModal}
          editMode={editMode}
          setActiveModal={(e) => setActiveModal(e)}
        />
      ) : (
        <ModalWindow
          key={21}
          nestedObj
          setActiveModal={(val) => setActiveModal(val)}
          deleteModal
        >
          <div className={"delete_modal"}>
            <span>Delete</span>
            <h3>
              Are you sure you want to delete{" "}
              {activeModal.data ? activeModal.data.name : "this Prospect"}?
            </h3>
            <p>This action cannot be restored</p>
            <div className={"modal_window_buttons"}>
              <MainButton
                type={"button"}
                className={"cancel_button"}
                text={"Cancel"}
                onClick={() => {
                  setActiveModal({
                    value: false,
                    type: "",
                    data: null,
                  });
                  bodyOverflow(false);
                }}
              />
              <MainButton
                type={"button"}
                className={"delete_button"}
                text={"Delete"}
                onClick={() => handleDeleteItem()}
              />
            </div>
          </div>
        </ModalWindow>
      )
    ) : null,
  ];
}
