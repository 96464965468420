import React, {useEffect, useState} from "react";
import './styles/profile_settings.css'
import {useDispatch, useSelector} from "react-redux";
import API from "../../api/API";
import {handleCurrentToken} from "../../Constants/handleCurrentToken";
import {setUserData} from "../../main-store/auth/actions";
import useBottomPopup from "../../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../../Components/ChangedConfirmedPopup";
import ChangePasswordPopup from "./ChangePasswordPopup";
import ProfileSettingsWrapper from "../../Components/ProfileSettingsWrapper";
// import ChangePasswordPopup from "./ChangePasswordPopup";

const initialState = {
    email: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    company_name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    first_name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    last_name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    phone: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    job_title: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
};

export default function ProfileSettings() {
    const dispatch = useDispatch();
    const store = useSelector(state => state.auth.user_data);

    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const [uploadIsOpen, setUploadIsOpen] = useState(false);
    const [profileEditError, setProfileEditError] = useState("");

    const toggleUploadPopup = () => {
        setUploadIsOpen(!uploadIsOpen);
    };

    const [profileData, setProfileData] = useState(() => initialState)

    useEffect(() => {
        API(handleCurrentToken()).get(`/profile`)
            .then(res => {
                setProfileData(state => ({
                    email: {
                        ...state.email,
                        value: res.data.profile.email,
                    },
                    company_name: {
                        ...state.company_name,
                        value: res.data.profile.company_name,
                    },
                    first_name: {
                        ...state.first_name,
                        value: res.data.profile.first_name ?? '',
                    },
                    last_name: {
                        ...state.last_name,
                        value: res.data.profile.last_name ?? '',
                    },
                    phone: {
                        ...state.phone,
                        value: res.data.profile.phone ?? '',
                    },
                    job_title: {
                        ...state.job_title,
                        value: res.data.profile.job_title ?? '',
                    }
                }))
            })
    }, [])

    const updateProfileSettings = (e) => {
        e.preventDefault();
        const {email, job_title, phone, last_name, first_name, company_name} = profileData;
        const condition = first_name.value && last_name.value && job_title.value && phone.value && email.value && company_name.value
        let formData = new FormData();

        if (condition) {
            if (store.role === 'gitgo_admin') formData.append('email', email.value.trim())
            if (store.role === 'gitgo_admin') formData.append('company_name', company_name.value.trim())
            formData.append('first_name', first_name.value.trim())
            formData.append('last_name', last_name.value.trim())
            formData.append('job_title', job_title.value.trim())
            formData.append('phone', phone.value.trim())
            API(handleCurrentToken()).post(`/profile`, formData)
                .then(res => {
                    console.log(res)
                    dispatch(setUserData({
                        ...store,
                        company_name: company_name.value.trim(),
                        first_name: first_name.value.trim(),
                        last_name: last_name.value.trim(),
                    }));
                    changeContinuePopup('Profile updated')
                    setTimeout(() => {
                        changeContinuePopup('Profile updated')
                    }, 2000)
                })
                .catch(err => {
                    console.log(err)
                    if(err.response.data.error.toLowerCase() === "user already exists"){
                        setProfileEditError("This email is already used in another workspace")
                    }
                    console.log(profileEditError)
                })
        } else {
            Object.entries(profileData).forEach((el) => {
                if (!el[1].value) {
                    setProfileData(prevState => ({
                        ...prevState,
                        [el[0]]: {
                            ...prevState[el[0]],
                            error: true,
                        }
                    }))
                }
            })
        }
    }

    return [
        <ChangedConfirmedPopup successText={successText}
                               key={0}
                               changingConfirmed={changingConfirmed}/>,
        <ProfileSettingsWrapper key={1} profileData={profileData}
                                toggleUploadPopup={toggleUploadPopup}
                                profileSettings
                                pageTitle={'Profile Settings'}
                                setProfileData={e => setProfileData(e)}
                                profileEditError={profileEditError}
                                updateProfileSettings={updateProfileSettings}/>,
        uploadIsOpen ?
            <ChangePasswordPopup key={10} changePasswordEmail={profileData.email.value}
                                 handleClose={toggleUploadPopup}/> : null,
    ]
}