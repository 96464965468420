import React, {useState} from "react";
import './styles/contacts_popup.css';
import OutsideClickHandler from "../../Constants/OutsideClickHandler";
import {bodyOverflow} from "../../Constants/bodyOverflow";
// import {handleCurrentToken} from "../../Constants/handleCurrentToken";
// import API from "../../api/API";
// import {Loader} from "../Loader";
import MainButton from "../CustomComponents/MainButton";
import TextInput from "../CustomComponents/TextInput";
import regExp from "../../Constants/regExp";
import API from "../../api/API";
import {handleCurrentToken} from "../../Constants/handleCurrentToken";
// import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const initialState = {
    name: {
        value: '',
        error: false,
        success: false,
        errorMessage: 'Fill this label'
    },
    email: {
        value: '',
        error: false,
        success: false,
        errorMessage: 'Fill this label'
    },
    job_title: {
        value: '',
        error: false,
        success: false,
        errorMessage: 'Fill this label'
    },
    phone: {
        value: '',
        error: false,
        success: false,
        errorMessage: 'Fill this label'
    },
}

export default function ContactsPopup(props) {

    const {activeModal, setActiveModal, prospectContacts, setProspectContacts, changeContinuePopup, editMode} = props;

    const [platformContacts, setPlatformContacts] = useState(() => initialState);
    const [editContact, setEditContact] = useState(null);
    // const [buttonPopup, setButtonPopup] = useState(false);
    // const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     API(handleCurrentToken()).get(`/contacts`)
    //         .then(res => {
    //             res.data.contacts.forEach(el => el.popup = false)
    //             console.log(res)
    //             setPlatformContacts(res.data)
    //             setLoading(true)
    //         })
    // }, [])

    // const handlePlatformContactsPopup = (el, i, close, prospect) => {
    //     if (prospect) {
    //         setProspectContacts(prevState => ({
    //             ...prevState,
    //             contacts: [
    //                 ...prevState.contacts.slice(0, i),
    //                 {...el, popup: close},
    //                 ...prevState.contacts.slice(i + 1)
    //             ]
    //         }))
    //     } else {
    //         setPlatformContacts(prevState => ({
    //             ...prevState,
    //             contacts: [
    //                 ...prevState.contacts.slice(0, i),
    //                 {...el, popup: close},
    //                 ...prevState.contacts.slice(i + 1)
    //             ]
    //         }))
    //     }
    // }

    const addNewContact = (e) => {
        e.preventDefault();
        const {name, email, job_title, phone} = platformContacts;

        const setStateContacts = () => {
            setProspectContacts(prevState => ({
                ...prevState,
                contacts: [
                    ...prevState.contacts.slice(0, +editContact.index),
                    {
                        ...editContact.el,
                        email: email.value.trim(),
                        phone: phone.value.trim(),
                        job_title: job_title.value.trim(),
                        name: name.value.trim(),
                        status: "New",
                        popup: false,
                    },
                    ...prevState.contacts.slice(+editContact.index + 1),
                ]
            }))
        }

        if (name.value && email.value && job_title.value && phone.value) {
            if (regExp.email.test(email.value)) {
                if (editContact) {
                    if (editMode) {
                        API(handleCurrentToken()).post(`/contacts/details`, {
                            id: +editContact.el.id,
                            name: name.value.trim(),
                            email: email.value.trim(),
                            job_title: job_title.value.trim(),
                            phone: phone.value.trim(),
                            status: "New",
                            prospect_id: +editMode,
                        })
                            .then(res => {
                                if (res.status === 200) {
                                    setStateContacts()
                                    setEditContact(null)
                                    changeContinuePopup('Contact edited')
                                    setPlatformContacts(initialState)
                                    setTimeout(() => {
                                        changeContinuePopup('Contact edited')
                                    }, 2000)
                                }
                            })
                    } else {
                        setStateContacts()
                        setEditContact(null)
                        changeContinuePopup('Contact edited')
                        setPlatformContacts(initialState)
                        setTimeout(() => {
                            changeContinuePopup('Contact edited')
                        }, 2000)
                    }
                } else {
                    API(handleCurrentToken()).post(`/contacts/check_email`, {
                        email: email.value
                    })
                        .then(res => {
                            // console.log(res)
                            if (res.status === 200) {
                                if (editMode) {
                                    API(handleCurrentToken()).post(`/contacts/details`, {
                                        id: null,
                                        name: name.value.trim(),
                                        email: email.value.trim(),
                                        job_title: job_title.value.trim(),
                                        phone: phone.value.trim(),
                                        prospect_id: +editMode,
                                        status: "New"
                                    })
                                        .then(res => {
                                                if (res.status === 200) {
                                                    setProspectContacts(prevState => ({
                                                        ...prevState,
                                                        total: prevState.total + 1,
                                                        contacts: [{
                                                            email: email.value.trim(),
                                                            phone: phone.value.trim(),
                                                            id: res.data.id,
                                                            job_title: job_title.value.trim(),
                                                            name: name.value.trim(),
                                                            status: "New",
                                                            popup: false,
                                                        }, ...prevState.contacts]
                                                    }))
                                                    setEditContact(null)
                                                    changeContinuePopup('Contact created')
                                                    setPlatformContacts(initialState)
                                                    setTimeout(() => {
                                                        changeContinuePopup('Contact created')
                                                    }, 2000)
                                                }
                                            }
                                        )
                                } else {
                                    setProspectContacts(prevState => ({
                                        ...prevState,
                                        total: prevState.total + 1,
                                        contacts: [{
                                            created: null,
                                            email: email.value.trim(),
                                            phone: phone.value.trim(),
                                            id: null,
                                            job_title: job_title.value.trim(),
                                            last_engaged: null,
                                            name: name.value.trim(),
                                            status: "New",
                                            popup: false,
                                        }, ...prevState.contacts,]
                                    }))
                                    changeContinuePopup('Contact created')
                                    setPlatformContacts(initialState)
                                    setTimeout(() => {
                                        changeContinuePopup('Contact created')
                                    }, 2000)
                                }
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            if (err.response.data.error === "contact already exists") {
                                setPlatformContacts(state => ({
                                    ...state,
                                    email: {
                                        ...state.email,
                                        error: true,
                                        errorMessage: 'contact already exists',
                                    },
                                }))
                            }
                        })
                }
            } else {
                if (!regExp.email.test(email.value)) {
                    setPlatformContacts(state => ({
                        ...state,
                        email: {
                            ...state.email,
                            error: true,
                            errorMessage: 'Wrong email format',
                        },
                    }))
                }
            }
        } else {
            Object.entries(platformContacts).forEach(el => {
                if (!el[1].value) {
                    setPlatformContacts(prevState => ({
                        ...prevState,
                        [el[0]]: {
                            ...prevState[el[0]],
                            error: true,
                            errorMessage: 'Fill this label',
                        }
                    }))
                }
            })
        }
    }

    const contactLayout = (data) => (
        data.length ?
            data.map((el, i) => (
                // <Draggable draggableId={`task-group-${el.id}`}
                //            key={el.id}
                //            index={i}>
                //     dragSnapshot
                // {(provided) => (
                <div key={el.id ?? i} className={'item'}
                onClick={() =>  window.open(`/admin/contacts/contact_info/${el.id}`, '_blank')}
                    // {...provided.draggableProps}
                    // {...provided.dragHandleProps}
                    // ref={provided.innerRef}
                >
                    <div className={'left'}>
                        <div>
                            <h6>{el.name}</h6>
                            <h6>{el.job_title}</h6>
                        </div>
                        <div style={!el.phone ? {gridGap: '0'} : null}>
                            <p>{el.phone}</p>
                            <p>{el.email}</p>
                        </div>
                    </div>
                    <div className={'right'}>
                        <div>
                            <span>Last Engaged</span>
                            <span>{el.last_engaged ?? 'No activity yet'}</span>
                        </div>
                        <div className={'popup_wrap'}>
                            <svg style={el.popup ? {pointerEvents: 'none'} : null}
                                 onClick={() => {
                                     // handlePlatformContactsPopup(el, i, true, prospect)
                                     setProspectContacts(prevState => ({
                                         ...prevState,
                                         contacts: [
                                             ...prevState.contacts.slice(0, i),
                                             {...el, popup: true},
                                             ...prevState.contacts.slice(i + 1)
                                         ]
                                     }))
                                 }}
                                 width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
                                    fill="#E2E8F0" stroke="#E2E8F0" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path
                                    d="M9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15Z"
                                    stroke="#CBD5E1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5Z"
                                    stroke="#CBD5E1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            {el.popup ?
                                <OutsideClickHandler callback={() => {
                                    // handlePlatformContactsPopup(el, i, false, prospect)
                                    setProspectContacts(prevState => ({
                                        ...prevState,
                                        contacts: [
                                            ...prevState.contacts.slice(0, i),
                                            {...el, popup: false},
                                            ...prevState.contacts.slice(i + 1)
                                        ]
                                    }))
                                }}>
                                    <div className={'popup_content modal_contact_popup'}>
                                <span
                                    onClick={() => {
                                        // history.push(`/admin/workspaces/${el.id}`)
                                        setPlatformContacts(prevState => ({
                                            ...prevState,
                                            name: {
                                                ...prevState.name,
                                                value: el.name,
                                            },
                                            email: {
                                                ...prevState.email,
                                                value: el.email,
                                            },
                                            job_title: {
                                                ...prevState.job_title,
                                                value: el.job_title,
                                            },
                                            phone: {
                                                ...prevState.phone,
                                                value: el.phone,
                                            },
                                        }))
                                        setEditContact({
                                            el: el,
                                            index: i
                                        })
                                        setProspectContacts(prevState => ({
                                            ...prevState,
                                            contacts: [
                                                ...prevState.contacts.slice(0, i),
                                                {...el, popup: false},
                                                ...prevState.contacts.slice(i + 1)
                                            ]
                                        }))
                                        document.getElementById('scroll_bottom').scrollIntoView({
                                            behavior: "smooth",
                                            block: "end",
                                            inline: "nearest"
                                        })
                                    }}>Edit</span>

                                        {/*//{prospect ?*/}
                                        {/*// <span onClick={() => {*/}
                                        {/*//     setProspectContacts(prevState => ({*/}
                                        {/*//         ...prevState,*/}
                                        {/*//         total: prevState.total - 1,*/}
                                        {/*//         contacts: prevState.contacts.filter(item => item.id !== el.id)*/}
                                        {/*//     }))*/}
                                        {/*// }} className={'delete'}>Remove from prospect</span>*/}
                                        {/*// : */}
                                        {/*// [*/}
                                        {/*//     <span key={'Assign prospect'}*/}
                                        {/*//           onClick={() => {*/}
                                        {/*//               let handleContact = () => {*/}
                                        {/*//                   setProspectContacts(prevState => ({*/}
                                        {/*//                       ...prevState,*/}
                                        {/*//                       total: prevState.total + 1,*/}
                                        {/*//                       error: false,*/}
                                        {/*//                       contacts: [...prevState.contacts, {*/}
                                        {/*//                           ...el,*/}
                                        {/*//                           popup: false,*/}
                                        {/*//                       }]*/}
                                        {/*//                   }))*/}
                                        {/*//               }*/}
                                        {/*//               if (prospectContacts.contacts.length) {*/}
                                        {/*//                   if (!prospectContacts.contacts.some(a => a.id === el.id)) {*/}
                                        {/*//                       handleContact()*/}
                                        {/*//                   } else {*/}
                                        {/*//                       alert('contact already exist')*/}
                                        {/*//                   }*/}
                                        {/*//               } else {*/}
                                        {/*//                   handleContact()*/}
                                        {/*//               }*/}
                                        {/*//               // handlePlatformContactsPopup(el, i, false, prospect)*/}
                                        {/*//           }}>Assign prospect</span>,*/}
                                        <span key={'delete contact'}
                                              onClick={() => {
                                                  // bodyOverflow(true)
                                                  // setActiveModal({
                                                  //     value: el.id,
                                                  //     data: el.name,
                                                  // })
                                                  if (!editMode) {
                                                      setProspectContacts(prevState => ({
                                                          ...prevState,
                                                          contacts: prevState.contacts.filter((_, num) => num !== i)
                                                      }))
                                                  } else {
                                                      setActiveModal({
                                                          value: true,
                                                          type: 'delete',
                                                          data: el,
                                                      })
                                                  }
                                              }} className={'delete'}>Delete</span>
                                        {/*// ]}*/}
                                    </div>
                                </OutsideClickHandler>
                                : null}
                        </div>
                    </div>
                </div>
                // )}
                // </Draggable>
            ))
            : <span className={'no_items_style'}>No items</span>
    )

    return (
        <div className={'contacts_popup_wrapper'}>
            <OutsideClickHandler callback={() => {
                if (activeModal) {
                    bodyOverflow(false)
                    setActiveModal({
                        value: false,
                        type: '',
                        data: ''
                    })
                }
            }}>
                {/*<DragDropContext>*/}
                <div className={'content overflow_scrollbar'}>
                    <div className={'head header_block_of_contact'}>
                        <div className={'inline'}>
                            <h4>Contacts</h4>
                            <span>{prospectContacts.total}</span>
                        </div>
                        <div style={{gridGap: '0'}} className={'inline'}>
                            <MainButton type={'button'}
                                        text={'Close'}
                                        className={'cancel_button'}
                                        onClick={() => {
                                            bodyOverflow(false)
                                            setActiveModal({
                                                value: false,
                                                type: '',
                                                data: ''
                                            })
                                        }}
                            />
                            {/*<div className={'popup_wrapper'}>*/}
                            {/*    <MainButton type={'button'}*/}
                            {/*                svg={<svg width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                            {/*                          xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                    <path d="M12 5V19" stroke="#fff" strokeWidth="2"*/}
                            {/*                          strokeLinecap="round"*/}
                            {/*                          strokeLinejoin="round"/>*/}
                            {/*                    <path d="M5 12H19" stroke="#fff" strokeWidth="2"*/}
                            {/*                          strokeLinecap="round"*/}
                            {/*                          strokeLinejoin="round"/>*/}
                            {/*                </svg>}*/}
                            {/*                className={'button_default_plus'}*/}
                            {/*                text={'New'}*/}
                            {/*                onClick={() => setButtonPopup(true)}/>*/}
                            {/*    <OutsideClickHandler callback={() => setButtonPopup(false)}>*/}
                            {/*        <div className={`popup ${buttonPopup ? 'popup_active' : ''}`}>*/}
                            {/*            <span>Upload as CSV</span>*/}
                            {/*            <span>Download CSV Template</span>*/}
                            {/*        </div>*/}
                            {/*    </OutsideClickHandler>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {prospectContacts.contacts.length ?
                        <div className={'platform_contact'}>
                            <div className={'head header_block_of_contact'}>
                                <div className={'inline'}>
                                    <h5>Prospect contacts</h5>
                                </div>
                            </div>
                            {/*<Droppable*/}
                            {/*    droppableId={'111'}*/}
                            {/*    // isDropDisabled={section.name === 'Done'}*/}
                            {/*    // key={colIndex}*/}
                            {/*    // type={'droppableSection'}*/}
                            {/*>*/}
                            {/*    snapshot*/}
                            {/*{(provided) => (*/}
                            <div className={'body list_of_contacts overflow_scrollbar'}
                                // {...provided.droppableProps}
                                // ref={provided.innerRef}
                            >
                                {contactLayout(prospectContacts.contacts, true)}

                                {/*{provided.placeholder}*/}
                            </div>
                            {/*)}*/}
                            {/*</Droppable>*/}
                        </div> : null}
                    <div
                        //     style={!loading ? {
                        //     display: 'flex',
                        //     justifyContent: 'center',
                        //     alignItems: 'center',
                        //     height: '100%'
                        // } : null}
                    >
                        {/*{loading ?*/}
                        <div className={'platform_contact create_contact'}>
                            <div className={'head'}>
                                <div className={'inline'}>
                                    <h5>Create contact</h5>
                                    {/*<span>{platformContacts.total}</span>*/}
                                </div>
                            </div>
                            {/*<Droppable*/}
                            {/*    droppableId={'222'}*/}
                            {/*    isDropDisabled={true}*/}
                            {/*    // key={colIndex}*/}
                            {/*    // type={'droppableSection'}*/}
                            {/*>*/}
                            {/*snapshot*/}
                            {/*{(provided) => (*/}
                            <form onSubmit={(e) => addNewContact(e)}
                                  className={'body body_create'}
                                // {...provided.droppableProps}
                                // ref={provided.innerRef}
                            >
                                {/*{contactLayout(platformContacts.contacts, false)}*/}

                                <TextInput value={platformContacts.name.value}
                                           type={'text'}
                                           successInput={platformContacts.name.success}
                                           wrongInput={platformContacts.name.error}
                                           error={platformContacts.name.error}
                                           errTitle={platformContacts.name.errorMessage}
                                           title={'Full Name'}
                                           onChange={({target}) => {
                                               setPlatformContacts(prevState => ({
                                                   ...prevState,
                                                   name: {
                                                       ...prevState.name,
                                                       value: target.value,
                                                       error: false,
                                                       success: false,
                                                   }
                                               }))
                                           }}/>
                                <TextInput value={platformContacts.email.value}
                                           type={'text'}
                                           title={'Email'}
                                           showErrorMessage
                                           successInput={platformContacts.email.success}
                                           wrongInput={platformContacts.email.error}
                                           error={platformContacts.email.error}
                                           errTitle={platformContacts.email.errorMessage}
                                           onChange={({target}) => {
                                               setPlatformContacts(prevState => ({
                                                   ...prevState,
                                                   email: {
                                                       ...prevState.email,
                                                       value: target.value,
                                                       error: false,
                                                       success: false,
                                                   }
                                               }))
                                           }}/>
                                <TextInput value={platformContacts.job_title.value}
                                           type={'text'}
                                           title={'Job Title'}
                                           successInput={platformContacts.job_title.success}
                                           wrongInput={platformContacts.job_title.error}
                                           error={platformContacts.job_title.error}
                                           errTitle={platformContacts.job_title.errorMessage}
                                           onChange={({target}) => {
                                               setPlatformContacts(prevState => ({
                                                   ...prevState,
                                                   job_title: {
                                                       ...prevState.job_title,
                                                       value: target.value,
                                                       error: false,
                                                       success: false,
                                                   }
                                               }))
                                           }}/>
                                <TextInput value={platformContacts.phone.value}
                                           type={'text'}
                                           title={'Phone'}
                                           successInput={platformContacts.phone.success}
                                           wrongInput={platformContacts.phone.error}
                                           error={platformContacts.phone.error}
                                           errTitle={platformContacts.phone.errorMessage}
                                           onChange={({target}) => {
                                               if (regExp.phone_format.test(target.value)) {
                                                   setPlatformContacts(prevState => ({
                                                       ...prevState,
                                                       phone: {
                                                           ...prevState.phone,
                                                           value: target.value,
                                                           error: false,
                                                           success: false,
                                                       }
                                                   }))
                                               }
                                           }}/>

                                <div className={'button_wrap'}>
                                    <MainButton type={'submit'}
                                                text={'Save'}
                                                id={'scroll_bottom'}
                                                className={'submit_button'}
                                    />
                                </div>

                                {/*{provided.placeholder}*/}
                            </form>
                            {/*)}*/}
                            {/*</Droppable>*/}
                        </div>
                        {/*: <Loader color={{*/}
                        {/*    '--bgColor': 'var(--gitgo-blue)',*/}
                        {/*    '--size': '40px',*/}
                        {/*    '--wrapper_size': '40px'*/}
                        {/*}}/>}*/}
                    </div>
                </div>
                {/*</DragDropContext>*/}
            </OutsideClickHandler>
        </div>
    )
}