import React from "react";
import CustomSelect from "../CustomSelect";
import TextInput from "../CustomComponents/TextInput";
import regExp from "../../Constants/regExp";
import MultipleSelect from "../CustomComponents/MultipleSelect";

export default function TargetingRenderLayout({objectData, setObjectData}) {
    return (
        Object.entries(objectData).map(el => {
            if (el[1].state_type === 'dropdown') {
                return (
                    <CustomSelect title={el[1].title}
                                  key={el[0]}
                                  loading={true}
                                  options={el[1].options}
                                  optionalLabel={el[0]}
                                  popupPosition={el[1].double ? '6.25rem' : null}
                                  className={'targeting_custom_select_wrapper'}
                                  svg={<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                      <path d="M8 10L12 14L16 10H8Z" fill="#0F172A" stroke="#0F172A" strokeWidth="2"
                                            strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>}
                                  popup={el[1].popup}
                                  setData={e => setObjectData(e)}
                                  error={el[1].error}
                                  placeholder={el[1].placeholder ?? null}
                                  errTitle={el[1].errorMessage}
                                  value={el[1].value}/>
                )
            } else if (el[1].state_type === 'multiselect') {
               return (
                   <MultipleSelect
                       title={el[1].title}
                       key={el[0]}
                       triple={el[1].triple}
                       options={el[1].options}
                       popup={el[1].popup}
                       error={el[1].error}
                       errTitle={el[1].errorMessage}
                       placeholder={el[1].placeholder ?? null}
                       dataArray={el[1].dataArray}
                       handlePopup={() => {
                           setObjectData(prevState => ({
                               ...prevState,
                               [el[0]]: {
                                   ...prevState[el[0]],
                                   popup: !prevState[el[0]].popup,
                               }
                           }))
                       }}
                       disabled={el[1].dataArray.length > 2}
                       setData={(b) => {
                           setObjectData(prevState => ({
                               ...prevState,
                               [el[0]]: {
                                   ...prevState[el[0]],
                                   dataArray: prevState[el[0]].dataArray.includes(b) ? prevState[el[0]].dataArray.filter(item => item !== b) : prevState[el[0]].dataArray.length === 3 ? prevState[el[0]].dataArray : [...prevState[el[0]].dataArray, b],
                                   error: false,
                               }
                           }))
                       }}/>
               )
            } else if (el[1].state_type === 'text') {
                return (
                    <TextInput value={el[1].value}
                               type={'text'}
                               title={el[1].title}
                               key={el[0]}
                               targetingInput
                               wrapper_margin={el[1].full_line ? 'full_line' : null}
                               maxLength={el[1].max_length}
                               placeholder={el[1].placeholder}
                               error={el[1].error}
                               errTitle={el[1].errorMessage}
                               onChange={({target}) => {
                                   const setInputState = (num) => {
                                       setObjectData(prevState => ({
                                           ...prevState,
                                           [el[0]]: {
                                               ...prevState[el[0]],
                                               value: num ? +target.value : target.value,
                                               error: false,
                                           }
                                       }))
                                   }
                                   if (el[1].type === 'number') {
                                       if (regExp.only_number.test(target.value)) {
                                           setInputState(el[1].field_type === 'number')
                                       }
                                   } else if (el[1].field_type === 'phone') {
                                       if (regExp.phone_format.test(target.value)) {
                                           setInputState()
                                       }
                                   } else {
                                       setInputState()
                                   }
                               }}/>
                )
            } else {
                return null
            }
        })
    )
}