import React, {useEffect, useState} from 'react'
import './styles/profile_settings.css'
import {useHistory, useLocation} from "react-router-dom";
import API from "../../api/API";
import {handleCurrentToken} from "../../Constants/handleCurrentToken";
import useBottomPopup from "../../Constants/useBottomPopup";
//import {useSelector} from "react-redux";
import ProfileSettingsWrapper from "../../Components/ProfileSettingsWrapper";
import ChangedConfirmedPopup from "../../Components/ChangedConfirmedPopup";

const initialState = {
    email: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    company_name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    first_name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    last_name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    phone: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    job_title: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
};

const EditManageSeats = () => {
    const history = useHistory();
    let location = useLocation();
    //const store = useSelector(state => state.auth.user_data);

    const [profileData, setProfileData] = useState(() => initialState);
    const [userId, setUserId] = useState(null);
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();

    useEffect(() => {
        let userId = location.pathname.split('/').pop();
        if (!isNaN(Number(userId))) {
            setUserId(userId)
            API(handleCurrentToken()).get(`/profile?id=${userId}`)
                .then(res => {
                    setProfileData(state => ({
                        ...state,
                        email: {
                            ...state.email,
                            value: res.data.profile.email,
                        },
                        company_name: {
                            ...state.company_name,
                            value: res.data.profile.company_name,
                        },
                        first_name: {
                            ...state.first_name,
                            value: res.data.profile.first_name ?? '',
                        },
                        last_name: {
                            ...state.last_name,
                            value: res.data.profile.last_name ?? '',
                        },
                        phone: {
                            ...state.phone,
                            value: res.data.profile.phone ?? '',
                        },
                        job_title: {
                            ...state.job_title,
                            value: res.data.profile.job_title ?? '',
                        }
                    }))
                })
                .catch(err => {
                    if (err.response.data.error === "user does not exist") {
                        history.push('/404')
                    }
                })
        } else {
            history.push('/404')
        }
    }, [location.pathname, history])


    const updateProfileSettings = (e) => {
        e.preventDefault();
        const {email, job_title, phone, last_name, first_name, company_name} = profileData;
        const condition = first_name.value && last_name.value && job_title.value && phone.value && email.value && company_name.value
        let formData = new FormData();

        if (condition) {
            // if (store.role === 'gitgo_admin') formData.append('email', email.value.trim())
            // if (store.role === 'gitgo_admin') formData.append('company_name', company_name.value.trim())
            formData.append('first_name', first_name.value.trim())
            formData.append('last_name', last_name.value.trim())
            formData.append('job_title', job_title.value.trim())
            formData.append('phone', phone.value.trim())
            API(handleCurrentToken()).post(`/profile?id=${userId}`, formData)
                .then(res => {
                    // console.log(res)
                    if (res.status === 200) {
                        history.push('/admin/manage_seats')
                        changeContinuePopup('Manager profile updated')
                        setTimeout(() => {
                            changeContinuePopup('Manager profile updated')
                        }, 2000)
                    }

                })
        } else {
            Object.entries(profileData).forEach((el) => {
                if (!el[1].value) {
                    setProfileData(prevState => ({
                        ...prevState,
                        [el[0]]: {
                            ...prevState[el[0]],
                            error: true,
                        }
                    }))
                }
            })
        }
    }

    return [
        <ChangedConfirmedPopup successText={successText}
                               key={0}
                               changingConfirmed={changingConfirmed}/>,
        <ProfileSettingsWrapper key={1} profileData={profileData}
                                pageTitle={'Edit Manager Profile'}
                                editManager
                                setProfileData={e => setProfileData(e)}
                                updateProfileSettings={updateProfileSettings}/>,
    ]
}

export default EditManageSeats