import React from "react";
import './styles/switch_block.css';

export default function SwitchBlock(props) {

    const {data, setData, unsetMargin, disable} = props;

    const optionsWrap = (el, options, available) => (
        <div className={`options_wrap overflow_scrollbar ${available ? 'options_wrap_right' : ''}`}>
            <div style={options.length >= 5 || !options.length ? {border: 'none'} : null} className={`content ${!available && el[1].error ? 'content_full' : ''}`}>
                {options.length ? options.map((item, index) => (
                    <div key={index} className={'item'} onClick={() => {
                        if (!disable) {
                            setData(prevState => ({
                                ...prevState,
                                [el[0]]: {
                                    ...prevState[el[0]],
                                    error: false,
                                    available_options: available ? prevState[el[0]].available_options.filter((el, i) => i !== index) : [...prevState[el[0]].available_options, item],
                                    chosen_options: available ? [...prevState[el[0]].chosen_options, item] : prevState[el[0]].chosen_options.filter((el, i) => i !== index),
                                }
                            }))
                        }
                    }}>
                        {!available && !disable ?
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 6.5L9 12.5L15 18.5" stroke="#475569" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg> : null}
                        <span>{item}</span>
                        {available && !disable ?
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 18.5L15 12.5L9 6.5" stroke="#475569" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg> : null}
                    </div>
                )) : !available && el[1].error ? <span className={'error'}>{el[1].errorMessage}</span> : <span className={'no_item'}>No items</span>}
            </div>
        </div>
    )

    return (
        Object.entries(data).map((el, i) => (
            <div style={unsetMargin && i + 1 === data.length ? {margin: 'unset'} : null}
                 key={el[1].title} className={'switch_wrapper'}>
                <span className={'title'}>{el[1].title}</span>
                <div className={'switch_content'}>
                    <div className={'available'}>
                        <div className={'block_title'}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 21.5C16.9706 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 17.4706 7.02944 21.5 12 21.5Z"
                                    stroke="#478ECC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8 12.5V12.51" stroke="#478ECC" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M12 12.5V12.51" stroke="#478ECC" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M16 12.5V12.51" stroke="#478ECC" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                            <span>Available</span>
                        </div>
                        {optionsWrap(el, el[1].available_options, true)}
                    </div>
                    <div className={'chosen'}>
                        <div className={'block_title'}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 21.5C16.9706 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 17.4706 7.02944 21.5 12 21.5Z"
                                    fill="#EDFCF5" stroke="#4BDE97" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path d="M9 12.5L11 14.5L15 10.5" fill="#EDFCF5"/>
                                <path d="M9 12.5L11 14.5L15 10.5" stroke="#4BDE97" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                            <span>Chosen</span>
                        </div>
                        {optionsWrap(el, el[1].chosen_options, false)}
                    </div>
                </div>
            </div>
        ))
    )
}